import { Button, Col, Form, Modal, Row, Tooltip, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, openModal, setElementSelected } from '../context/modalsSlice'
import { CaretDoubleRight, Notepad, Paperclip, TrashSimple, X } from '@phosphor-icons/react'
import { setData } from '../context/dataSlice'
import { SelectInput, TextAreaInput, TextInput } from '../components/Forms/Inputs'
import { useEffect, useState } from 'react'
import { returnCurrencyValue } from '../functions/helpers'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
import { useAuth } from 'reactfire'

const paymentEmailTemplate = {
    subjectBusiness: `Link de pago`,
    subjectNonprofit: `Link de donativo`,
    bodyBusiness: `Hola {cliente},\n\nAdjunto encontrarás el enlace correspondiente al pago de {total} {moneda}.\n\nSaludos,\n\n{alias}`,
    bodyNonprofit: `Hola {cliente},\n\nAdjunto encontrarás el enlace correspondiente al donativo de {total} {moneda}.\n\nSaludos,\n\n{alias}`,
}

const defaultEmailTemplate = {
    bodyBusiness: `Hola {cliente},\n\nAdjunto encontrarás tu factura correspondiente al pago de {total} {moneda}.\n\nSaludos,\n\n{alias}`,
    bodyNonprofit: `Hola {cliente},\n\nAdjunto encontrarás tu CFDI correspondiente al donativo de {total} {moneda}.\n\nSaludos,\n\n{alias}`,
    receiptSubjectBusiness: `Recibo de venta por {total} {moneda}`,
    receiptSubjectNonprofit: `Recibo de donativo por {total} {moneda}`,
    invoiceSubjectBusiness: `Factura {folio}`,
    invoiceSubjectNonprofit: `CFDI {folio}`,
}

const SendElementByEmail: React.FC = () => {
    const dispatch = useDispatch()
    const { sendByEmailModalVisible } = useSelector((state: any) => state.modals)
    const { toEmail } = useSelector((state: any) => state.modals)
    const { team } = useSelector((state: any) => state.team)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const { user } = useSelector((state: any) => state.user)
    const [form] = Form.useForm()
    const auth = useAuth()
    const [loading, setLoading] = useState(false)
    const { templateEmails, testmode, client } = useSelector((state: any) => state.data)

    let defaultSubjecttoEmail = ''

    if (toEmail?.elementType === 'invoices') {
        const subjectLineInvoice =
            defaultEmailTemplate[team?.defaults?.isNonprofit ? 'invoiceSubjectNonprofit' : 'invoiceSubjectBusiness']
        defaultSubjecttoEmail = subjectLineInvoice
    }
    if (toEmail?.elementType === 'receipts') {
        const subjectLineReceipt = isNaN(toEmail?.total)
            ? 'Recibo'
            : defaultEmailTemplate[team?.defaults?.isNonprofit ? 'receiptSubjectNonprofit' : 'receiptSubjectBusiness']
        defaultSubjecttoEmail = subjectLineReceipt
    }
    if (toEmail?.elementType === 'payments') {
        defaultSubjecttoEmail = `${paymentEmailTemplate[team?.defaults?.isNonprofit ? 'subjectNonprofit' : 'subjectBusiness']} ${toEmail?.folio_number ? `#${toEmail.folio_number}` : ''}`
    }

    defaultSubjecttoEmail = defaultSubjecttoEmail
        .replace(/{folio}/g, toEmail?.folio_number ?? '')
        .replace(/{total}/g, returnCurrencyValue(toEmail?.total))
        .replace(/{moneda}/g, toEmail?.currency)
    

    useEffect(() => {
        if (templateEmails?.id) {
            form.setFieldsValue({
                subjet: templateEmails?.name,
                body: templateEmails?.content,
            })
        } else {
            dispatch(
                setData({
                    item: 'templateEmails',
                    data: null,
                }),
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateEmails?.id])

    const defaulttoEmailMessage = defaultEmailTemplate[
        team?.defaults?.isNonprofit ? 'bodyNonprofit' : 'bodyBusiness'
    ]
        .replace(/{cliente}/g, toEmail?.client?.name ?? toEmail?.client.legal_name ?? '')
        .replace(/{total}/g, returnCurrencyValue(toEmail?.total))
        .replace(/{moneda}/g, toEmail?.currency)
        .replace(/{alias}/g, team?.brand?.alias ?? '')    

    const defaultPaymentMessage = paymentEmailTemplate[
        team?.defaults?.isNonprofit ? 'bodyNonprofit' : 'bodyBusiness'
    ]
        .replace(/{cliente}/g, toEmail?.client?.name ?? toEmail?.client?.legal_name ?? '')
        .replace(/{total}/g, returnCurrencyValue((toEmail?.amount ?? 0) / 100))
        .replace(/{moneda}/g, toEmail?.currency)
        .replace(/{alias}/g, team?.brand?.alias ?? '')
    
    const emails = [
        client?.email ?? toEmail?.client?.email ?? [],
        ...(client?.bcc ?? toEmail?.client?.bcc ?? []),
    ]
    var options = [...emails, team?.brand?.supportEmail ?? [], user?.email ?? []]

    const uniqueEmails = [...new Set((emails ?? [])?.filter((e) => e !== null) ?? [])]
    const idRef =
        toEmail?.elementType === 'invoices' ? 'invoices' : toEmail?.elementType === 'receipts' ? 'receipts' : 'payments'

    const readableType =
        toEmail?.elementType === 'invoices' ? 'Factura' : toEmail?.elementType === 'receipts' ? 'Recibo' : 'Pago'
    const SendEmail = async (values: any) => {
        try {
            setLoading(true)
            const prepEmails = values.to?.map((e: any) => e)

            // console.log(toEmail);

            const body = {
                emailSubject: values.subjet, //required
                emailBody: values.body, //required
                emails: prepEmails, //required
                message: values.body,
                messageTwo: '',
                messageThree: '',
                goodbye:
                    toEmail.shortUrl ?? toEmail?.shortURL
                        ? `Si no puedes abrir enlace en tu navegador, copia y pega el siguiente enlace ${toEmail.shortUrl ?? toEmail?.shortURL}`
                        : '',
                actiondisplay: toEmail.shortUrl ?? toEmail?.shortURL ? 'display:block;' : 'display:none;',
                action: toEmail?.elementType === 'payments' ? 'Realizar pago' : 'Continuar', //BUTTON TEXT
                link: toEmail.shortUrl ?? toEmail?.shortURL ?? ``, //BUTTON ACTION LINK
                type: idRef,
                hrType: readableType ?? 'Email', //Human readable type ex: Payment
                elementID: (toEmail?.fid ?? toEmail?.id ?? toEmail.uuid) || '',
                attachInvoice: idRef === 'invoices' ? true : null, //ID of the element, ex: payment id.
                attachPdf: idRef === 'invoices' ? true : null,
                note: null,
                invoiceId: toEmail?.id ?? toEmail?.fid ?? toEmail?.uuid ?? '',
                invoice: idRef === 'invoices' ? toEmail : null,
                receipt: idRef === 'receipts' ? toEmail : null,
                payment: idRef === 'payments' ? toEmail : null,
                id: toEmail?.id ?? toEmail?.fid ?? toEmail?.uuid ?? '',
                test: testmode,
            }
            // return console.log('values', body);

            const resp = await SignedInternalAPIRequest(
                { ...body, team: team?.id, billingAccount: billingAccount?.id },
                'requestSendEmailSeats',
                auth.currentUser,
            )
            message.success(resp?.message ?? 'Correo enviado correctamente.')
            dispatch(closeModal('sendByEmailModalVisible'))
            setLoading(false)
        } catch (error: any) {
            message.error(error?.message ?? 'Error al enviar el correo.')
            setLoading(false)
        }
    }

    useEffect(() => {
        const emailBody = toEmail?.elementType === 'payments' ? defaultPaymentMessage : defaulttoEmailMessage
        if (toEmail) {
            form.setFieldsValue({
                body: emailBody,
                to: uniqueEmails ?? [],
                subjet: defaultSubjecttoEmail
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendByEmailModalVisible, toEmail])

    return (
        <Modal
            style={{ zIndex: 1 }}
            title={
                <div style={{ position: 'relative', paddingBottom: '15px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            //center the text
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        Enviar por correo electrónico
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X
                                    className="icon clickable"
                                    size={20}
                                    onClick={() => {
                                        dispatch(closeModal('sendByEmailModalVisible'))
                                        dispatch(
                                            setData({
                                                item: 'toEmail',
                                                data: null,
                                            }),
                                        )
                                        dispatch(
                                            setData({
                                                item: 'templateEmails',
                                                data: null,
                                            }),
                                        )
                                        dispatch(
                                            setData({
                                                item: 'client',
                                                data: null,
                                            })
                                        )
                                    }}
                                />
                                <></>
                                <Form.Item style={{ margin: 0, padding: 0 }}>
                                    <Button
                                        type="primary"
                                        loading={loading}
                                        htmlType="submit"
                                        disabled={loading ? true : false}
                                        onClick={() => form.submit()}
                                    >
                                        Enviar
                                        <CaretDoubleRight
                                            size={15}
                                            style={{ marginLeft: '5px' }}
                                            className=""
                                            weight="regular"
                                        />
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            open={sendByEmailModalVisible}
            closeIcon={<></>}
            closable={false}
            onCancel={() => {
                dispatch(closeModal('sendByEmailModalVisible'))
                dispatch(
                    setElementSelected({
                        element: 'toEmail',
                        data: null,
                    }),
                )
                dispatch(
                    setData({
                        item: 'templateEmails',
                        data: null,
                    }),
                )
            }}
            footer={
                <Row
                    style={{ backgroundColor: '#F7F7F7', padding: '10px 20px', borderRadius: '8px', width: '100%' }}
                    justify="space-between"
                    align="middle"
                >
                    <Row align="middle">
                        <Tooltip title="Cancelar envío">
                            <TrashSimple
                                size={19}
                                className="icon iconDanger clickable"
                                onClick={() => {
                                    dispatch(closeModal('sendByEmailModalVisible'))
                                    dispatch(
                                        setElementSelected({
                                            element: 'toEmail',
                                            data: null,
                                        }),
                                    )
                                }}
                            />
                        </Tooltip>
                    </Row>
                    <Row align="middle" wrap>
                        <Tooltip title="Plantillas">
                            <Notepad
                                size={19}
                                onClick={() => dispatch(openModal('templatesEmailsDrawerVisible'))}
                                className="icon clickable"
                            />
                        </Tooltip>
                        {toEmail?.elementType === 'toEmail' && (
                            <Paperclip style={{ marginLeft: '5px' }} size={15} className="icon clickable" />
                        )}

                        <Button
                            size="small"
                            loading={loading}
                            disabled={loading ? true : false}
                            type="primary"
                            style={{ marginLeft: '5px' }}
                            onClick={() => form.submit()}
                        >
                            Enviar ahora{' '}
                            <CaretDoubleRight size={15} style={{ marginLeft: '5px' }} className="" weight="regular" />
                        </Button>
                    </Row>
                </Row>
            }
        >
            <Form form={form} layout="vertical" onFinish={SendEmail}>
                <SelectInput
                    rules={[
                        {
                            required: true,
                            message: 'Por favor selecciona al menos un correo',
                        },
                    ]}
                    label="Enviar a"
                    mode="tags"
                    options={(
                        [
                            ...new Set(
                                (options ?? []).filter(
                                    (e) => e !== null && e !== '' && e && e !== undefined && typeof e === 'string',
                                ),
                            ),
                        ]
                    ).map((e: any) => {
                        return {
                            label: e,
                            value: e,
                        }
                    })}
                    name={'to'}
                />
                <TextInput
                    name="subjet"
                    placeholder="Ej: Factura #89"
                    label="Asunto"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor añade un asunto',
                        },
                    ]}
                />
                <TextAreaInput
                    minRows={10}
                    name={'body'}
                    propsStyle={{
                        border: 'none',
                    }}
                />
            </Form>
        </Modal>
    )
}

export default SendElementByEmail
