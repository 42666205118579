import { Col, Popconfirm, Row, Switch, Tag, Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTeam } from '../../context/teamsSlice'
import { PencilSimple, TrashSimple, Warning } from '@phosphor-icons/react'
import { arrayUnion, doc, getFirestore, updateDoc } from 'firebase/firestore'
import { openModal } from '../../context/modalsSlice'
import { setData } from '../../context/dataSlice'
import { LockSimple } from '@phosphor-icons/react/dist/ssr'

const WebhooksList: React.FC = () => {
    const dispatch = useDispatch()
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)

    return (
        <Row>
            <Col xs={24}>
                <label>Lista de webhooks</label>
            </Col>
            <Col xs={24} style={{ marginTop: '25px' }}>
                {team?.webhooks?.length === 0 && (
                    <Typography.Text className="smallparagraph">No hay webhooks registrados.</Typography.Text>
                )}
                {team?.webhooks?.map((webhook: any) => {
                    return (
                        <div
                            key={webhook.id}
                            className="d-flex flex-column"
                            style={{
                                marginBottom: '10px',
                                borderRadius: '10px',
                                padding: '10px',
                                backgroundColor: webhook?.status === 'active' ? 'white' : '#fafbfd',
                                border: '1px solid #E5E5E5',
                            }}
                        >
                            <Row justify="space-between">
                                <div className="d-flex flex-column" style={{ flex: 1 }}>
                                    {webhook?.security?.headers && (
                                        <LockSimple size={16} style={{ paddingRight: '4px' }} className="green-7" />
                                    )}

                                    <Typography.Text className="smallheader">{webhook.url}</Typography.Text>
                                    <Typography.Text className="smallparagraph" style={{ marginTop: '10px' }}>
                                        {webhook.description ?? ''} - ID: {webhook.id}
                                    </Typography.Text>
                                    <Typography.Text
                                        className="smallparagraph descriptions"
                                        style={{ marginTop: '5px' }}
                                    >
                                        {moment(webhook.created).format('DD MMM YYYY HH:mm')}
                                    </Typography.Text>
                                    <Row wrap style={{ marginTop: '15px' }}>
                                        {webhook?.events?.map((event: any) => {
                                            return (
                                                <Tag
                                                    key={event}
                                                    closable
                                                    style={{ marginTop: '4px' }}
                                                    onClose={async () => {
                                                        const hooks = [...(team.webhooks ?? [])]
                                                        const indx = hooks.findIndex((w: any) => w.id === webhook.id)
                                                        hooks[indx] = {
                                                            ...hooks[indx],
                                                            events: hooks[indx].events.filter((e: any) => e !== event),
                                                        }
                                                        dispatch(setTeam({ ...team, webhooks: hooks }))
                                                        await updateDoc(doc(getFirestore(), 'teams', team.id), {
                                                            webhooks: hooks,
                                                            logs: arrayUnion({
                                                                date: moment().valueOf(),
                                                                description:
                                                                    'Se eliminó el evento ' +
                                                                    event +
                                                                    ' del webhook ' +
                                                                    webhook.url,
                                                                user: user.uid,
                                                                value: 'deleted',
                                                            }),
                                                        })
                                                    }}
                                                >
                                                    {event}
                                                </Tag>
                                            )
                                        })}
                                    </Row>
                                </div>

                                <Row align="middle" style={{ marginBottom: 'auto' }}>
                                    {webhook?.status === 'active' && (
                                        <PencilSimple
                                            size={20}
                                            weight="regular"
                                            style={{ marginRight: '5px' }}
                                            onClick={() => {
                                                dispatch(openModal('editWebhooksModalVisible'))
                                                dispatch(setData({ item: 'preloadedWebhookData', data: webhook }))
                                            }}
                                            className="clickable"
                                        />
                                    )}
                                    <Switch
                                        defaultChecked={webhook?.status === 'active'}
                                        size="small"
                                        onChange={(checked) => {
                                            const hooks = [...(team.webhooks ?? [])]
                                            const indx = hooks.findIndex((w: any) => w.id === webhook.id)
                                            hooks[indx] = {
                                                ...hooks[indx],
                                                status: checked ? 'active' : 'inactive',
                                            }
                                            dispatch(setTeam({ ...team, webhooks: hooks }))
                                        }}
                                    />

                                    {webhook.status === 'inactive' && (
                                        <Popconfirm
                                            title={
                                                <Typography.Text className="smallparagraph">
                                                    ¿Estás seguro de que deseas eliminar este webhook?
                                                </Typography.Text>
                                            }
                                            icon={<Warning size={15} weight="regular" className="danger" />}
                                            onConfirm={async () => {
                                                const hooks = [...(team.webhooks ?? [])]
                                                const indx = hooks.findIndex((w: any) => w.id === webhook.id)
                                                hooks.splice(indx, 1)
                                                dispatch(setTeam({ ...team, webhooks: hooks }))
                                                await updateDoc(doc(getFirestore(), 'teams', team.id), {
                                                    webhooks: hooks,
                                                    logs: arrayUnion({
                                                        date: moment().valueOf(),
                                                        description: 'Se eliminó el webhook ' + webhook.url,
                                                        user: user.uid,
                                                        value: 'deleted',
                                                    }),
                                                })
                                            }}
                                        >
                                            <TrashSimple
                                                size={16}
                                                weight="regular"
                                                className="danger clickable"
                                                style={{ paddingLeft: '4px' }}
                                            />
                                        </Popconfirm>
                                    )}
                                </Row>
                            </Row>
                        </div>
                    )
                })}
            </Col>
            {/* Your component code here */}
        </Row>
    )
}

export default WebhooksList
