import { ConfigProvider, Segmented } from 'antd'
import { usePostHog } from 'posthog-js/react'
import { useState } from 'react'
import { trackEvent } from '../../analytics/helpers'
import Paragraph from 'antd/lib/typography/Paragraph'
import { Sparkle } from '@phosphor-icons/react'

export default function PricingDeckSwitch({
    parentCallback,
}: {
    parentCallback: (type: 'monthly' | 'yearly') => void
}) {
    const [subscription, setSubscription] = useState('monthly')
    const posthog = usePostHog()

    const handleChange = (e: any) => {
        trackEvent(
            {
                name: 'onboarding_plan_periodicity_selected',
                metadata: {
                    v: 'v2',
                    periodicity: e,
                },
            },
            posthog,
        )
        setSubscription(e)
        parentCallback(e)
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Radio: {
                        buttonSolidCheckedColor: 'var(--neutral-2)',
                        buttonBg: 'var(--neutral-4)',
                        buttonCheckedBg: 'var(--neutral-4)',
                        colorPrimary: 'var(--white)',
                        colorPrimaryHover: 'var(--white)',
                    },
                },
            }}
        >
            <Segmented<string>
                options={[
                    {
                        label: (
                            <div>
                                <Paragraph style={{ padding: 0, margin: 0, paddingTop: '3px' }}>Mensual</Paragraph>
                            </div>
                        ),
                        value: 'monthly',
                    },
                    {
                        label: (
                            <div>
                                <Paragraph style={{ padding: 0, margin: 0, paddingTop: '3px' }}>
                                    Anual, ahorra 16% {subscription === 'annual' && <Sparkle size={16} />}
                                </Paragraph>
                            </div>
                        ),
                        value: 'annual',
                    },
                ]}
                onChange={(v) => handleChange(v)}
            />
        </ConfigProvider>
    )
}
