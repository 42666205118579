import { ArrowLeft, Check, Clock, UserCircleDashed } from '@phosphor-icons/react'
import { Avatar, Button, Col, Divider, Form, Row, Steps, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'reactfire'
import { handleSignOut } from '../../components/UI/Header'
import { ONBOARDING_ROUTES, ONBOARDING_STEP, setCurrentStep } from '../../context/onboardingSlice'

import { setUser } from '../../context/userSlice'
import ComericalInformationStep from './steps/ComercialInformationStep'
import { FiscalInformationStep } from './steps/FiscalIformationStep'
import PersonalInformationStep from './steps/PersonalInformationStep'
import SatConnectionStep from './steps/SatConnectionStep'
import { usePostHog } from 'posthog-js/react'
import { trackEvent } from '../../analytics/helpers'

const { Step } = Steps

const steps = [
    { title: 'Información Comercial', key: 'comercial', path: '/onboarding/team' },
    { title: 'Información Fiscal', key: 'fiscal', path: '/onboarding/fiscal-data' },
    { title: 'Sube tus Sellos', key: 'defaults', path: '/onboarding/sat-connection' },
]

const Onboarding = ({ step }: { step: ONBOARDING_STEP }) => {
    const auth = useAuth()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const posthog = usePostHog()
    const { user } = useSelector((state: any) => state.user)
    const [form] = Form.useForm()

    const noBack = [ONBOARDING_STEP.USER, ONBOARDING_STEP.TEAM]

    const { currentStep, stepLoading } = useSelector((state: any) => state.onboarding)

    const handleStepComplete = () => {
        const nextStep = step + 1
        dispatch(setCurrentStep(nextStep))
    }

    const handlePrevious = () => {
        trackEvent({ name: 'onboarding_back_step_clicked', metadata: { v: 'v2', step } }, posthog)
        if (step <= ONBOARDING_STEP.TEAM) return
        const stepToForce = step - 1
        const path = ONBOARDING_ROUTES[stepToForce as keyof typeof ONBOARDING_ROUTES]

        dispatch(setCurrentStep(stepToForce))
        dispatch(
            setUser({
                ...user,
                forceStep: stepToForce,
            }),
        )
        navigate(path)
    }

    const renderButtons = () => (
        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
            {!noBack.includes(step) && (
                <Button className="btn-link" onClick={handlePrevious} style={{ marginRight: 'auto' }}>
                    <ArrowLeft style={{ marginRight: '5px' }} />
                    Anterior
                </Button>
            )}
            <Button
                className="btn-primary"
                htmlType="submit"
                loading={stepLoading}
                onClick={() => {
                    trackEvent({ name: 'onboarding_next_step_clicked', metadata: { v: 'v2', step } }, posthog)
                }}
            >
                Siguiente
            </Button>
        </div>
    )

    const renderStepContent = () => {
        switch (step) {
            case ONBOARDING_STEP.USER:
                return (
                    <PersonalInformationStep
                        form={form}
                        onStepCompleted={handleStepComplete}
                        renderButtons={renderButtons}
                    />
                )
            case ONBOARDING_STEP.INVITE:
                return <></>
            case ONBOARDING_STEP.TEAM:
                return (
                    <ComericalInformationStep
                        form={form}
                        onStepCompleted={handleStepComplete}
                        renderButtons={renderButtons}
                    />
                )
            case ONBOARDING_STEP.FISCAL_DATA:
                return (
                    <FiscalInformationStep
                        form={form}
                        onStepCompleted={handleStepComplete}
                        renderButtons={renderButtons}
                    />
                )
            case ONBOARDING_STEP.SAT_CONNECTION:
                return (
                    <SatConnectionStep onStepCompleted={handleStepComplete} form={form} renderButtons={renderButtons} />
                )
            default:
                return null
        }
    }

    const getColor = (index: number, currentStep: number | null) => {
        return index <= (currentStep ?? 0) ? 'white' : 'white'
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Row style={{ flex: 'none' }}>
                <Col xs={24} md={12} style={{ padding: '24px 0 0' }}>
                    <div style={{ padding: '0 100px' }}>
                        <Steps
                            className="onboarding-steps"
                            current={steps.findIndex((s) => s.path === window.location.pathname)}
                            style={{ marginBottom: '16px' }}
                            size="small"
                        >
                            {steps.map((stepItem, index) => {
                                const currentPath = window.location.pathname
                                const isCurrentStep = currentPath === stepItem.path
                                const currentStepNumber = Object.entries(ONBOARDING_ROUTES).find(
                                    ([_, path]) => path === stepItem.path,
                                )?.[0]
                                return (
                                    <Step
                                        key={stepItem.key}
                                        status={isCurrentStep ? 'process' : 'wait'}
                                        icon={
                                            <Avatar
                                                icon={
                                                    isCurrentStep ? (
                                                        <UserCircleDashed size={12} />
                                                    ) : Number(step) >= Number(currentStepNumber) ? (
                                                        <Check weight="bold" />
                                                    ) : (
                                                        <Clock size={12} />
                                                    )
                                                }
                                                size={22}
                                                style={{
                                                    backgroundColor:
                                                        Number(step) >= Number(currentStepNumber)
                                                            ? '#044737'
                                                            : '#c7c7c7',
                                                    color: getColor(index, Number(step)),
                                                }}
                                            />
                                        }
                                        title={
                                            <Typography.Text
                                                className={
                                                    (currentStep ?? 0) === index
                                                        ? 'p-small-bold neutral-2'
                                                        : 'p-small-regular neutral-2'
                                                }
                                            >
                                                {stepItem.title}
                                            </Typography.Text>
                                        }
                                    />
                                )
                            })}
                        </Steps>
                    </div>
                    <Divider style={{ margin: '16px 0 0' }} />
                </Col>
                <Col
                    xs={0}
                    md={12}
                    style={{
                        background: 'var(--neutral-8)',
                        display: 'flex',
                        justifyContent: 'end',
                        padding: '24px',
                    }}
                >
                    <Button
                        className="btn-link"
                        onClick={async () => {
                            handleSignOut({ dispatch, auth })
                        }}
                        style={{ alignSelf: 'center' }}
                    >
                        Cerrar sesión
                    </Button>
                </Col>
            </Row>
            <Row style={{ flex: 1, overflow: 'hidden' }}>{renderStepContent()}</Row>
        </div>
    )
}

export default Onboarding
