import { Alert, Descriptions, Divider, Modal, Row, Spin, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { PaymentStatusTag } from '../components/UI/ElementStatus'
import { useEffect, useState } from 'react'
import { paymentSet } from '../../interfaces/paymentsDef'
import { returnCurrencyValue } from '../functions/helpers'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { setData } from '../context/dataSlice'

const StripeBankInstructionsModal = () => {
    const dispatch = useDispatch()
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const { stripeBankInstructionsVisible } = useSelector((state: any) => state.modals)
    const { payment, client } = useSelector((state: any) => state.data)
    const [loading, setLoading] = useState(true)

    const [bankInstructions, setBankInstructions] = useState<any>({})

    useEffect(() => {
        if (payment?.fid) {
            setBankInstructions({
                amountRemaining: payment?.next_action?.display_bank_transfer_instructions?.amount_remaining,
                bankName: (payment?.next_action?.display_bank_transfer_instructions?.financial_addresses || [])[0]?.spei
                    ?.bank_name,
                clabe: (payment?.next_action?.display_bank_transfer_instructions?.financial_addresses || [])[0]?.spei
                    ?.clabe,
                from: 'payment',
            })
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payment?.fid])

    const getClientBankDetails = async () => {
        try {
            setLoading(true)
            console.log("GET BANK TRANSFER DETAILS: ", {
                action: 'retieveBankInstructions',
                customerId: client?.metadata?.stripeId,
                livemode: client.livemode,
                team: team.id,
                owner: user.uid,
            })
            const clientHandler = httpsCallable(getFunctions(), 'stripeclientsapp')
            const clientBankDetails = (await clientHandler({
                action: 'retieveBankInstructions',
                customerId: client?.metadata?.stripeId,
                livemode: client.livemode,
                team: team.id,
                owner: user.uid,
            })) as any
            setBankInstructions({
                amountRemaining: 0,
                bankName: clientBankDetails?.data?.bankInstructions?.spei?.bank_name,
                clabe: clientBankDetails?.data?.bankInstructions?.spei?.clabe,
                cashBalance: clientBankDetails?.data?.cashBalance?.mxn,
                from: 'client',
            })
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            console.log('ERROR: ', error)
            message.error(error?.message || 'Ocurrió un error al obtener los datos del banco del cliente')
        }
    }

    useEffect(() => {
        if (client?.id && stripeBankInstructionsVisible && !payment?.fid) {
            getClientBankDetails()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payment?.fid, stripeBankInstructionsVisible])

    const BankTransferDetails = () => {
        const items = []

        if (payment?.id) {
            items.push(
                {
                    key: 'paymentIntentId',
                    label: <Typography.Text className="smallparagraphbold">ID del pago</Typography.Text>,
                    children: (
                        <Typography.Text className="smallparagraph">
                            {payment?.paymentIntent || payment?.id}
                        </Typography.Text>
                    ),
                },
                {
                    key: 'client',
                    label: <Typography.Text className="smallparagraphbold">Nombre del cliente</Typography.Text>,
                    children: <Typography.Text className="smallparagraph">{payment?.client?.name}</Typography.Text>,
                },
                {
                    key: 'amountRemaining',
                    label: <Typography.Text className="smallparagraphbold">Monto restante</Typography.Text>,
                    children: (
                        <Typography.Text className="smallparagraph ">
                            {returnCurrencyValue(bankInstructions?.amountRemaining / 100)}
                        </Typography.Text>
                    ),
                },
            )
        }

        items.push(
            {
                key: 'bankName',
                label: <Typography.Text className="smallparagraphbold">Nombre del banco</Typography.Text>,
                children: <Typography.Text className="smallparagraph ">{bankInstructions?.bankName}</Typography.Text>,
            },
            {
                key: 'clabe',
                label: <Typography.Text className="smallparagraphbold">CLABE</Typography.Text>,
                children: <Typography.Text className="smallparagraph ">{bankInstructions?.clabe}</Typography.Text>,
            },
        )

        if (client?.id && !payment?.fid) {
            items.push({
                key: 'cashBalance',
                label: <Typography.Text className="smallparagraphbold">Saldo en caja</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {returnCurrencyValue((bankInstructions?.cashBalance || 0) / 100)} MXN
                    </Typography.Text>
                ),
            })
        }

        return (
            <Descriptions
                title={
                    <Row justify="space-between" align="middle">
                        <Typography.Text className="mediumparagraphbold">
                            Detalles para la transferencia
                        </Typography.Text>
                        {payment?.fid && <PaymentStatusTag shape="tag" record={paymentSet(payment)} />}
                    </Row>
                }
                layout="vertical"
                style={{ marginTop: '20px' }}
                size="middle"
                column={{
                    xl: 3,
                    lg: 2,
                    md: 2,
                    sm: 1,
                    xs: 1,
                }}
                items={items}
            />
        )
    }

    return (
        <Modal
            title={null}
            width={650}
            open={stripeBankInstructionsVisible}
            onCancel={() => {
                dispatch(closeModal('stripeBankInstructionsVisible'))
                dispatch(
                    setData({
                        item: 'payment',
                        data: null,
                    }),
                )
                setLoading(true)
                setBankInstructions({})
            }}
            footer={null}
        >
            <div>
                <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
                    Instrucciones para la transferencia{' '}
                    <span style={{ color: '#b7b7b7', fontSize: '14px', margin: 0, padding: 0 }}></span>
                </Typography.Title>
                <Divider style={{ margin: '10px' }} />
                <Typography.Text style={{ fontSize: '14px' }}>
                    Tu cliente debe relizar una transferencia con los siguientes datos:
                </Typography.Text>
                {loading ? (
                    <Row justify="center" style={{ marginTop: '20px' }}>
                        <Spin />
                    </Row>
                ) : (
                    <BankTransferDetails />
                )}
                <Alert
                    description="Esta transferencia es procesada por Stripe"
                    type="info"
                    showIcon
                    style={{ marginTop: '20px' }}
                />
            </div>
        </Modal>
    )
}

export default StripeBankInstructionsModal
