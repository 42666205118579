import {
    CaretDoubleRight,
    CaretDown,
    CaretUp,
    ChartLineDown,
    ChartLineUp,
    CheckSquare,
    CurrencyCircleDollar,
    Plus,
    PlusCircle,
    X,
} from '@phosphor-icons/react'
import {
    Button,
    Checkbox,
    Col,
    Divider,
    Drawer,
    Form,
    FormInstance,
    message,
    Popover,
    Progress,
    Row,
    Space,
    Switch,
    Table,
    Tooltip,
    Typography,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire'
import { setData, setElementSelected } from '../context/dataSlice'
import { closeModal, openModal } from '../context/modalsSlice'
import { addParamToUrl, removeParamFromUrl } from '../functions/UrlParams'

import {
    InvoiceCreatedView,
    InvoiceEmailsComponent,
    InvoiceMetadata,
    InvoiceProductsServices,
    InvoiceTestMode,
    InvoiceTypeDescription,
} from '../components/invoices/InvoiceComponents'
import { SignedInternalAPIRequest } from '../functions/APIRequest'

import dayjs, { Dayjs } from 'dayjs'
import {
    arrayUnion,
    collection,
    doc,
    getDocs,
    getFirestore,
    increment,
    query,
    updateDoc,
    where,
    writeBatch,
} from 'firebase/firestore'
import moment from 'moment'
import { usePostHog } from 'posthog-js/react'
import { MetroSpinner } from 'react-spinners-kit'
import { generateObjectFromKeys, manageAutomations } from '../../helpers/helperFunctions'
import { SummarizeMessage } from '../components/Calendarize/SummarizeRecurring'
import SelectClientCard from '../components/Cards/SelectClientCard'
import RecurringEventDetails from '../components/ComponentIndividual/RecurringEventDetails'
import ErrorsInFormComponent from '../components/Forms/ErrorsInFormComponent'
import {
    CheckBoxInput,
    ChipsSelect,
    DateInput,
    NumberInput,
    SelectInput,
    TextAreaInput,
    TextInput,
} from '../components/Forms/Inputs'
import { complementColumns } from '../components/Tables/Columns/ComplementColumns'
import {
    getFolioByInvoiceType,
    handleEgressInvoiceObject,
    handleIncomeInvoiceObject,
    handlePaymentInvoiceObject,
} from '../components/invoices/invoiceHelpers'
import { GetExchangeRates /* GetTeamHelper */ } from '../context/databaseContextHelpers'
import { setTeam } from '../context/teamsSlice'
import { currecies } from '../datasets/Currencies'
import { CFDIRelations, CFDIUses, paymentForms, paymentMethods } from '../datasets/Fiscal'
import { generateCode, getUniqueTaxes, returnCurrencyValue, taxRegimes } from '../functions/helpers'
import { trackEvent } from '../analytics/helpers'

const GLOBAL_INVOICE_MONTHS = [
    { value: '01', label: 'Enero' },
    { value: '02', label: 'Febrero' },
    { value: '03', label: 'Marzo' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Mayo' },
    { value: '06', label: 'Junio' },
    { value: '07', label: 'Julio' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Septiembre' },
    { value: '10', label: 'Octubre' },
    { value: '11', label: 'Noviembre' },
    { value: '12', label: 'Diciembre' },
    { value: '13', label: 'Enero-Febrero' },
    { value: '14', label: 'Marzo-Abril' },
    { value: '15', label: 'Mayo-Junio' },
    { value: '16', label: 'Julio-Agosto' },
    { value: '17', label: 'Septiembre-Octubre' },
    { value: '18', label: 'Noviembre-Diciembre' },
]

const PERIODICITIES = [
    { value: 'month', label: 'Mensual' },
    { value: 'day', label: 'Diario' },
    { value: 'week', label: 'Semanal' },
    { value: 'two_months', label: 'Bimestral' },
]

export const CreateInvoiceV2: React.FC = () => {
    const dispatch = useDispatch()
    const { invoiceModalVisible } = useSelector((state: any) => state.modals)
    const { invoice, recurringEventData, exchangeRates, client, testmode } = useSelector((state: any) => state.data)

    const [form] = Form.useForm()

    useEffect(() => {
        if (Object.keys(exchangeRates).length === 0) {
            GetExchangeRates(dispatch)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exchangeRates])

    useEffect(() => {
        form.setFieldsValue({
            emails: [...(client?.bcc || []), client?.email].filter((email: any) => email),
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client])

    const HeaderTitle =
        invoice?.from === 'recurringEvent' && recurringEventData?.id
            ? 'Editar evento recurrente'
            : invoice?.from === 'recurringEvent' && !invoice?.id
              ? 'Crear factura recurrente'
              : 'Crear factura'

    return (
        <Drawer
            style={{ marginTop: testmode ? '32px' : '0px', paddingBottom: testmode ? '32px' : '0px' }}
            width="100%"
            title={
                <DrawerHeader
                    edit={false}
                    title={HeaderTitle}
                    buttonText={
                        invoice?.from === 'recurringEvent' && recurringEventData?.id
                            ? 'Editar evento recurrente'
                            : invoice?.from === 'recurringEvent' && !invoice?.id
                              ? 'Crear factura recurrente'
                              : 'Crear factura'
                    }
                    ctxElementName="invoice"
                    ctxModalName="invoiceModalVisible"
                    readableElementType="factura"
                    form={form}
                    state={{}}
                />
            }
            closable={false}
            onClose={() => {
                console.log('🚀🚀🚀 CLOSING INVOICE MODAL')
                dispatch(
                    setElementSelected({
                        element: 'recurringInvoice',
                        data: false,
                    }),
                )
                dispatch(
                    setData({
                        item: 'recurringInvoiceData',
                        data: null,
                    }),
                )
                dispatch(closeModal('invoiceModalVisible'))
                removeParamFromUrl('open')
                removeParamFromUrl('invoiceType')
                removeParamFromUrl('clientId')
                removeParamFromUrl('services')
            }}
            open={invoiceModalVisible}
        >
            <Row justify="center">
                <Col xs={24} md={23} lg={17} xl={14}>
                    <ToRender form={form} />
                </Col>
            </Row>
        </Drawer>
    )
}

// RENDER

const ToRender = ({ form }: { form: FormInstance }) => {
    const { invoice } = useSelector((state: any) => state.data)
    const { step } = invoice ?? {}
    if (!invoice?.invoiceType || step === 0 || !step) return <SelectInvoiceType />
    switch (step) {
        case 0:
            return <SelectInvoiceType />
        case 1:
            return <InvoiceForm state={{}} setState={() => {}} form={form} />
        case 2:
            return <InvoiceCreatedView />
        default:
            return <></>
    }
}

const InvoiceForm = ({ state, setState, form }: { state: any; setState: any; form: FormInstance }) => {
    const dispatch = useDispatch()
    const posthog = usePostHog()
    const auth = useAuth()

    const [errors, setErrors] = useState<any>([])

    const { recurringEventData, invoice, client, selectedServices, testmode, idempotency_key } = useSelector(
        (state: any) => state.data,
    )
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const [calendarForm] = Form.useForm()

    useEffect(() => {
        if (invoice?.from === 'recurringEvent' && recurringEventData?.id) {
            calendarForm.setFieldsValue({
                startDate: dayjs(recurringEventData?.startDate),
                endDate: dayjs(recurringEventData?.endDate),
                onTime: recurringEventData?.onTime,
                temporality: recurringEventData?.temporality,
                onWeekday: recurringEventData?.onWeekday,
                onDay: recurringEventData?.onDay,
                test: testmode,
            })
        }

        console.log('INVOICE: ', invoice)

        form.setFieldsValue({
            ...invoice,
            date: invoice?.date ? dayjs(invoice?.date) : dayjs(),
            use: invoice?.use ?? client?.use ?? team?.defaults.usage ?? 'G03',
            payment_form:
                invoice?.payment_form ?? invoice?.paymentForm ?? team?.defaults?.manualInvoicesConfig?.paymentForm,
            paymentMethod:
                invoice?.payment_method ??
                invoice?.paymentMethod ??
                team?.defaults?.manualInvoicesConfig?.paymentMethod ??
                'PUE',
            folio: invoice?.folio_number,
            currency: invoice?.currency ?? 'MXN',
            exchange: invoice?.exchange ?? 1,
            series:
                invoice?.invoiceType === 'P'
                    ? team?.invoice_serie_payments
                    : invoice?.invoiceType === 'E'
                      ? team?.invoice_serie_egress
                      : (team?.invoice_serie ?? 'G'),
            test: testmode,
            notes: invoice?.invoiceSingleExtra,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client])

    const checkRequirements = async () => {
        try {
            await form.validateFields()
        } catch (error: any) {
            console.log(error)

            if (error?.errorFields?.length > 0) {
                console.log(error?.errorFields)

                dispatch(
                    setData({
                        item: 'invoice',
                        data: {
                            ...invoice,
                            loading: false,
                            formErrors: error.errorFields,
                            // deleteKeys: [...(invoice?.deleteKeys ?? []), 'formErrors'],
                        },
                    }),
                )
                message.error('Por favor completa los campos requeridos')
            }
            throw new Error('Campos requeridos')
        }

        if (!client?.id) {
            message.error('Por favor selecciona un cliente')
            throw new Error('Selecciona un cliente')
        }

        if ((!selectedServices || selectedServices?.length === 0) && invoice?.invoiceType !== 'P') {
            message.error('Por favor selecciona al menos un servicio')
            throw new Error('Selecciona al menos un servicio')
        }
    }

    const prepareInvoice = (values: any) => {
        let inv: any = {}

        const isGlobalInvoiceEnabled = client?.rfc === 'XAXX010101000' || client?.rfc === 'XEXX010101000'
        const isGeneralPublic = client?.legal_name?.toUpperCase() === 'PUBLICO EN GENERAL'

        if (isGlobalInvoiceEnabled && isGeneralPublic && !values.isGlobal) {
            const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0')
            const currentYear = new Date().getFullYear()

            values.isGlobal = true
            values.global = {
                periodicity: 'month',
                months: currentMonth,
                year: currentYear,
            }
            values.periodicity = 'month'
            values.months = currentMonth
            values.year = currentYear
        }

        switch (invoice?.type) {
            case 'I':
                inv = handleIncomeInvoiceObject({
                    values,
                    invoice,
                    client,
                    items: selectedServices,
                })
                break
            case 'P':
                inv = handlePaymentInvoiceObject({ values, invoice, state, client })
                break
            case 'E':
                inv = handleEgressInvoiceObject({
                    values,
                    invoice,
                    client,
                    items: selectedServices,
                })
                break
            default:
                inv = handleIncomeInvoiceObject({
                    values,
                    invoice,
                    client,
                    items: selectedServices,
                })
                break
        }
        if (values.automations) {
            inv.automations = manageAutomations({ toCreate: 'payment', from: 'invoices' })
        }

        let metadata
        try {
            generateObjectFromKeys(values?.metadata ?? [])
        } catch (error) {
            console.log(error)
        }
        if (values.relatedDocuments && values.relatedDocuments.length > 0 && values.relationship) {
            inv.related_documents = [
                {
                    documents: values.relatedDocuments,
                    relationship: values.relationship,
                },
            ]
        }

        inv = {
            ...inv,
            from: inv?.from ?? 'manual',
            test: values.test,
            livemode: !values.test,
            metadata: {
                orderId: values?.orderId || null,
                ...(metadata ?? {}),
            },
            emails: values?.emails,
            appVersion: '2.0.0',
            type: 'create_invoice',
            currency: values.currency || 'MXN',
            exchange: values.exchange || 1,
            exchangeRate: values.exchange || 1,
            exchange_rate: values.exchange || 1,
            clientId: client?.id,
            clientID: client?.id,
            client: client,
            invoiceType: invoice?.type ?? invoice?.invoiceType ?? 'I',
            team: team.id,
            date: values?.date ? values?.date?.toISOString() : dayjs().toISOString(),
            billingAccount: team.billingAccount,
            items: selectedServices,
            v4: true,
            folio_number: values.folio ?? values?.folioNumber ?? 0,
            forceAutomaticFolio: values.forceAutomaticFolioCheck ?? false,
            series: values.series,
            invoiceSingleExtra: values.notes || null,
            idempotency_key: idempotency_key,
            payments: invoice.payments ?? [],
            payment_form: values.payment_form ?? values?.paymentForm,
            payment_method: values.payment_method ?? values?.paymentMethod,
        }
        if (team?.defaults?.useFtpiFolio) {
            delete inv.folio_number
        }
        if ((inv?.related_documents || []).length === 0) {
            delete inv.related_documents
        }
        if ((inv.complements || [])?.length === 0) {
            delete inv.complements
        }

        return inv
    }

    const prepareRecurringEvent = (invoice: any) => {
        const id = recurringEventData?.id ?? generateCode(15, 're')
        const sm = SummarizeMessage({
            startDate:
                typeof recurringEventData?.startDate !== 'number'
                    ? recurringEventData?.startDate
                    : dayjs(recurringEventData?.startDate).valueOf(),
            endDate:
                typeof recurringEventData?.endDate !== 'number'
                    ? recurringEventData?.endDate
                    : dayjs(recurringEventData?.endDate).valueOf(),
            onTime: recurringEventData?.onTime,
            temporality: recurringEventData?.temporality,
            onWeekday: recurringEventData?.onWeekday,
            onDay: recurringEventData?.onDay,
        })

        const recurringEvent = {
            team: team?.id,
            billingAccount: team?.billingAccount,
            owner: user.uid,
            processed: false,
            replicateElement: { ...invoice, from: 'recurringEvent' },
            elementType: 'invoice',
            id,
            startDate: moment(recurringEventData?.startDate).valueOf(),
            startDateUTC: moment(recurringEventData.startDate).utc().valueOf(),
            startDateString: moment(recurringEventData.startDate).format('D MMMM YYYY HH:mm'),
            endDate: moment(recurringEventData.endDate).valueOf(),
            endDateUTC: moment(recurringEventData.endDate).utc().valueOf(),
            endDateString: moment(recurringEventData.endDate).format('D MMMM YYYY HH:mm'),
            onWeekday: recurringEventData.onWeekday ?? null,
            onDay: recurringEventData.onDay ?? null,
            onTime: recurringEventData.onTime,
            temporality: recurringEventData.temporality ?? null,
            timestamp: recurringEventData.timestamp ?? moment().valueOf(),
            timestampUTC: recurringEventData?.timestampUTC ?? moment().utc().valueOf(),
            client: client ?? null,
            clientID: client?.id ?? null,
            clientId: client?.id ?? null,
            nextRun: sm.nextHit,
            nextRunUTC: sm.nextHit ? moment(sm.nextHit).utc().valueOf() : null,
            nextRunString: sm.nextHit ? moment(sm.nextHit).format('D MMMM YYYY HH:mm') : null,
            status: 'active',
            type: 'invoice',
            startEndDate: [
                moment(recurringEventData?.startDate).format(),
                moment(recurringEventData?.endDate).format(),
            ],
            lastUpdate: moment().valueOf(),
        }

        return recurringEvent
    }

    const createInvoice = async (values: any) => {
        try {
            await checkRequirements()
        } catch (error: any) {
            return
        }

        setErrors([])
        const inv = prepareInvoice(values)

        // inv.invoiceType = invoice?.type ?? invoice?.invoiceType ?? 'I'
        inv.folio_number = values?.folio

        const invoicePreDoc = {
            ...invoice,
            ...inv,
            payment_form: values?.payment_form ?? values?.paymentForm,
            payment_method: values?.payment_method ?? values?.paymentMethod,
            folio: values?.folio,
            date: values?.date ? values?.date?.toISOString() : dayjs().toISOString(),
            from: invoice?.from ?? 'manual',
            loading: true,
            error: null,
        }

        if ((invoicePreDoc?.complements ?? [])?.length > 0 && invoicePreDoc?.invoiceType === 'P') {
            const complement = invoicePreDoc.complements.reduce(
                (acc: any, current: any) => {
                    acc.data = acc.data.concat(current.data)
                    return acc
                },
                { type: 'pago', data: [] },
            )

            invoicePreDoc.complements = [complement]
        }

        // console.log("INVOICE PRE DOC: ", invoicePreDoc)

        for (const key of invoice?.keysToDelete ?? []) {
            delete invoicePreDoc[key]
        }
        delete invoicePreDoc.keysToDelete

        dispatch(
            setData({
                item: 'invoice',
                data: {
                    ...invoice,
                    loading: (invoice?.loading ?? []).concat('creatingInvoice'),
                },
            }),
        )

        try {
            if (invoice.from === 'recurringEvent') {
                const recurringEvent = prepareRecurringEvent(inv)

                if (invoice?.id) {
                    await updateDoc(doc(getFirestore(), 'recurringEvents', invoice?.id), {
                        ...recurringEvent,
                        updateList: arrayUnion(moment().valueOf()),
                    })
                    return dispatch(
                        setData({
                            item: 'invoice',
                            data: {
                                ...invoice,
                                ...inv,
                                step: 2,
                                from: 'recurringEvent',
                                invoiceType: invoice?.invoiceType ?? invoice?.type ?? 'I',
                                loading: [],
                                error: null,
                            },
                        }),
                    )
                } else {
                    await SignedInternalAPIRequest({ ...recurringEvent }, 'createRecurringEventSeats', auth.currentUser)

                    trackEvent({ name: 'recurring_invoice_created', metadata: { id: recurringEvent.id } }, posthog)
                    return dispatch(
                        setData({
                            item: 'invoice',
                            data: {
                                ...invoice,
                                ...inv,
                                step: 2,
                                type: invoice?.type,
                                folio: values?.folio,
                                from: 'recurringEvent',
                                invoiceType: invoice?.invoiceType ?? invoice?.type ?? 'I',
                                loading: false,
                                error: null,
                            },
                        }),
                    )
                }
            } else {
                const resp = await SignedInternalAPIRequest(
                    invoicePreDoc,
                    'invoicing',
                    auth.currentUser,
                    {},
                    'POST',
                    false,
                    false,
                )

                const { invoice_folio, invoice_folio_egress, invoice_folio_payments } = getFolioByInvoiceType(
                    invoicePreDoc,
                    team,
                    dispatch,
                )

                // console.log("SET TEAM CREATE INVOICE DRAWER v2")
                dispatch(
                    setTeam({
                        ...team,
                        // Change the folio number if the invoice is income,
                        invoice_folio: inv?.invoiceType === 'I' ? inv?.folio_number : invoice_folio,
                        invoice_folio_egress: inv?.invoiceType === 'E' ? inv?.folio_number + 1 : invoice_folio_egress,
                        invoice_folio_payments:
                            inv?.invoiceType === 'P' ? inv?.folio_number + 1 : invoice_folio_payments,
                    }),
                )

                // console.log("FOLIOS: ", {
                //     invoice_folio: inv?.invoiceType === 'I' ? inv?.folio_number : invoice_folio,
                //     invoice_folio_egress:
                //         inv?.invoiceType === 'E' ? inv?.folio_number + 1 : invoice_folio_egress,
                //     invoice_folio_payments:
                //         inv?.invoiceType === 'P' ? inv?.folio_number + 1 : invoice_folio_payments,
                // })

                if (inv?.invoiceType === 'P' || inv?.invoiceType === 'E') {
                    let toUpdate = {}

                    if (inv?.invoiceType === 'P') {
                        toUpdate = {
                            invoice_folio_payments: increment(1),
                        }
                    }

                    if (inv?.invoiceType === 'E') {
                        toUpdate = {
                            invoice_folio_egress: increment(1),
                        }
                    }

                    try {
                        await updateDoc(doc(getFirestore(), 'teams', team.id), {
                            ...toUpdate,
                        })
                    } catch (error) {
                        console.error('Error incrementing folio number')
                    }
                }

                if (inv?.invoiceType === 'P') {
                    try {
                        const batch = writeBatch(getFirestore())
                        for (const complement of inv.complements) {
                            const relatedDocuments = complement.data[0].related_documents
                            for (const rDoc of relatedDocuments) {
                                const q = query(
                                    collection(getFirestore(), 'invoices'),
                                    where('uuid', '==', rDoc.uuid),
                                    where('team', '==', team.id),
                                )
                                const docs = await getDocs(q)
                                for (const doc of docs.docs) {
                                    batch.update(doc.ref, {
                                        installment: rDoc.installment,
                                        lastBalance: rDoc.last_balance - rDoc.amount,
                                    })
                                }
                            }
                        }

                        await batch.commit()
                    } catch (error) {
                        console.error('Error updating related documents', error)
                    }
                }

                //         // const folioValue =
                //         //     invoice?.invoiceType === 'I'
                //         //         ? invoice_folio
                //         //         : invoice?.invoiceType === 'E'
                //         //             ? invoice_folio_egress
                //         //             : invoice_folio_payments

                trackEvent({ name: 'invoice_created', metadata: { id: resp.id } }, posthog)
                form.resetFields()
                dispatch(
                    setData({
                        item: 'invoice',
                        data: {
                            ...invoicePreDoc,
                            loading: false,
                            id: resp.id,
                            uuid: resp.uuid,
                            step: 2,
                            from: 'manual',
                            error: null,
                        },
                    }),
                )

                dispatch(
                    setData({
                        item: 'client',
                        data: null,
                    }),
                )

                dispatch(
                    setData({
                        item: 'selectedServices',
                        data: null,
                    }),
                )
            }
        } catch (error: any) {
            dispatch(
                setData({
                    item: 'invoice',
                    data: {
                        ...invoice,
                        error: error?.message ?? error?.error ?? error,
                    },
                }),
            )
            // return setErrors(error ?? [])
            //     form.setFieldsValue({
            //         ...invoice,
            //         ...inv,
            //         // paymentMethod: invoice?.payment_method || invoice?.paymentMethod || 'PUE',
            //         // paymentForm: invoice?.payment_form || invoice?.paymentForm,
            //         date: invoice?.date ? dayjs(invoice?.date) : dayjs(),
            //     })

            //     inv.folio_number -= 1
            //     dispatch(
            //         setData({
            //             item: 'invoice',
            //             data: {
            //                 ...invoice,
            //                 ...inv,
            //                 type: invoice?.type ?? invoice?.invoiceType ?? 'I',
            //                 invoiceType: invoice?.invoiceType ?? invoice?.type ?? 'I',
            //                 from: 'manual',
            //                 loading: false,
            //                 error: error?.message ?? error?.error ?? error,
            //             },
            //         })
            //     )
        }
    }

    const RenderInfoByType = () => {
        const type = invoice?.invoiceType ?? recurringEventData?.replicateElement?.invoiceType

        switch (type) {
            case 'I':
                return <IncomeInvoice form={form} />
            case 'P':
                return <PaymentComplement form={form} />
            case 'E':
                return <EgressInvoice form={form} />
            default:
                return <IncomeInvoice form={form} />
        }
    }

    const buttonText =
        invoice?.from === 'recurringEvent' && recurringEventData?.id
            ? 'Editar evento recurrente'
            : invoice?.from === 'recurringEvent' && !invoice?.id
              ? 'Crear factura recurrente'
              : 'Crear factura'

    const availableEmails: any = [
        ...new Set(
            [...(client?.bcc || []), client?.email, ...(team?.contactEmails || [])].filter((email: any) => email),
        ),
    ].map((email: any) => {
        return email
    })

    return (
        <Row>
            <Col xs={24}>
                <Form
                    layout="vertical"
                    initialValues={{
                        ...invoice,
                        date: invoice?.from === 'recurringEvent' ? dayjs(invoice?.date ?? invoice?.timestamp) : dayjs(),
                        paymentForm: invoice?.paymentForm || invoice?.payment_form,
                        emails: availableEmails,
                    }}
                    form={form}
                    onFinish={createInvoice}
                    onFinishFailed={async (e: any) => {
                        setErrors(e?.errorFields ?? [])
                        message.error('Por favor completa los campos requeridos')
                    }}
                    disabled={(invoice.loading ?? []).includes('creatingInvoice')}
                >
                    <Col xs={24} className="d-flex flex-column" style={{ marginBottom: '15px' }}>
                        <Typography.Title className="bigparagraph" level={5}>
                            Cliente
                        </Typography.Title>
                        <SelectClientCard defaultClient={invoice?.client} />
                    </Col>

                    <RenderInfoByType />

                    {invoice?.invoiceType === 'I' && (
                        <Col style={{ marginTop: '20px' }}>
                            <Typography.Title className="bigparagraph" level={5}>
                                Automatizaciones
                            </Typography.Title>
                            <CheckBoxInput
                                name="automations"
                                content={
                                    <>
                                        {' '}
                                        <Typography.Text
                                            type="secondary"
                                            className="strong"
                                            style={{ fontSize: '13px' }}
                                        >
                                            Crear una solicitud de pago con la factura
                                        </Typography.Text>
                                    </>
                                }
                            />
                        </Col>
                    )}
                    <Divider />
                </Form>
            </Col>
            <Col xs={24}>
                <Row justify={'center'}>
                    <Col xs={24} style={{ marginTop: '15px' }}>
                        {invoice?.from === 'recurringEvent' && (
                            <>
                                <RecurringEventDetails form={calendarForm} />
                            </>
                        )}
                    </Col>
                    <Col xs={24}>
                        <ErrorsInFormComponent
                            formErrors={errors}
                            form={form}
                            title="Error en los datos"
                            errorMessage={invoice.error}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={24}>
                <Row justify="space-between" style={{ marginTop: '20px' }}>
                    <Button
                        onClick={() => {
                            dispatch(
                                setData({
                                    item: 'invoice',
                                    data: {
                                        ...invoice,
                                        step: 0,
                                    },
                                }),
                            )
                        }}
                    >
                        Regresar
                    </Button>

                    <Button
                        type="primary"
                        id="createInvoiceButton"
                        loading={(invoice?.loading ?? [])?.includes('creatingInvoice')}
                        onClick={() => {
                            form.submit()
                        }}
                    >
                        {buttonText}
                        <CaretDoubleRight size={15} style={{ marginLeft: '5px' }} className="" weight="regular" />
                    </Button>
                </Row>
            </Col>
        </Row>
    )
}

const getFolioFromFtpiSerie = async (team: any, auth: any) => {
    const ftpiData = await SignedInternalAPIRequest({}, 'facturapiAppHandlerInfo/data', auth.currentUser, {}, 'GET')
    const series = ftpiData?.find((series: any) =>
        team?.invoice_serie ? series.series === team?.invoice_serie : series.series === 'G',
    )

    const incomeFolio = series ? series.next_folio : 1
    const incomeFolioTest = series ? series.next_folio_test : 1

    return { incomeFolio, incomeFolioTest }
}

const SelectInvoiceType = () => {
    const dispatch = useDispatch()
    const auth = useAuth()

    const { invoice, testmode } = useSelector((state: any) => state.data)
    const { loading } = invoice ?? {}
    const { team } = useSelector((state: any) => state.team)
    // GetTeamHelper(team.id, dispatch)

    const getFolioFromFtpi = async () => {
        if (invoice?.invoiceType !== 'I') {
            return dispatch(
                setData({
                    item: 'invoice',
                    data: {
                        ...invoice,
                        loading: loading?.filter((item: string) => item !== 'gettingFtpiData'),
                        folio_number:
                            invoice?.invoiceType === 'P'
                                ? (team?.invoice_folio_payments ?? 1)
                                : (team?.invoice_folio_egress ?? 1),
                        step: 1,
                        forceAutomaticFolioCheck: true,
                    },
                }),
            )
        }

        try {
            dispatch(
                setData({
                    item: 'invoice',
                    data: {
                        ...invoice,
                        loading: [...(loading ?? []), 'gettingFtpiData'],
                    },
                }),
            )

            const { incomeFolio, incomeFolioTest } = await getFolioFromFtpiSerie(team, auth)

            dispatch(
                setData({
                    item: 'invoice',
                    data: {
                        ...invoice,
                        loading: loading?.filter((item: string) => item !== 'gettingFtpiData'),
                        folio_number: (testmode ? incomeFolioTest : incomeFolio) ?? team?.invoice_folio ?? 1,
                        test_folio_number: incomeFolioTest ?? team?.invoice_folio_test ?? 1,
                        forceAutomaticFolioCheck: true, //team?.defaults?.useFtpiFolio,
                        step: 1,
                        keysToDelete: [
                            ...(invoice?.keysToDelete ?? []),
                            'forceAutomaticFolioCheck',
                            'test_folio_number',
                            'loading',
                        ],
                    },
                }),
            )
        } catch (error) {
            console.log(error)
        }
    }

    const ChangeInvoiceType = ({ type }: { type: 'I' | 'P' | 'E' }) => {
        const idk = generateCode(15, 'idpkey')
        dispatch(
            setData({
                item: 'idempotency_key',
                data: idk,
            }),
        )
        dispatch(
            setData({
                item: 'invoice',
                data: {
                    ...invoice,
                    invoiceType: type,
                    idempotency_key: idk,
                },
            }),
        )
        addParamToUrl('invoiceType', type)
    }

    return (
        <Row style={{ marginTop: '35px' }}>
            <Col xs={24}>
                <Row align="middle" justify="center">
                    <Typography.Text className="bigparagraph">Selecciona el tipo de factura</Typography.Text>
                </Row>
                <Row align="middle" justify="center" style={{ marginTop: '30px' }}>
                    <Space>
                        <Popover
                            content={
                                <InvoiceTypeDescription
                                    icon={
                                        <ChartLineUp className="green" weight="regular" style={{ fontSize: '24px' }} />
                                    }
                                    description="son todos aquellos comprobantes que se emiten al realizar un trámite de compra-venta"
                                    boldDescription="Los CFDI de ingreso "
                                />
                            }
                            placement="bottom"
                            arrow={false}
                        >
                            <Button
                                onClick={() => ChangeInvoiceType({ type: 'I' })}
                                className={invoice?.invoiceType === 'I' ? 'activeBtn' : ''}
                            >
                                Factura de ingreso
                            </Button>
                        </Popover>
                        <Popover
                            content={
                                <InvoiceTypeDescription
                                    icon={
                                        <CurrencyCircleDollar
                                            className="blue"
                                            weight="regular"
                                            style={{ fontSize: '24px' }}
                                        />
                                    }
                                    description="son todos los comprobantes que se emiten relacionados a un CFDI ingreso en PPD con los detalles del pago"
                                    boldDescription="Los CFDI de pago"
                                />
                            }
                            placement="bottom"
                            arrow={false}
                        >
                            <Button
                                onClick={() => ChangeInvoiceType({ type: 'P' })}
                                className={invoice?.invoiceType === 'P' ? 'activeBtn' : ''}
                            >
                                Factura de pago
                            </Button>
                        </Popover>
                        <Popover
                            content={
                                <InvoiceTypeDescription
                                    icon={
                                        <ChartLineDown className="red" weight="regular" style={{ fontSize: '24px' }} />
                                    }
                                    description="son todos aquellos comprobantes que detallan una transacción de egreso, por ejemplo una devolución"
                                    boldDescription="Los CFDI de egreso"
                                />
                            }
                            placement="bottom"
                            arrow={false}
                        >
                            <Button
                                onClick={() => ChangeInvoiceType({ type: 'E' })}
                                className={invoice?.invoiceType === 'E' ? 'activeBtn' : ''}
                            >
                                Nota de crédito
                            </Button>
                        </Popover>
                    </Space>
                </Row>
                {invoice?.invoiceType && (
                    <Row style={{ marginTop: '30px' }} justify="end">
                        <Button
                            loading={(loading ?? [])?.includes('gettingFtpiData')}
                            type="primary"
                            onClick={async () => {
                                await getFolioFromFtpi()
                            }}
                        >
                            Siguiente
                        </Button>
                    </Row>
                )}
            </Col>
        </Row>
    )
}

// INVOICE EXTRAS

const InvoiceExtras = () => {
    return (
        <>
            <InvoiceEmailsComponent />
            <InvoiceTestMode />
            <InvoiceMetadata />
        </>
    )
}

// MORE OPTIONS

const MoreOptionsButton = () => {
    const dispatch = useDispatch()
    const { invoice } = useSelector((state: any) => state.data)
    return (
        <>
            <Row justify="start" style={{ width: 'auto' }}>
                <Button
                    type="link"
                    className="primary"
                    style={{ padding: '0px' }}
                    onClick={() => {
                        // console.log(!invoice?.showMoreOptions);

                        dispatch(
                            setData({
                                item: 'invoice',
                                data: {
                                    ...invoice,
                                    showMoreOptions: !invoice?.showMoreOptions,
                                    keysToDelete: [...(invoice?.keysToDelete ?? []), 'showMoreOptions'],
                                },
                            }),
                        )
                    }}
                >
                    Ver opciones adicionales
                    {invoice.showMoreOptions ? (
                        <CaretUp className="primary" size={16} weight="regular" style={{ marginLeft: '5px' }} />
                    ) : (
                        <CaretDown className="primary" size={16} weight="regular" style={{ marginLeft: '5px' }} />
                    )}
                </Button>
            </Row>
            <div style={!invoice.showMoreOptions ? { display: 'none' } : {}}>
                <InvoiceMoreOptionsSection />
            </div>
        </>
    )
}

// DRAWER HEADER

export const DrawerHeader = ({
    edit,
    ctxModalName,
    ctxElementName,
    title,
    buttonText,
    form,
    state,
}: {
    edit: Boolean
    ctxModalName:
        | 'createClientDrawerVisible'
        | 'serviceModalVisible'
        | 'paymentModalVisible'
        | 'invoiceModalVisible'
        | 'createMassiveInvoicesModalVisible'
    ctxElementName: 'client' | 'invoice' | 'payment' | 'service' | 'receipt'
    readableElementType: 'factura' | 'pago' | 'recibo' | 'cliente' | 'servicio'
    title: string
    buttonText: string
    form: FormInstance
    state: any
}) => {
    const dispatch = useDispatch()
    const { invoice } = useSelector((state: any) => state.data)

    const titleComplement =
        invoice?.invoiceType === 'I'
            ? 'de ingreso'
            : invoice?.invoiceType === 'P'
              ? 'de pago'
              : invoice?.invoiceType === 'E'
                ? 'de egreso'
                : ''

    return (
        <div style={{ position: 'relative' }}>
            <Typography.Text
                className="smallheader"
                style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
            >
                {title} {titleComplement ? titleComplement : ''}
            </Typography.Text>
            <Row>
                <Col xs={24} style={{ margin: 'auto' }}>
                    <Row justify="space-between" align="middle">
                        <X
                            className="icon clickable"
                            size={20}
                            onClick={() => {
                                dispatch(setData({ item: ctxElementName, data: {} }))
                                dispatch(closeModal(ctxModalName))
                                removeParamFromUrl('open')
                                removeParamFromUrl('invoiceType')
                            }}
                        />
                        <></>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

// INVOICES FORMS

const IncomeInvoice = ({ form }: { form: any }) => {
    const dispatch = useDispatch()
    const auth = useAuth()

    const { exchangeRates, invoice } = useSelector((state: any) => state.data)

    const { team } = useSelector((state: any) => state.team)
    const { testmode } = useSelector((state: any) => state.data)

    const initialFolioValue = invoice?.folio_number

    return (
        <>
            <Row justify="center" style={{ marginTop: '30px' }}>
                <Col xs={24}>
                    <Typography.Title className="bigparagraph" level={5}>
                        Información General
                    </Typography.Title>
                    <Row justify="space-between" gutter={10}>
                        <Col xs={24} md={12}>
                            <TextInput
                                name="series"
                                label="Serie"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor escribe la serie de la factura',
                                    },
                                ]}
                            />
                        </Col>
                        {invoice?.from !== 'recurringEvent' && (
                            <>
                                <Col xs={12} style={{ marginBottom: '1rem' }}>
                                    <NumberInput
                                        name="folio"
                                        label="Folio"
                                        disabled={invoice?.forceAutomaticFolioCheck}
                                        initialValue={initialFolioValue}
                                        min={0}
                                        max={100000000000}
                                        propsStyle={{ marginBottom: '.5rem' }}
                                        step={1}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor escribe el folio de la factura',
                                            },
                                        ]}
                                    />

                                    <Form.Item name="forceAutomaticFolioCheck" valuePropName="checked">
                                        <Checkbox
                                            onChange={async (v) => {
                                                v.preventDefault()
                                                const checked = v.target.checked

                                                let data = {
                                                    ...invoice,
                                                    forceAutomaticFolioCheck: v.target.checked,
                                                    deleteKeys: [
                                                        ...(invoice?.deleteKeys ?? []),
                                                        'forceAutomaticFolioCheck',
                                                    ],
                                                }

                                                if (checked) {
                                                    const { incomeFolio, incomeFolioTest } =
                                                        await getFolioFromFtpiSerie(team, auth)

                                                    data = {
                                                        ...data,
                                                        folio_number:
                                                            (testmode ? incomeFolioTest : incomeFolio) ??
                                                            team?.invoice_folio ??
                                                            1,
                                                        test_folio_number:
                                                            incomeFolioTest ?? team?.invoice_folio_test ?? 1,
                                                    }

                                                    form.setFieldsValue({
                                                        folio: data.folio_number,
                                                        test_folio: data.test_folio_number,
                                                    })
                                                }

                                                dispatch(setData({ item: 'invoice', data }))
                                            }}
                                        >
                                            Folio Automático
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </>
                        )}

                        <Col xs={24} md={12}>
                            <SelectInput
                                name="use"
                                label="Uso del CFDI"
                                options={CFDIUses}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor selecciona el uso de la factura',
                                    },
                                ]}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectInput
                                name="payment_form"
                                label="Forma de pago"
                                placeholder="Selecciona una forma de pago"
                                options={paymentForms}
                                onChange={(v: any) => {
                                    if (team?.defaults?.manualInvoicesConfig?.['PUE'] === v) {
                                        form.setFieldsValue({
                                            paymentMethod: 'PUE',
                                        })
                                    }
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor selecciona la forma de pago',
                                    },
                                ]}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectInput
                                name="paymentMethod"
                                label="Método de pago"
                                placeholder="Selecciona un método de pago"
                                options={paymentMethods}
                                onChange={(v: any) => {
                                    console.log('v', v)
                                    if (team?.defaults?.manualInvoicesConfig?.[v]) {
                                        form.setFieldsValue({
                                            payment_form: team?.defaults?.manualInvoicesConfig?.[v] || '99',
                                        })
                                    }
                                    if (v === 'PPD') {
                                        form.setFieldsValue({
                                            payment_form: '99',
                                        })
                                    }
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor selecciona el método de pago',
                                    },
                                ]}
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <SelectInput
                                name="currency"
                                label="Moneda"
                                options={currecies}
                                onChange={(currency: any) => {
                                    form.setFieldsValue({
                                        exchange: (1 / exchangeRates[currency]).toFixed(4),
                                    })
                                }}
                            />
                        </Col>
                        <Col xs={12} md={12} xl={12} xxl={12}>
                            <TextInput
                                name="exchange"
                                label="Tipo de cambio"
                                disabled={invoice?.currency === 'MXN' ? true : false}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor escribe el tipo de cambio',
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <MoreOptionsButton />
                </Col>
            </Row>
            <InvoiceProductsServices />
            <InvoiceExtras />
        </>
    )
}

const InvoiceMoreOptionsSection = () => {
    const dispatch = useDispatch()
    const { invoice, client } = useSelector((state: any) => state.data)

    const isGlobalInvoiceEnabled = client?.rfc === 'XAXX010101000' || client?.rfc === 'XEXX010101000'

    return (
        <div>
            <Row justify="start" align="middle" style={{ marginTop: '15px' }}>
                <Form.Item
                    labelAlign="right"
                    label="¿Generar factura global?"
                    valuePropName="checked"
                    name="isGlobal"
                    tooltip={
                        !isGlobalInvoiceEnabled
                            ? 'El cliente debe tener RFC XAXX010101000 o XEXX010101000 para generar factura global'
                            : 'Activar factura global'
                    }
                >
                    <Switch
                        disabled={!isGlobalInvoiceEnabled}
                        onChange={(checked) => {
                            dispatch(
                                setData({
                                    item: 'invoice',
                                    data: {
                                        ...invoice,
                                        isGlobal: checked,
                                        global: checked
                                            ? {
                                                  periodicity: undefined,
                                                  months: undefined,
                                                  year: undefined,
                                              }
                                            : undefined,
                                    },
                                }),
                            )
                        }}
                    />
                </Form.Item>
            </Row>

            {invoice?.isGlobal && (
                <Row justify="center" gutter={10} style={{ marginTop: '10px' }}>
                    <Col xs={8}>
                        <SelectInput
                            name="periodicity"
                            label="Periodicidad"
                            options={PERIODICITIES}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecciona la periodicidad',
                                },
                            ]}
                        />
                    </Col>
                    <Col xs={8}>
                        <SelectInput
                            name="months"
                            label="Mes"
                            options={GLOBAL_INVOICE_MONTHS}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecciona el mes',
                                },
                            ]}
                        />
                    </Col>
                    <Col xs={8}>
                        <NumberInput
                            name="year"
                            label="Año"
                            min={2000}
                            max={2100}
                            step={1}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresa el año',
                                },
                            ]}
                        />
                    </Col>
                </Row>
            )}

            {invoice?.type === 'P' && (
                <>
                    <Row justify="start" align="middle" style={{ marginTop: '15px' }}>
                        <Form.Item
                            labelAlign="right"
                            label="¿Es un pago por cuenta de terceros?"
                            valuePropName="checked"
                            name="isThirdParty"
                        >
                            <Switch
                                onChange={() => {
                                    dispatch(
                                        setData({
                                            item: 'invoice',
                                            data: {
                                                ...invoice,
                                                isThirdParty: !invoice?.isThirdParty,
                                            },
                                        }),
                                    )
                                }}
                            />
                        </Form.Item>
                    </Row>
                    {invoice?.isThirdParty && (
                        <Row justify="center" gutter={10} style={{ marginTop: '10px' }}>
                            <Col xs={12}>
                                <TextInput
                                    name="thirdPartyLegalName"
                                    label="Nombre legal"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Es necesario capturar el nombre legal',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col xs={12}>
                                <TextInput
                                    name="thirdPartyTaxId"
                                    label="RFC"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Es necesario capturar el RFC',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col xs={12}>
                                <TextInput
                                    name="thirdPartyZip"
                                    label="Código postal"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Es necesario capturar el código postal',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col xs={12}>
                                <SelectInput
                                    name="thirdPartyTaxRegimen"
                                    label="Régimen fiscal"
                                    options={taxRegimes}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Es necesario capturar el régimen fiscal',
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    )}
                </>
            )}

            <Row justify="center" gutter={10} style={{ marginTop: '10px' }}>
                <Col xs={12}>
                    <DateInput
                        name="date"
                        label="Fecha"
                        defaultValue={dayjs()}
                        showTime={true}
                        disabledDate={(currentDate: Dayjs) => {
                            const limitInvoiceDate = dayjs().subtract(72, 'hours').startOf('day')
                            return (
                                currentDate && (currentDate.isBefore(limitInvoiceDate) || currentDate.isAfter(dayjs()))
                            )
                        }}
                        disabledTime={(date: Dayjs) => {
                            const now = dayjs()
                            const limitInvoiceDate = now.subtract(72, 'hours')

                            if (date.isSame(limitInvoiceDate, 'day')) {
                                return {
                                    disabledHours: () => Array.from(Array(24).keys()).slice(0, limitInvoiceDate.hour()),
                                    disabledMinutes: () =>
                                        Array.from(Array(60).keys()).slice(0, limitInvoiceDate.minute()),
                                }
                            }

                            if (date.isSame(now, 'day')) {
                                return {
                                    disabledHours: () => Array.from(Array(24).keys()).slice(now.hour() + 1),
                                    disabledMinutes: () => Array.from(Array(60).keys()).slice(now.minute() + 1),
                                }
                            }

                            return {}
                        }}
                    />
                </Col>
                <Col xs={12}>
                    <TextInput name="orderId" label="Número de orden (Order ID)" />
                </Col>
                <Col xs={24}>
                    <TextAreaInput name="notes" label="Agrega una nota a tu factura" />
                </Col>
            </Row>
            <Row justify="center" gutter={10} style={{ marginTop: '20px' }}>
                <Col xs={12}>
                    <ChipsSelect
                        name="relatedDocuments"
                        label="CFDI Relacionados"
                        placeholder="Pega los folios fiscales de los CFDI"
                        options={[]}
                        change={() => {}}
                    />
                </Col>
                <Col xs={12}>
                    <SelectInput name="relationship" label="Tipo de relación" options={CFDIRelations} />
                </Col>
            </Row>
            {/* <Row justify="end" align="middle">
            <Button type="link" className="primary" style={{ padding: 0 }}>
                <PlusCircle className="primary" size={16} weight="regular" style={{ marginRight: '5px' }} />
                Agregar otra relación
            </Button>
        </Row> */}
        </div>
    )
}

//***COMPLEMENTO DE PAGO***//
const PaymentComplement = ({ form }: { form: FormInstance }) => {
    const dispatch = useDispatch()
    const posthog = usePostHog()
    const { team } = useSelector((state: any) => state.team)
    const { invoice, client, testmode, invoiceComplement } = useSelector((state: any) => state.data)

    const recurringInvoice = invoice?.from === 'recurringEvent'

    posthog.setPersonPropertiesForFlags({ lastTeamViewed: team?.id })

    let invoiceHelperFlag = posthog.isFeatureEnabled('invoice_complements_helpers')

    // posthog.onFeatureFlags(function () {
    //     // feature flags should be available at this point
    //     if (posthog.isFeatureEnabled('invoice_complements_helpers')) {
    //         invoiceHelperFlag = posthog.isFeatureEnabled('invoice_complements_helpers')
    //     }
    // })

    // console.log(invoiceHelperFlag);

    // console.log(...[...invoice?.complements?.map((c: any) => c.data)].map((d: any) => d.related_documents))//.map((d: any) => d?.related_documents));

    const complements = invoice?.complements
    const relatedDocuments = complements?.map((c: any) => c.data) // THIS IS AN ARRAY OF ARRAYS
    const relatedDocumentsFlatten = relatedDocuments
        ?.flat()
        .map((r: any) => r.related_documents)
        .flat() // THIS IS AN ARRAY OF OBJECTS
    const total = relatedDocumentsFlatten?.reduce((a: any, b: any) => a + b.amount, 0)

    const FolioInput = ({ form }: { form: FormInstance }) => {
        const { data: teamRt } = useFirestoreDocData(doc(getFirestore(), 'teams', team?.id || 'gh'))

        useEffect(() => {
            form.setFieldsValue({
                folio: teamRt?.invoice_folio_payments,
            })

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [teamRt?.invoice_folio_payments])

        return (
            <>
                <Col xs={12} style={{ marginBottom: '1rem' }}>
                    <NumberInput
                        name="folio"
                        label="Folio"
                        min={0}
                        max={100000000000}
                        disabled={invoice?.forceAutomaticFolioCheck}
                        propsStyle={{ marginBottom: '.5rem' }}
                        step={1}
                        rules={[
                            {
                                required: true,
                                message: 'Por favor escribe el folio de la factura',
                            },
                        ]}
                    />
                    <Form.Item name="forceAutomaticFolioCheck" valuePropName="checked">
                        <Checkbox
                            onChange={(v) => {
                                dispatch(
                                    setData({
                                        item: 'invoice',
                                        data: {
                                            ...invoice,
                                            forceAutomaticFolioCheck: v.target.checked,
                                            deleteKeys: [...(invoice?.deleteKeys ?? []), 'forceAutomaticFolioCheck'],
                                        },
                                    }),
                                )
                            }}
                        >
                            Folio Automático
                        </Checkbox>
                    </Form.Item>
                </Col>
            </>
        )
    }

    // const checkPendingInvoices = async () => {
    //     const q = query(collection(getFirestore(), "invoices"), where("team", "==", team?.id), where("status", "==", "valid"), where("type", "==", "P"))
    //     const docs = await getDocs(q)
    //     console.log(docs.docs.length);
    //     return

    // }

    // useEffect(() => {
    //     checkPendingInvoices()

    //     console.log('PaymentComplement');

    // }, [])

    const ListOfPendingInvoices = () => {
        const { status, data } = useFirestoreCollectionData(
            query(
                collection(getFirestore(), 'invoices'),
                where('team', '==', team?.id),
                where('status', '==', 'valid'),
                where('payment_method', '==', 'PPD'),
                where('livemode', '==', !testmode),
                where('invoicePaid', '!=', true),
                where('clientId', '==', client?.id),
            ),
        )

        console.log(team.id, client?.id)
        if (status === 'success' && data.length <= 0) return <></>
        return (
            <div
                style={{
                    backgroundColor: '#fafbfd',
                    padding: '10px',
                    borderRadius: '5px',
                    // border: '1px solid #e8e8e8'
                }}
                className="d-flex flex-column"
            >
                <Row justify="space-between" align="middle">
                    <Typography.Text className="smallheader">Facturas de ingreso pendientes de pago</Typography.Text>
                    {status === 'loading' && <MetroSpinner size={15} color="#8666FF" />}
                </Row>
                <Table
                    columns={[
                        {
                            title: 'CFDI',
                            dataIndex: 'folio',
                            key: 'folio',
                            render: (text: any, record: any) => {
                                return (
                                    <div className="d-flex flex-column">
                                        <Typography.Text className="smallparagraph">
                                            {record.series}-{record?.folio}
                                        </Typography.Text>
                                        <Typography.Text className="smallparagraph">{record.uuid}</Typography.Text>
                                        {
                                            //show only in develepment

                                            <Typography.Text className="smallparagraph">{record.id}</Typography.Text>
                                        }
                                    </div>
                                )
                            },
                        },
                        {
                            title: 'Total',
                            dataIndex: 'total',
                            key: 'total',
                            render: (text: any, record: any) => {
                                return (
                                    <Typography.Text className="smallparagraph">
                                        {returnCurrencyValue(record?.total)}
                                    </Typography.Text>
                                )
                            },
                        },
                        {
                            title: 'Último balance',
                            dataIndex: 'lastBalance',
                            key: 'lastBalance',
                            render: (text: any, record: any) => {
                                const completion = ((record?.lastBalance ?? 0) / record?.total) * 100
                                return (
                                    <Space>
                                        <Typography.Text className="smallparagraph">
                                            {returnCurrencyValue(
                                                record?.total - (record?.lastBalance ?? record?.total),
                                            )}
                                        </Typography.Text>

                                        <Progress
                                            type="circle"
                                            trailColor="#cecece"
                                            percent={completion}
                                            strokeWidth={5}
                                            size={21}
                                            showInfo={false}

                                            // format={(number) => `${number}%`}
                                        />
                                    </Space>
                                )
                            },
                        },
                        {
                            title: '',
                            dataIndex: 'actions',
                            key: 'actions',
                            render: (text: any, record: any) => {
                                return (
                                    <Space>
                                        {
                                            //check if the invoice is already in the complement
                                            invoice?.complements
                                                ?.map((c: any) => c.data)
                                                .flat()
                                                .map((r: any) => r.related_documents)
                                                .flat()
                                                .map((r: any) => r.uuid)
                                                .includes(record.uuid) ? (
                                                <Tooltip title="Ya se encuentra en el complemento">
                                                    {' '}
                                                    <CheckSquare size={20} style={{ marginTop: '8px' }} />
                                                </Tooltip>
                                            ) : (
                                                <Button
                                                    onClick={() => {
                                                        const complementTaxes = getUniqueTaxes(
                                                            record?.internalItems ?? [],
                                                        )
                                                        const relatedDocument = {
                                                            uuid: record.uuid,
                                                            amount: record.total,
                                                            installment: record.installment ?? 1,
                                                            last_balance: record.lastBalance ?? record.total,
                                                            currency: record.currency ?? 'MXN',
                                                            exchange: Number(record.exchange ?? 1),
                                                            taxes: complementTaxes,
                                                        }
                                                        dispatch(
                                                            setData({
                                                                item: 'invoiceComplement',
                                                                data: {
                                                                    ...invoiceComplement,
                                                                    related_documents: [relatedDocument],
                                                                },
                                                            }),
                                                        )
                                                        dispatch(openModal('paymentComplementModalVisible'))
                                                    }}
                                                    style={{ margin: '3px' }}
                                                >
                                                    Crear complemento
                                                </Button>
                                            )
                                        }
                                    </Space>
                                )
                            },
                        },
                    ]}
                    pagination={{ hideOnSinglePage: true }}
                    dataSource={data}
                />
            </div>
        )
    }

    return (
        <>
            <Row justify="center" style={{ marginTop: '30px' }}>
                <Col xs={24}>
                    <Typography.Title className="bigparagraph" level={5}>
                        Información General
                    </Typography.Title>
                    <Row justify="space-between" gutter={10}>
                        <Col xs={24} lg={recurringInvoice ? 24 : 12}>
                            <TextInput
                                name="series"
                                label="Serie"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor escribe la serie de la factura',
                                    },
                                ]}
                            />
                        </Col>
                        {!recurringInvoice && <FolioInput form={form} />}
                    </Row>
                    <MoreOptionsButton />
                </Col>
                <Col
                    xs={24}
                    style={{
                        margin: '10px 0px',
                    }}
                >
                    {client && invoiceHelperFlag ? <ListOfPendingInvoices /> : <></>}
                </Col>
                <Col xs={24} style={{ marginTop: '25px' }}>
                    <Row justify="space-between" align="middle" style={{ marginBottom: '25px' }}>
                        <Typography.Text style={{ margin: 0, padding: 0 }} className="bigparagraph">
                            Pagos
                        </Typography.Text>
                        <Button
                            type="primary"
                            icon={<PlusCircle />}
                            onClick={() => dispatch(openModal('paymentComplementModalVisible'))}
                        >
                            Agregar pago
                        </Button>
                    </Row>
                    <Table
                        size="small"
                        columns={complementColumns}
                        dataSource={invoice?.complements?.map((c: any) => c.data)}
                        scroll={{ x: 300 }}
                    />
                    <Row justify="end">
                        <ul>
                            <li>
                                <Typography.Text className="smallparagraph descriptions">
                                    Total abonado: {returnCurrencyValue(total ?? 0)}
                                </Typography.Text>
                            </li>
                        </ul>
                        {/*TODO: AGREGAR MONTOS EN ESTE PUNTO  */}
                    </Row>
                    <Button
                        icon={<Plus />}
                        style={{ width: '100%' }}
                        onClick={() => {
                            dispatch(openModal('paymentComplementModalVisible'))
                        }}
                    >
                        Agregar pago
                    </Button>
                </Col>
            </Row>
            <InvoiceExtras />
        </>
    )
}
//***COMPLEMENTO DE PAGO***//

const EgressInvoice = ({ form }: { form: FormInstance }) => {
    const dispatch = useDispatch()

    const { invoice, exchangeRates } = useSelector((state: any) => state.data)
    const recurringInvoice = invoice?.from === 'recurringEvent'

    return (
        <div>
            <Row justify="center" style={{ marginTop: '30px' }}>
                <Col xs={24}>
                    <Typography.Title className="bigparagraph" level={5}>
                        Información General
                    </Typography.Title>
                    <Row justify="space-between" gutter={10}>
                        <Col xs={12} md={12} xl={12} xxl={12}>
                            <TextInput
                                name="series"
                                label="Serie"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor escribe la serie de la factura',
                                    },
                                ]}
                            />
                        </Col>
                        {!recurringInvoice && (
                            <>
                                <Col xs={12} style={{ marginBottom: '1rem' }}>
                                    <NumberInput
                                        name="folio"
                                        label="Folio"
                                        min={0}
                                        max={100000000000}
                                        disabled={invoice?.forceAutomaticFolioCheck}
                                        propsStyle={{ marginBottom: '.5rem' }}
                                        step={1}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor escribe el folio de la factura',
                                            },
                                        ]}
                                    />
                                    <Form.Item name="forceAutomaticFolioCheck" valuePropName="checked">
                                        <Checkbox
                                            onChange={(v) => {
                                                dispatch(
                                                    setData({
                                                        item: 'invoice',
                                                        data: {
                                                            ...invoice,
                                                            forceAutomaticFolioCheck: v.target.checked,
                                                            deleteKeys: [
                                                                ...(invoice?.deleteKeys ?? []),
                                                                'forceAutomaticFolioCheck',
                                                            ],
                                                        },
                                                    }),
                                                )
                                            }}
                                        >
                                            Folio Automático
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </>
                        )}

                        <Col xs={12} md={12} xl={12} xxl={12}>
                            <SelectInput
                                name="use"
                                label="Uso del CFDI"
                                options={CFDIUses}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor selecciona el uso de la factura',
                                    },
                                ]}
                            />
                        </Col>
                        <Col xs={12} md={12} xl={12} xxl={12}>
                            <SelectInput
                                name="paymentForm"
                                label="Forma de pago"
                                options={paymentForms}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor selecciona la forma de pago',
                                    },
                                ]}
                            />
                        </Col>
                        <Col xs={12} md={12} xl={12} xxl={12}>
                            <SelectInput
                                name="currency"
                                label="Moneda"
                                options={currecies}
                                onChange={(currency: any) => {
                                    form.setFieldsValue({
                                        exchange: (1 / exchangeRates[currency]).toFixed(4),
                                    })
                                }}
                            />
                        </Col>
                        <Col xs={12} md={12} xl={12} xxl={12}>
                            <TextInput
                                name="exchange"
                                label="Tipo de cambio"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor escribe el tipo de cambio',
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <MoreOptionsButton />
                </Col>
            </Row>
            <InvoiceProductsServices />
            <InvoiceExtras />
        </div>
    )
}
