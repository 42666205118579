import './App.css'
import './css/antd-overrides.css'
import './css/Colors.css'
import './css/Gigstack.css'
import './css/Typography.css'

import { ConfigProvider } from 'antd'
import locale from 'antd/locale/es_ES'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { getAnalytics } from 'firebase/analytics'
import { AppCheck, ReCaptchaV3Provider, initializeAppCheck } from 'firebase/app-check'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore, initializeFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import moment from 'moment'
import 'moment/locale/es-mx'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { useEffect } from 'react'
import {
    AnalyticsProvider,
    AppCheckProvider,
    AuthProvider,
    FirestoreProvider,
    StorageProvider,
    useFirebaseApp,
    useInitPerformance,
} from 'reactfire'
import MainRoutes from './app/routes/MainRoutes'
import { antdTheme } from './css/antd-theme'
import { initializeGTM } from './app/analytics/helpers'
const APP_CHECK_TOKEN = '6LcN4cscAAAAAIRntjbXxgZyqzXEO5x2xBKDBezv'

const environment: 'production' | 'staging' | 'local' = (process.env.REACT_APP_ENVIRONMENT ?? 'production') as
    | 'production'
    | 'staging'
    | 'local'

function App() {
    dayjs.locale('es')
    moment.locale('es-MX')

    const app = useFirebaseApp()
    initializeFirestore(app, { ignoreUndefinedProperties: true })

    const firestoreInstance = getFirestore(app)
    const auth = getAuth(app)
    const storage = getStorage(app)
    const analyticssdk = getAnalytics(app)
    const functions = getFunctions(app)
    // DEPLOYMENT
    if (environment === 'local') {
        console.log(process.env.REACT_APP_ENVIRONMENT)
        connectFirestoreEmulator(firestoreInstance, 'localhost', 8080)
        connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true })
        connectStorageEmulator(storage, 'localhost', 9199)
        connectFunctionsEmulator(functions, 'localhost', 5001)

        // /* @ts-ignore */
        // if (!firestoreInstance?._settingsFrozen) connectFirestoreEmulator(firestoreInstance, 'localhost', 8080)
    }

    initializeGTM('GTM-KR8W5CH')

    useInitPerformance(async (app) => {
        const { getPerformance } = await import('firebase/performance')
        return getPerformance(app)
    })

    const appCheck =
        environment === 'production'
            ? initializeAppCheck(app, {
                  provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
                  isTokenAutoRefreshEnabled: false,
              })
            : (null as unknown as AppCheck)

    useEffect(() => {
        if (environment === 'production') {
            posthog.init('phc_rd0aAtXdBAomlJIQREuxMm6XZjVFrVNkfgCk8lPYe7S', {
                api_host: 'https://app.posthog.com',
                opt_in_site_apps: true,
            })
        }
    }, [])

    // No es la manera mas elegante de hacerlo, pero funciona para evitar appcheck en local y staging
    return (
        <AuthProvider sdk={auth}>
            <FirestoreProvider sdk={firestoreInstance}>
                {environment === 'production' ? (
                    <AppCheckProvider sdk={appCheck}>
                        <StorageProvider sdk={storage}>
                            <AnalyticsProvider sdk={analyticssdk}>
                                <ConfigProvider theme={antdTheme} locale={locale}>
                                    <PostHogProvider client={posthog}>
                                        <MainRoutes />
                                    </PostHogProvider>
                                </ConfigProvider>
                            </AnalyticsProvider>
                        </StorageProvider>
                    </AppCheckProvider>
                ) : (
                    <StorageProvider sdk={storage}>
                        <AnalyticsProvider sdk={analyticssdk}>
                            <ConfigProvider theme={antdTheme} locale={locale}>
                                <PostHogProvider client={posthog}>
                                    <MainRoutes />
                                </PostHogProvider>
                            </ConfigProvider>
                        </AnalyticsProvider>
                    </StorageProvider>
                )}
            </FirestoreProvider>
        </AuthProvider>
    )
}

export default App
