import {
    Info,
    Files,
    Calculator,
    Users,
    UserList,
    Plugs,
    Receipt,
    UserGear,
    Headset,
    Code,
    Globe,
    UsersThree,
    ChartLine,
    ChatCenteredDots,
    Storefront,
    Question,
} from '@phosphor-icons/react'
import { Button, Card, message, Space, Typography, Popover } from 'antd'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { redirect, useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth } from 'reactfire'
import { PricingPlan } from '../../../interfaces/PricingPlanDef'
import { setBillingAccount } from '../../context/billingAccountSlice'
import { setTeam } from '../../context/teamsSlice'
import { generateCode, preserveQueryParams, returnCurrencyValue } from '../../functions/helpers'
import FreePlansModal from '../../modals/FreePlansModal'
import PlanConfirmationModal from '../../modals/UpgradePlanConfirmationModal'
import { getFrontUrl, isStagingOrLocal } from '../Integrations/stripeHelpers'
import { usePostHog } from 'posthog-js/react'
import { trackEvent } from '../../analytics/helpers'

const featureTranslations: { [key: string]: string } = {
    includedDocuments: 'eventos',
    aditionalDocumentPrice: 'Evento adicional',
    seats: 'asientos',
    maxClients: 'clientes',
    selfBillingPortal: 'Portal de facturación propia',
    maxIntegrations: 'Integraciones',
    customerPortal: 'Portal de cliente',
    technicalSupport: 'Soporte técnico',
    apiAndWebhooks: 'API keys y Webhooks',
    customDomain: 'Dominio personalizado',
    multipleIssuerAccounts: 'Multi-cuentas de facturación',
    analyticsAndReports: 'Estadísticas e informes',
    dedicatedSupportChannel: 'Canal de soporte dedicado',
    supplierMode: 'Modalidad de proveedores',
    unlimited: 'Ilimitado',
}

interface PricingCardProps {
    plan: PricingPlan
    billingCycle: 'monthly' | 'annual'
    isRecommended?: boolean
    disabled?: boolean
    onSelect?: () => void
    isDowngrade?: boolean
}

const documentsOrder = ['includedDocuments', 'aditionalDocumentPrice', 'seats', 'maxClients', 'maxIntegrations']

const featureOrder = [
    'selfBillingPortal', // 0
    'customerPortal', // 1
    'technicalSupport', // 2
    'apiAndWebhooks', // 3
    'customDomain', // 4
    'multipleIssuerAccounts', // 5
    'analyticsAndReports', // 6
    'dedicatedSupportChannel', // 7
    'supplierMode', // 8
]

const featureIcons = {
    includedDocuments: <Files size={20} weight="duotone" />,
    aditionalDocumentPrice: <Calculator size={20} weight="duotone" />,
    seats: <Users size={20} weight="duotone" />,
    maxClients: <UserList size={20} weight="duotone" />,
    maxIntegrations: <Plugs size={20} weight="duotone" />,
    selfBillingPortal: <Receipt size={20} weight="duotone" />,
    customerPortal: <UserGear size={20} weight="duotone" />,
    technicalSupport: <Headset size={20} weight="duotone" />,
    apiAndWebhooks: <Code size={20} weight="duotone" />,
    customDomain: <Globe size={20} weight="duotone" />,
    multipleIssuerAccounts: <UsersThree size={20} weight="duotone" />,
    analyticsAndReports: <ChartLine size={20} weight="duotone" />,
    dedicatedSupportChannel: <ChatCenteredDots size={20} weight="duotone" />,
    supplierMode: <Storefront size={20} weight="duotone" />,
}

const PricingCard = ({
    plan,
    billingCycle,
    isRecommended = false,
    onSelect,
    disabled,
    isDowngrade,
}: PricingCardProps) => {
    const auth = useAuth()
    const navigate = useNavigate()
    const posthog = usePostHog()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()

    const { team } = useSelector((state: any) => state.team)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    const [isProcessing, setIsProcessing] = useState(false)
    const [price] = useState(plan.price[billingCycle ?? 'monthly'])
    const [displayFreeModal, setDisplayFreeModal] = useState<boolean>(false)
    const [selectedToLoading, setSelectedToLoading] = useState<string | null>(null)
    const [isConfirmModalOpen, setIsConfirModalOpen] = useState(false)
    const [eventPopoverOpen, setEventPopoverOpen] = useState(false)

    const shouldTrialBeActive = () => {
        if (plan.id === 'free' || plan.id === 'free-plus') return false
        if (billingAccount?.plan?.id) return false
        return searchParams.get('trial') === 'false' ? false : true
    }

    const handleEventPopoverChange = (newOpen: boolean) => {
        setEventPopoverOpen(newOpen)
    }

    const formatDocumentValue = (
        featName: string,
        value: number | string | boolean | undefined,
    ): JSX.Element | string => {
        if (value === undefined) return 'No disponible'
        if (typeof value === 'boolean') return featureTranslations[featName]
        if (value === 'unlimited' && featureTranslations[featName] === 'integraciones')
            return `${featureTranslations[featName]} ilimitadas`
        if (value === 'unlimited') return `${featureTranslations[featName]} ilimitados`
        if (value === 1) {
            if (featName === 'seats') return `${value} asiento`
            if (featName === 'maxIntegrations') return `${value} integración`
        }
        if (featName === 'includedDocuments') {
            const includedDocs = plan?.price[`${billingCycle}`]?.includedDocuments || value
            return (
                <Space size={4}>
                    {`${includedDocs} eventos`}
                    <Popover
                        content={
                            <div style={{ maxWidth: '400px' }}>
                                <Typography.Paragraph
                                    style={{
                                        margin: 0,
                                        marginBottom: '16px',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: 'var(--neutral-9)',
                                    }}
                                >
                                    Un evento es una transacción o documento fiscal que se procesa en el sistema.
                                </Typography.Paragraph>

                                <div
                                    style={{
                                        background: 'var(--neutral-1)',
                                        borderRadius: '8px',
                                        padding: '12px',
                                        marginBottom: '16px',
                                    }}
                                >
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: '13px',
                                            display: 'block',
                                            marginBottom: '8px',
                                            color: 'var(--neutral-9)',
                                        }}
                                    >
                                        Ejemplo:
                                    </Typography.Text>
                                    <Typography.Text
                                        style={{
                                            fontSize: '13px',
                                            lineHeight: '18px',
                                            color: 'var(--neutral-8)',
                                            display: 'block',
                                        }}
                                    >
                                        En 200 ventas donde se emitan 100 facturas y 100 ventas sin factura:
                                        <ul
                                            style={{
                                                margin: '8px 0 0 0',
                                                paddingLeft: '18px',
                                                listStyle: 'disc',
                                            }}
                                        >
                                            <li>100 facturas individuales = 100 eventos</li>
                                            <li>100 ventas sin factura = 1 evento global</li>
                                            <li>Total = 101 eventos</li>
                                        </ul>
                                    </Typography.Text>
                                </div>

                                <Button
                                    type="text"
                                    size="small"
                                    onClick={() => setEventPopoverOpen(false)}
                                    style={{
                                        padding: '4px 8px',
                                        height: 'auto',
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        color: 'var(--neutral-6)',
                                    }}
                                >
                                    Cerrar
                                </Button>
                            </div>
                        }
                        title={
                            <Typography.Text
                                strong
                                style={{
                                    fontSize: '14px',
                                    color: 'var(--neutral-10)',
                                }}
                            >
                                ¿Qué es un evento?
                            </Typography.Text>
                        }
                        trigger={['hover', 'click']}
                        open={eventPopoverOpen}
                        onOpenChange={handleEventPopoverChange}
                        placement="top"
                        overlayStyle={{
                            maxWidth: '320px',
                        }}
                        overlayInnerStyle={{
                            padding: '16px',
                        }}
                        mouseEnterDelay={0.3}
                        mouseLeaveDelay={0.3}
                    >
                        <Question
                            size={15}
                            weight="fill"
                            style={{
                                cursor: 'help',
                                color: 'black',
                                verticalAlign: 'middle',
                                marginTop: '-2px',
                                transition: 'color 0.2s ease',
                            }}
                        />
                    </Popover>
                </Space>
            )
        }
        if (featName === 'aditionalDocumentPrice') {
            if (typeof value === 'number') {
                return value === 0
                    ? 'Sin eventos adicionales'
                    : `Evento adicional: ${returnCurrencyValue(value / 100)} MXN`
            }
        }
        return `${value} ${featureTranslations[featName]}`
    }

    const formatFeatureValue = (featName: string): string => {
        return featureTranslations[featName]
    }

    const checkoutSubscription = async (id?: string) => {
        if (isProcessing) return

        try {
            setIsProcessing(true)
            setSelectedToLoading(id ?? plan.id)

            const formattedId = id?.replace('-', '_') ?? plan?.id?.replace('-', '_')
            trackEvent(
                {
                    name: `${formattedId}_plan_selected`,
                    metadata: {
                        v: 'v2',
                        billingAccount: billingAccount?.id,
                        userId: auth.currentUser?.uid,
                    },
                },
                posthog,
            )
            const currentUrl = new URL(window.location.href)
            const searchParams = new URLSearchParams(currentUrl.search)

            const isUpgrade =
                billingAccount?.plan?.id && billingAccount.plan.id !== plan.id && billingAccount.plan.id !== 'free'

            const successUrlPath = isUpgrade ? '/onboarding/finishSetup' : 'onboarding/plan?subscription=success'
            const cancelUrlPath = isUpgrade ? '/onboarding/finishSetup' : 'onboarding/plan?subscription=cancel'

            const cancelUrl = preserveQueryParams(`${getFrontUrl()}/${cancelUrlPath}`, searchParams)
            const successUrl = preserveQueryParams(`${getFrontUrl()}/${successUrlPath}`, searchParams)

            const billingManager = httpsCallable(getFunctions(), 'internalbillingapp')

            const billingData = {
                successUrl,
                cancelUrl,
                action: isUpgrade ? 'upgrade' : 'subscribe',
                plan: id ?? plan?.id,
                billingCycle: price.billingPeriod,
                trial: shouldTrialBeActive(),
                quantity: 1,
                isStaging: isStagingOrLocal(),
            }

            const checkout = await billingManager(billingData)

            if (!checkout.data) {
                throw new Error('No se recibió respuesta del servidor')
            }

            const data = checkout.data as any

            if (data?.onUpgradeCompleted) {
                navigate('/onboarding/finished')
                return
            }

            if (data.error) throw new Error(data.message)

            if (data?.onFree) {
                try {
                    const lastTeam = await getDoc(doc(getFirestore(), 'teams', team.id))
                    const lastBilling = await getDoc(doc(getFirestore(), 'billingAccounts', billingAccount.id))

                    if (lastTeam.exists()) {
                        dispatch(setTeam(lastTeam.data()))
                    }

                    if (lastBilling.exists()) {
                        dispatch(setBillingAccount(lastBilling.data()))
                    }

                    redirect(preserveQueryParams('/onboarding/team', searchParams))
                } catch (error) {
                    console.error('Error actualizando datos:', error)
                    message.error('Error actualizando la información del equipo')
                }
            } else {
                if (data.session?.url) {
                    // Agregar pequeño delay para asegurar que los estados se actualicen
                    setTimeout(() => {
                        window.open(data.session.url, '_self')
                    }, 100)
                } else if (data.raw?.message) {
                    message.error(data.raw.message)
                }
            }
        } catch (error) {
            console.error('Error detallado:', {
                error,
                state: {
                    selectedToLoading,
                    isConfirmModalOpen,
                    plan: plan.id,
                },
            })
            message.error('Hubo un error al procesar tu suscripción')
        } finally {
            setTimeout(() => {
                setSelectedToLoading(null)
                setIsProcessing(false)
            }, 1000) // Dar tiempo para que la redirección ocurra
        }
    }

    const sortedDocuments = documentsOrder.map((featName) => {
        if (featName === 'aditionalDocumentPrice') {
            return {
                name: featName,
                value: price?.additionalDocumentPrice!,
            }
        } else if (featName === 'includedDocuments') {
            return {
                name: featName,
                value: plan.price[`${billingCycle}`]?.includedDocuments || plan.features[featName],
            }
        } else {
            return {
                name: featName,
                value: plan.features[featName as keyof typeof plan.features],
            }
        }
    })

    const sortFeatures = (planId: string) => {
        let title: string
        let showFrom: number
        let showUntil: number
        switch (planId) {
            case 'advanced':
                title = 'Plan Esencial, más:'
                showFrom = 1
                showUntil = 2
                break
            case 'professional':
                title = 'Plan Avanzado, más:'
                showFrom = 3
                showUntil = 5
                break
            case 'professional-plus':
                title = 'Plan Profesional, más:'
                showFrom = 6
                showUntil = 7
                break

            default:
                title = 'Funcionalidades'
                showFrom = 0
                showUntil = 0
                break
        }

        return featureOrder
            .map((featName) => {
                return {
                    mapping: {
                        title,
                        showFrom,
                        showUntil,
                    },
                    name: featName,
                    value: plan.features[featName as keyof typeof plan.features],
                }
            })
            .filter((feature, index) => index >= feature.mapping.showFrom && index <= feature.mapping.showUntil)
    }

    const isCurrentPlan = billingAccount?.plan?.id === plan.id

    const getButtonText = () => {
        if (isCurrentPlan) return 'Plan actual'
        if (isDowngrade) return 'Comunicate con soporte'
        if (plan.id === 'free') return 'Comenzar gratis'
        if (plan.id === 'free-plus') return 'Continuar con tarjeta'
        if (billingAccount?.plan?.id) return 'Actualizar plan'
        if (!shouldTrialBeActive()) return 'Suscribirse'
        return 'Probar 12 días gratis'
    }

    const getButtonClass = () => {
        if (isCurrentPlan) return 'btn-outline'
        if (isRecommended) return 'btn-pricing-plans'
        if (disabled) return 'btn-secondary'
        if (plan.id === 'free') return 'btn-secondary'
        if (plan.id === 'free-plus') return 'btn-primary'
        return 'btn-secondary'
    }

    const handleButtonClick = async () => {
        if (!auth.currentUser?.uid) {
            const customPriceId = searchParams.get('customPriceId')
            const code = searchParams.get('code')
            let red = '/register?redirect=/memberships'

            if (customPriceId) {
                red = `${red}&customPriceId=${customPriceId}`
            }
            if (code) {
                red = `${red}&code=${code}`
            }
            return navigate(preserveQueryParams(red, searchParams))
        }

        if (selectedToLoading || isProcessing) {
            console.log('Ya hay una operación en proceso')
            return
        }

        try {
            if (plan.id === 'free' || plan.id === 'free-plus') {
                trackEvent(
                    {
                        name: 'start_with_free_plan',
                        metadata: {
                            v: 'v2',
                            billingAccount: billingAccount?.id,
                            userId: auth.currentUser?.uid,
                        },
                    },
                    posthog,
                )
                setDisplayFreeModal(true)
            } else if (billingAccount?.plan?.id) {
                setIsConfirModalOpen(true)
            } else {
                await checkoutSubscription()
            }
        } catch (error) {
            console.error('Error en el manejo del botón:', error)
            message.error('Ocurrió un error, por favor intenta de nuevo')
        }
    }

    return (
        <Card
            className={`pricing-card  rounded-lg ${isRecommended ? 'recommended-card' : ''}`}
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '25px 20px',
                height: '100%',
                position: 'relative',
                overflow: 'hidden',
                marginTop: !isRecommended ? '8px' : '0',
                backgroundColor: 'white',
            }}
        >
            {isRecommended && (
                <div
                    className="recommended-accent"
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '150px',
                        height: '150px',
                        background: 'linear-gradient(135deg, var(--primary-1) 0%, transparent 100%)',
                        opacity: 0.1,
                        borderRadius: '0 8px 0 100%',
                    }}
                />
            )}

            <FreePlansModal
                selectedToLoading={selectedToLoading}
                isOpen={displayFreeModal}
                onClose={() => setDisplayFreeModal(false)}
                ids={{
                    free: 'free',
                    freePlus: 'free-plus',
                }}
                selectedFreePlan={(id: string) => {
                    checkoutSubscription(id)
                }}
            />

            {/* Card Header */}
            <div style={{ textAlign: 'center', marginBottom: 24 }}>
                {/* <div
                    style={{
                        color: 'var(--primary-1)',
                        marginBottom: 16,
                        height: '48px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {planIcons[plan.id as keyof typeof planIcons]}
                </div> */}
                <Typography.Title
                    level={3}
                    style={{
                        margin: 0,
                        height: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {plan.name}
                </Typography.Title>
                <Typography.Text
                    type="secondary"
                    style={{
                        marginTop: 8,
                        height: '48px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {plan?.description}
                </Typography.Text>
            </div>

            {/* Pricing Section - Fixed Height */}
            <div
                style={{
                    height: '100px',
                    marginBottom: 24,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'baseline', whiteSpace: 'nowrap', marginBottom: 8 }}>
                    <Typography.Title
                        level={2}
                        style={{ margin: 0, fontSize: 40, lineHeight: 1, fontWeight: 600, display: 'inline' }}
                    >
                        {billingCycle === 'monthly'
                            ? returnCurrencyValue(Math.round(price.baseAmount!) / 100).split('.')[0]
                            : returnCurrencyValue(Math.round(price.baseAmount!) / 100 / 12).split('.')[0]}
                    </Typography.Title>
                    <Typography.Text style={{ fontSize: 15, marginRight: 4, fontWeight: 500 }}>MXN</Typography.Text>
                </div>
                <Typography.Text type="secondary" style={{ fontSize: 12, lineHeight: 1 }}>
                    +IVA{' '}
                    {billingCycle === 'annual' && plan.id !== 'free'
                        ? 'facturado anualmente'
                        : 'facturado mensualmente'}
                </Typography.Text>
            </div>

            {/* CTA Button */}
            <Button
                loading={selectedToLoading === plan.id}
                className={getButtonClass()}
                block
                size="large"
                onClick={handleButtonClick}
                disabled={isCurrentPlan || disabled}
                style={{ marginBottom: 32, height: '48px' }}
            >
                {getButtonText()}
            </Button>

            {/* Features Section */}
            <div style={{ flex: 1 }}>
                <Typography.Title level={5} style={{ marginBottom: 16 }}>
                    Funcionalidades
                </Typography.Title>
                <Space direction="vertical" size={12} style={{ width: '100%', marginBottom: 24 }}>
                    {sortedDocuments.map(({ name, value }) => (
                        <Space key={generateCode(8)} align="start">
                            {featureIcons[name as keyof typeof featureIcons] || (
                                <Info size={20} weight="duotone" style={{ color: 'var(--neutral-3)' }} />
                            )}
                            <Typography.Text className="neutral-3">{formatDocumentValue(name, value)}</Typography.Text>
                        </Space>
                    ))}
                </Space>

                {sortFeatures(plan.id).map((feature, index) => {
                    if (!feature || !feature.value) return null
                    return (
                        <div key={generateCode(8)} style={{ marginBottom: 16 }}>
                            {index === 0 && (
                                <Typography.Title level={5} style={{ marginBottom: 16 }}>
                                    {feature.mapping.title}
                                </Typography.Title>
                            )}
                            <Space align="start">
                                {featureIcons[feature.name as keyof typeof featureIcons] || (
                                    <Info size={20} weight="duotone" style={{ color: 'var(--neutral-3)' }} />
                                )}
                                <Typography.Text className="neutral-3">
                                    {formatFeatureValue(feature.name)}
                                </Typography.Text>
                            </Space>
                        </div>
                    )
                })}
            </div>

            <PlanConfirmationModal
                isOpen={isConfirmModalOpen}
                onClose={() => setIsConfirModalOpen(false)}
                onConfirm={() => checkoutSubscription(plan.id)}
                selectedPlan={plan}
                currentPlan={billingAccount?.plan}
                isLoading={Boolean(selectedToLoading)}
                billingCycle={billingCycle}
            />
        </Card>
    )
}

export default PricingCard
