import { Button, Col, message, Row, Tag, Typography } from 'antd'
import { collection, doc, getDocs, getFirestore, onSnapshot, query } from 'firebase/firestore'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth } from 'reactfire'
import { PricingPlan } from '../../../interfaces/PricingPlanDef'
import { setBillingAccount } from '../../context/billingAccountSlice'
import { preserveQueryParams, SearchParamInURL } from '../../functions/helpers'
import ConfirmOnboardingStep from '../../new-onboarding/onboarding/steps/ConfirmOnboardingStep'
import { handleSignOut } from '../UI/Header'
import PricingCard from './PricingCard'
import PricingDeckSwitch from './PricingDeckSwitch'
import { usePostHog } from 'posthog-js/react'
import { trackEvent } from '../../analytics/helpers'
import { CheckCircle } from '@phosphor-icons/react'
import Paragraph from 'antd/lib/typography/Paragraph'

const PLAN_ORDER = ['free', 'free-plus', 'essential', 'advanced', 'professional', 'professional-plus']
const FREE_PLANS = ['free', 'free-plus']

export default function PricingCardDeck() {
    const db = getFirestore()
    const auth = useAuth()
    const dispatch = useDispatch()
    const posthog = usePostHog()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    const [plans, setPlans] = useState<PricingPlan[]>([])
    const [displayedPlans, setDisplayedPlans] = useState<PricingPlan[]>([])
    const [type, setType] = useState<'monthly' | 'annual'>('monthly')

    const [isSubscriptionActive, setIsSubscriptionActive] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const url = new URL(window.location.href)
    const params = useMemo(() => new URLSearchParams(url.search), [url.search])

    const showFree = searchParams.get('show_free') === 'true' ? true : false

    const sortedPlans = useMemo(
        () => [...plans].sort((a, b) => PLAN_ORDER.indexOf(a.id) - PLAN_ORDER.indexOf(b.id)),
        [plans],
    )

    const freePlan = useMemo(() => sortedPlans.find((plan) => plan.id === 'free'), [sortedPlans])

    const isDowngrade = (selectedPlanId: string) => {
        if (!billingAccount?.plan?.id) return false
        const currentPlanIndex = PLAN_ORDER.indexOf(billingAccount.plan.id)
        const selectedPlanIndex = PLAN_ORDER.indexOf(selectedPlanId)
        return selectedPlanIndex < currentPlanIndex
    }

    useEffect(() => {
        const getPlans = async () => {
            const q = query(collection(db, 'subscriptionPricing'))
            const subscriptionPlans = await getDocs(q)
            const fetchedPlans = subscriptionPlans.docs.map((doc) => doc.data()) as PricingPlan[]
            setPlans(fetchedPlans)
        }

        trackEvent(
            {
                name: 'onboarding_plan_section_viewed',
                metadata: {
                    v: 'v2',
                    billingAccount: billingAccount?.id,
                    userId: auth.currentUser?.uid,
                },
            },
            posthog,
        )
        getPlans()
    }, [db, posthog, billingAccount, auth?.currentUser?.uid])

    useEffect(() => {
        if (!freePlan) return

        if (showFree) {
            const filteredPlans = sortedPlans.filter(
                (plan) => !['custom', 'free-plus', 'professional-plus'].includes(plan.id),
            )
            setDisplayedPlans(filteredPlans)
        } else {
            const finalPlans = sortedPlans.filter((plan) => !FREE_PLANS.includes(plan.id) && plan.id !== 'custom')
            setDisplayedPlans(finalPlans)
        }
    }, [sortedPlans, freePlan, showFree])

    useEffect(() => {
        const subscriptionStatus = SearchParamInURL('subscription')
        if (!billingAccount.id && subscriptionStatus === 'success') {
            setIsLoading(true)
            return
        }

        if (subscriptionStatus !== 'success' && subscriptionStatus !== 'cancel') return

        setIsLoading(true)
        const billingRef = doc(db, 'billingAccounts', billingAccount?.id)

        // TOTAL WAIT TIME: 20 seconds (should always show minimum 5 seconds)
        const checkSubscription = async () => {
            await new Promise((resolve) => setTimeout(resolve, 5000))

            return new Promise((resolve, reject) => {
                const unsubscribe = onSnapshot(billingRef, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        const billingData = docSnapshot.data()
                        const planId = billingData?.plan?.id
                        const subscriptionId = billingData?.plan?.subscription

                        if (planId && subscriptionId) {
                            unsubscribe()
                            resolve(billingData)
                        }
                    }
                })

                setTimeout(() => {
                    unsubscribe()
                    reject()
                }, 15000)
            })
        }

        const pollSubscription = async () => {
            try {
                const billingAccount = await checkSubscription()

                setIsSubscriptionActive(true)
                await new Promise((resolve) => setTimeout(resolve, 2500))

                dispatch(setBillingAccount(billingAccount))
                navigate(preserveQueryParams('/onboarding/finished', params, ['subscription']))
                setIsLoading(false)
            } catch (error) {
                const url = new URL(window.location.href)
                url.searchParams.delete('subscription')
                window.history.replaceState({}, '', url.toString())
                message.error('No se pudo configurar tu plan, por favor intenta de nuevo')
                setIsLoading(false)
            }
        }

        pollSubscription()
    }, [billingAccount, billingAccount?.id, db, dispatch, navigate, params])

    if (isLoading) {
        return <ConfirmOnboardingStep type="waiting-subscription" success={isSubscriptionActive} />
    }

    return (
        <div
            className="pricing-card-deck"
            style={
                auth?.currentUser
                    ? {
                          //   backgroundImage: `url(${appbackground})`,
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                      }
                    : {}
            }
        >
            {auth.currentUser && (
                <Button
                    className="btn-link"
                    onClick={async () => handleSignOut({ dispatch, auth })}
                    style={{
                        position: 'absolute',
                        top: '16px',
                        right: '16px',
                        color: '#666',
                    }}
                >
                    Cerrar sesión
                </Button>
            )}
            <Row style={{ justifyContent: 'center' }}>
                <Col style={{ justifyContent: 'center' }}>
                    <Tag className="bg-primary-2 primary-1 p-small-bold rounded-lg" style={{ borderWidth: '0' }}>
                        Comienza con gigstack
                    </Tag>
                </Col>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
                <Col style={{ justifyContent: 'center', alignItems: 'center' }} className="d-flex flex-column">
                    <Typography className="h1-bold">Encuentra el plan perfecto para tu negocio</Typography>
                    <Paragraph
                        style={{
                            fontSize: '18px',
                            color: '#666',
                        }}
                    >
                        Enfócate en lo que realmente importa: tu negocio.
                    </Paragraph>
                </Col>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
                <Col>
                    <PricingDeckSwitch parentCallback={(t: any) => setType(t)} />
                </Col>
            </Row>
            <>
                <Row
                    gutter={[16, 16]}
                    className="pricing-cards"
                    style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        overflowX: 'auto',
                        padding: '20px 0',
                        margin: '0 -20px',
                        width: 'calc(100% + 40px)',
                        alignItems: 'stretch',
                    }}
                >
                    {displayedPlans.map((plan, index) => (
                        <Col
                            key={plan.id + type}
                            style={{
                                flex: '0 0 300px',
                                padding: '0 8px',
                                display: 'flex',
                            }}
                        >
                            <div style={{ width: '100%' }}>
                                {(!showFree ? index === 1 : index === 2) && (
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            marginBottom: '10px',
                                            height: '22px',
                                        }}
                                    >
                                        <Tag color="green" icon={<CheckCircle size={16} />}>
                                            Elegido por el +63% de los usuarios
                                        </Tag>
                                    </div>
                                )}
                                {(!showFree ? index !== 1 : index !== 2) && <div style={{ height: '22px' }} />}
                                <PricingCard
                                    plan={plan}
                                    billingCycle={type}
                                    isRecommended={!showFree ? index === 1 : index === 2}
                                    disabled={isDowngrade(plan.id)}
                                    isDowngrade={isDowngrade(plan.id)}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </>
        </div>
    )
}
