import { Button, Form, Modal, Row, Tag, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { setData } from '../context/dataSlice'
import { TextAreaInput, TextInput } from '../components/Forms/Inputs'
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { generateCode } from '../functions/helpers'

const CreateNewTemplate = () => {
    const { createNewTemplateEmailModalVisible } = useSelector((state: any) => state.modals)
    const { templateEmails } = useSelector((state: any) => state.data)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const { user } = useSelector((state: any) => state.user)
    const { team } = useSelector((state: any) => state.team)
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)

    const AddReplacer = ({ replacer }: { replacer?: any }) => {
        const content = form.getFieldValue('content')
        form.setFieldsValue({ content: (content ?? '') + ' ' + replacer })
        //     dispatch(setData({
        //         item: 'template',
        //         data: {
        //             ...templateEmails,
        //             content: (content ?? '') + ' ' + replacer
        //         }
        //     }))
    }
    useEffect(
        () => {
            if (templateEmails?.id) {
                form.setFieldsValue({
                    name: templateEmails?.name,
                    content: templateEmails?.content,
                })
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [templateEmails?.id],
    )

    const CreateTemplate = async (v: any) => {
        try {
            setLoading(true)
            if (templateEmails?.id) {
                const upDoc = doc(getFirestore(), 'templates', templateEmails?.id)
                await updateDoc(upDoc, {
                    editedTimestamp: moment().valueOf(),
                    name: v.name,
                    content: v.content,
                })
            } else {
                const d = doc(getFirestore(), 'templates', generateCode(10, 'temp'))
                await setDoc(d, {
                    team: team?.id,
                    billingAccount: billingAccount?.id,
                    owner: user?.uid,
                    timestamp: moment().valueOf(),
                    name: v.name,

                    content: v.content,
                })
            }
            setLoading(false)
            dispatch(closeModal('createNewTemplateEmailModalVisible'))
            dispatch(
                setData({
                    item: 'template',
                    data: null,
                }),
            )
        } catch (error: string | any) {
            message.error('Error al crear la plantilla')
            setLoading(false)
        }
    }
    return (
        <Modal
            zIndex={1001}
            open={createNewTemplateEmailModalVisible}
            onCancel={() => {
                dispatch(closeModal('createNewTemplateEmailModalVisible'))
                dispatch(
                    setData({
                        item: 'template',
                        data: null,
                    }),
                )
            }}
            title={
                <Typography.Title level={4} style={{ margin: 0 }}>
                    {templateEmails?.id ? 'Editar plantilla' : 'Crear plantilla'}
                </Typography.Title>
            }
            footer={null}
            width={600}
            style={{ top: 10 }}
        >
            <div style={{ padding: '15px 20px' }} className="d-flex flex-column">
                <Typography.Title level={5}>Nueva plantilla</Typography.Title>
                <Form
                    onFinish={CreateTemplate}
                    form={form}
                    initialValues={templateEmails?.id ? templateEmails?.id : {}}
                    layout="vertical"
                    style={{ marginTop: '25px' }}
                >
                    <TextInput
                        label="Título"
                        name="name"
                        rules={[{ required: true, message: 'Identifica tu plantilla' }]}
                    />
                    <Row justify="space-between">
                        <Typography.Text className="cLabel labelRequired">Mensaje</Typography.Text>
                        <Row wrap={true} style={{ marginBottom: '20px' }}>
                            <Tag className="clickable" onClick={() => AddReplacer({ replacer: '{cliente}' })}>
                                Nombre del cliente
                            </Tag>
                            <Tag className="clickable" onClick={() => AddReplacer({ replacer: '{total}' })}>
                                Total
                            </Tag>
                            <Tag className="clickable" onClick={() => AddReplacer({ replacer: '{moneda}' })}>
                                Moneda
                            </Tag>
                            <Tag className="clickable" onClick={() => AddReplacer({ replacer: '{fecha}' })}>
                                Fecha
                            </Tag>
                            <Tag className="clickable" onClick={() => AddReplacer({ replacer: '{id}' })}>
                                Folio
                            </Tag>
                            <Tag className="clickable" onClick={() => AddReplacer({ replacer: '{empresa}' })}>
                                Empresa
                            </Tag>
                        </Row>
                    </Row>
                    <TextAreaInput label="" name="content" rules={[{ required: true, message: 'Añade un mensaje' }]} />
                    <Row justify="center" style={{ marginTop: '25px' }}>
                        <Button loading={loading} disabled={loading ? true : false} htmlType="submit" type="primary">
                            {templateEmails?.id ? 'Actualizar' : 'Guardar'}
                        </Button>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}

export default CreateNewTemplate
