import { Button, Col, Divider, Form, Row, Typography, Card, Avatar, Carousel, Image, Rate } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import Hero from '../../../assets/images/signup-hero.png'
import { CheckBoxInput, PasswordInput, TextInput } from '../../components/Forms/Inputs'
import { useCustomAuth } from '../../customHooks/useCustomAuth'
import { preserveQueryParams } from '../../functions/helpers'
import AuthFormLayout from './AuthFormLayout'
import GoogleSignInButton from './GoogleSignInButton'
import { usePostHog } from 'posthog-js/react'
import { trackEvent } from '../../analytics/helpers'

interface Author {
    name: string
    role: string
    company: string
    avatar: string
}

interface Testimonial {
    quote: string
    author: Author
}

const testimonials: Testimonial[] = [
    {
        quote: 'Fue amor a primera vista. Estábamos buscando un software que nos ayudara a automatizar la emisión de facturas integradas con nuestro CRM y ERP, y Gigstack fue la solución perfecta.',
        author: {
            name: 'Juan',
            role: '',
            company: 'Mundo Joven',
            avatar: 'https://gigstack.pro/images/mundo-joven_logo.png',
        },
    },
    {
        quote: 'Afortunadamente, descubrimos a Gigstack y nuestra experiencia ha sido excepcional.',
        author: {
            name: 'Cristhian',
            role: '',
            company: 'Clivi',
            avatar: 'https://www.clivi.com.mx/hs-fs/hubfs/Clivi-header-blanco.png?width=323&name=Clivi-header-blanco.png',
        },
    },
    {
        quote: 'Con su portal de cliente ha mejorando significativamente la satisfacción de nuestros clientes.',
        author: {
            name: 'HJ',
            role: '',
            company: 'Whitepaper',
            avatar: 'https://gigstack.pro/images/whitepaper-p-500.png',
        },
    },
    {
        quote: 'He trabajado con varias PAC, sin embargo, Gigstack, ha sido hasta el día de hoy, la plataforma, más dinámica a diferencia de otras que pueden existir en el mercado',
        author: {
            name: 'Juan',
            role: '',
            company: 'Lapzo',
            avatar: 'https://gigstack.pro/images/65aacf48bde4467e127570ba_logo-lapzo2-p-500.png',
        },
    },
    {
        quote: 'gigstack nos ha ahorrado más del 90% del tiempo que utilizabamos en estas actividades.',
        author: {
            name: 'Ximena',
            role: '',
            company: 'leadsales',
            avatar: 'https://gigstack.pro/images/Leadsales-Logo_1.png',
        },
    },
]

const TestimonialCard: React.FC<Testimonial> = ({ quote, author }) => (
    <Card
        style={{
            background: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(10px)',
            border: 'none',
            borderRadius: '24px',
            padding: '40px',
            marginBottom: '20px',
            textAlign: 'left',
        }}
    >
        <Typography.Text
            style={{
                color: 'white',
                fontSize: '18px',
                display: 'block',
                marginBottom: '32px',
                lineHeight: '1.5',
            }}
        >
            "{quote}"
        </Typography.Text>
        <Row align="middle" gutter={16}>
            <Col>
                <Image width={52} src={author.avatar} preview={false} />
            </Col>
            <Col flex="1" className="d-flex flex-column">
                <Typography.Text
                    style={{
                        color: 'white',
                        fontSize: '12px',
                        opacity: 0.8,
                        display: 'block',
                        marginBottom: '1px',
                    }}
                >
                    {author.role}
                </Typography.Text>
                <Typography.Text
                    strong
                    style={{
                        color: 'white',
                        fontSize: '14px',
                        display: 'block',
                        marginBottom: '1px',
                    }}
                >
                    {author.name}
                </Typography.Text>
                <Typography.Text
                    style={{
                        color: 'white',
                        fontSize: '12px',
                        opacity: 0.8,
                        display: 'block',
                    }}
                >
                    {author.company}
                </Typography.Text>
            </Col>
        </Row>
    </Card>
)

const SignUp = () => {
    const [form] = Form.useForm()
    const posthog = usePostHog()
    const { loading, signup, googleSignIn } = useCustomAuth()
    const location = useLocation()

    const getLoginLink = () => {
        const currentSearchParams = new URLSearchParams(location.search)
        const signup = preserveQueryParams('/login', currentSearchParams)
        return signup
    }

    const onFinish = (values: any) => {
        trackEvent({ name: 'signed_up_with_email', metadata: { email: values.email, v: 'v2' } }, posthog)
        signup(values)
    }

    return (
        <Row style={{ minHeight: '100vh' }}>
            <Col xs={24} md={12}>
                <AuthFormLayout
                    title="Crea una cuenta"
                    subtitle="Regístrate fácil y rápido"
                    footerText="¿Ya tienes cuenta?"
                    footerLinkText="Inicia Sesión"
                    footerLinkTo={getLoginLink()}
                >
                    <Form
                        form={form}
                        name="register"
                        onFinish={onFinish}
                        layout="vertical"
                        style={{ marginTop: '20px' }}
                    >
                        <TextInput
                            name="fullName"
                            label="Nombre completo"
                            placeholder="Escribe tu nombre completo"
                            rules={[{ required: true, message: 'Por favor ingresa tu nombre completo' }]}
                        />

                        <TextInput
                            name="email"
                            label="Correo electrónico"
                            placeholder="Escribe tu correo electrónico empresarial"
                            rules={[
                                { required: true, message: 'Por favor ingresa tu correo electrónico' },
                                { type: 'email', message: 'Por favor ingresa un correo electrónico válido' },
                            ]}
                        />

                        <PasswordInput
                            label="Contraseña"
                            name="password"
                            placeholder="Escribe tu contraseña"
                            rules={[
                                { required: true, message: 'Por favor ingresa tu contraseña' },
                                { min: 8, message: 'La contraseña debe tener al menos 8 caracteres' },
                            ]}
                        />

                        <CheckBoxInput
                            rules={[
                                {
                                    validator: (_: any, value: boolean) =>
                                        value
                                            ? Promise.resolve()
                                            : Promise.reject(new Error('Por favor acepta los términos y condiciones')),
                                },
                            ]}
                            name="terms-privacy"
                            content={
                                <Typography.Paragraph className="p-small-regular neutral-1" style={{ margin: 0 }}>
                                    Acepto y reconozco que he leído los &nbsp;
                                    <Link
                                        to="https://pro-gigstack.s3.us-east-2.amazonaws.com/legal/terms.pdf"
                                        target="_blank"
                                    >
                                        <span className="p-small-bold primary-1">términos y condiciones</span>
                                    </Link>
                                    &nbsp; y el &nbsp;
                                    <Link
                                        to="https://pro-gigstack.s3.us-east-2.amazonaws.com/legal/privacy.pdf"
                                        target="_blank"
                                    >
                                        <span className="p-small-bold primary-1">aviso de privacidad</span>
                                    </Link>
                                </Typography.Paragraph>
                            }
                        />

                        <Button
                            className="btn-primary"
                            htmlType="submit"
                            block
                            loading={loading.includes('email')}
                            disabled={loading.length !== 0}
                            onClick={() => form.submit()}
                        >
                            Regístrate
                        </Button>
                    </Form>

                    <Divider plain className="neutral-4">
                        <Typography.Text className="p-xsmall-regular neutral-3">o régistrate con</Typography.Text>
                    </Divider>
                    <GoogleSignInButton
                        onClick={() => {
                            if (form.getFieldValue('terms-privacy')) {
                                trackEvent({ name: 'signed_up_with_google', metadata: { v: 'v2' } }, posthog)
                                googleSignIn()
                            } else {
                                form.setFields([
                                    { name: 'terms-privacy', errors: ['Por favor acepta los términos y condiciones'] },
                                ])
                            }
                        }}
                        loading={loading.includes('google')}
                    />
                </AuthFormLayout>
            </Col>
            <Col
                xs={0}
                md={12}
                style={{
                    background: 'linear-gradient(221.51deg, #000000 0.75%, #044737 69.64%)',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '40px',
                }}
            >
                <img
                    src={Hero}
                    alt="Hero"
                    draggable={false}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        mixBlendMode: 'soft-light',
                        opacity: 0.4,
                    }}
                />
                <div
                    style={{
                        position: 'relative',
                        zIndex: 1,
                        width: '100%',
                        maxWidth: '720px',
                        margin: '0 auto',
                        textAlign: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '16px',
                            margin: '0 0 24px 0',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar.Group>
                                <Avatar
                                    src="https://gigstack.pro/images/juan-novelo-p-500.jpeg"
                                    size={40}
                                    style={{
                                        border: '2px solid white',
                                        filter: 'grayscale(100%)',
                                    }}
                                />
                                <Avatar
                                    src="https://gigstack.pro/images/Xally.png"
                                    size={40}
                                    style={{
                                        border: '2px solid white',
                                        filter: 'grayscale(100%)',
                                    }}
                                />
                                <Avatar
                                    src="https://gigstack.pro/images/juan-groz-p-500.jpeg"
                                    size={40}
                                    style={{
                                        border: '2px solid white',
                                        filter: 'grayscale(100%)',
                                    }}
                                />
                            </Avatar.Group>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
                            <Rate
                                disabled
                                defaultValue={5}
                                style={{
                                    fontSize: '16px',
                                    color: '#FFD700',
                                }}
                            />
                            <Typography.Title
                                level={4}
                                style={{
                                    color: 'white',
                                    fontSize: '28px',
                                    lineHeight: '1.2',
                                    margin: 0,
                                    fontWeight: '500',
                                }}
                            >
                                Amado por +2k empresas
                            </Typography.Title>
                        </div>
                    </div>

                    <div style={{ maxWidth: '600px', width: '100%', margin: '0 auto', marginTop: '100px' }}>
                        <Carousel
                            autoplay
                            effect="fade"
                            // dots={{ className: 'custom-dots' }}
                            arrows={true}
                            draggable={true}
                            dotPosition="bottom"
                        >
                            {testimonials.map((testimonial, index) => (
                                <div key={index}>
                                    <TestimonialCard {...testimonial} />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default SignUp
