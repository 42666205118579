import { Col, Form, Row } from 'antd'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import { useSelector } from 'react-redux'
import { useFirestoreDocDataOnce } from 'reactfire'
import { mexicanStates } from '../../../datasets/Countries'
import { taxRegimes } from '../../../functions/helpers'
import ErrorsInFormComponent from '../ErrorsInFormComponent'
import { FileUpload, NumberInput, PasswordInput, SelectInput, TextInput } from '../Inputs'
import { usePostHog } from 'posthog-js/react'
import { trackEvent } from '../../../analytics/helpers'

export const SATDefaults = () => {
    return (
        <Row>
            <Col xs={24}>
                <Form.Item label={`Siguiente folio en facturas de ingreso`}>
                    <NumberInput
                        description="Añade el folio que con el que quieras que se emita la próxima factura de ingreso"
                        name={ 'invoice_folio'}
                        min={1}
                        max={100000000}
                        step={1}
                    />
                </Form.Item>
            </Col>
            <Col xs={24}>
                <Form.Item label={`Siguiente folio en facturas de ingreso (prueba)`}>
                    <TextInput
                        description="Añade el folio que con el que quieras que se emita la próxima factura de ingreso de prueba"
                        name={ 'invoice_folio_test'}
                    />
                </Form.Item>
            </Col>
            <Col xs={24}>
                <Form.Item label="Siguiente folio en complementos de pago">
                    <NumberInput name="invoice_folio_payments" min={1} max={100000000} step={1} />
                </Form.Item>
            </Col>
            <Col xs={24}>
                <Form.Item label="Siguiente folio en facturas de egreso">
                    <NumberInput name="invoice_folio_egress" min={1} max={100000000} step={1} />
                </Form.Item>
            </Col>
            <Col xs={24}>
                <Form.Item label="Serie de factura de ingreso">
                    <TextInput name="invoice_serie" />
                </Form.Item>
            </Col>
            <Col xs={24}>
                <Form.Item label="Serie de complementos de pago">
                    <TextInput name="invoice_serie_payments" />
                </Form.Item>
            </Col>
            <Col xs={24}>
                <Form.Item label="Serie de facturas de egreso">
                    <TextInput name="invoice_serie_egress" />
                </Form.Item>
            </Col>
        </Row>
    )
}

export const FiscalInformationForm = () => {
    const { team } = useSelector((state: any) => state.team)

    return (
        <>
            <Row gutter={10}>
                <Col xs={24}>
                    <TextInput
                        label="Nombre legal"
                        name="legalName"
                        description="Nombre legal sin acentos ni régimen societario (Ej: SA de CV) "
                        rules={[{ required: true, message: 'Por favor ingresa la razón social' }]}
                    />
                </Col>
                <Col xs={24}>
                    <TextInput
                        disabled={team?.facturapi?.completed}
                        label="RFC"
                        name="rfc"
                        rules={[{ required: true, message: 'Por favor ingresa el RFC' }]}
                    />
                </Col>
            </Row>

            <Row gutter={10} style={{}}>
                <Col xs={24}>
                    <SelectInput
                        label="Régimen fiscal"
                        options={taxRegimes}
                        name="taxSystem"
                        rules={[{ required: true, message: 'Por favor ingresa el regimen fiscal' }]}
                    />
                </Col>
            </Row>
        </>
    )
}

export const FiscalAddressForm = () => {
    return (
        <>
            <Row gutter={10}>
                <Col xs={24}>
                    <TextInput
                        label="Nombre de la calle"
                        name="street"
                        rules={[{ required: true, message: 'Por favor ingresa la calle' }]}
                    />
                </Col>
            </Row>

            <Row gutter={10}>
                <Col xs={12}>
                    <TextInput
                        label="Número exterior"
                        name="exterior"
                        rules={[{ required: true, message: 'Por favor ingresa el número exterior' }]}
                    />
                </Col>
                <Col xs={12}>
                    <TextInput label="Número interior" name="interior" />
                </Col>
            </Row>

            <Row gutter={10}>
                <Col xs={12}>
                    <TextInput
                        label="Colonia"
                        name="neighborhood"
                        rules={[{ required: true, message: 'Por favor ingresa la colonia' }]}
                    />
                </Col>
                <Col xs={12}>
                    <TextInput
                        label="Código postal"
                        name="zip"
                        rules={[{ required: true, message: 'Por favor ingresa el código postal' }]}
                    />
                </Col>
            </Row>

            <Row gutter={10}>
                <Col xs={12}>
                    <TextInput
                        label="Ciudad"
                        name="city"
                        rules={[{ required: true, message: 'Por favor ingresa la ciudad' }]}
                    />
                </Col>
                <Col xs={12}>
                    <SelectInput
                        label="Estado"
                        options={mexicanStates}
                        name="state"
                        rules={[{ required: true, message: 'Por favor ingresa el estado' }]}
                    />
                </Col>
            </Row>
        </>
    )
}

export const CSDForm = ({ error }: { error: string | null }) => {
    const fs = getFirestore()
    const posthog = usePostHog()
    const { team } = useSelector((state: any) => state.team)
    const { status, data: certData } = useFirestoreDocDataOnce(doc(fs, `teams/${team?.id}/privateFiles/cert`))
    const { status: statusKey, data: keyData } = useFirestoreDocDataOnce(doc(fs, `teams/${team?.id}/privateFiles/key`))

    console.log(status, statusKey)

    const privateFilesPrefix = 'csd'

    return (
        <>
            <Row gutter={10}>
                <Col xs={24}>
                    <FileUpload
                        incomingFiles={
                            certData
                                ? [
                                      {
                                          ...certData,
                                          name: 'Certificado.cer',
                                          percent: 100,
                                          status: 'done',
                                      },
                                  ]
                                : []
                        }
                        name="cer"
                        label="Sube el archivo .cer"
                        firestoreKey="csdcert"
                        allowedFiles={['application/x-x509-ca-cert']}
                        placeholder="Seleccion o arrastra un archivo"
                        firestorePath={`teams/${team?.id}/privateFiles/${privateFilesPrefix}cert`}
                        storagePath={`teams/${team?.id}/privateFiles/cert`}
                        onFileUploaded={async (file: any) => {
                            trackEvent({ name: 'csd_cer_uploaded', metadata: { v: 'v2' } }, posthog)
                            await setDoc(
                                doc(fs, `teams/${team?.id}/privateFiles/${privateFilesPrefix}cert`),
                                {
                                    invoicingKey: true,
                                    code: 'csdcert',
                                    type: 'cert',
                                    name: file.name,
                                    url: file.url,
                                    uid: file.uid,
                                    storageRef: file.storageRef,
                                    path: file.storageRef,
                                },
                                { merge: true },
                            )
                        }}
                    />
                </Col>
                <Col xs={24}>
                    <FileUpload
                        incomingFiles={
                            keyData
                                ? [
                                      {
                                          ...keyData,
                                          percent: 100,
                                          status: 'done',
                                          name: 'Llave.key',
                                      },
                                  ]
                                : []
                        }
                        name="key"
                        label="Sube el archivo .key"
                        firestoreKey="csdkey"
                        placeholder="Seleccion o arrastra un archivo"
                        firestorePath={`teams/${team?.id}/privateFiles/${privateFilesPrefix}key`}
                        storagePath={`teams/${team?.id}/privateFiles/key`}
                        allowedFiles={[
                            //Keynote document
                            'application/x-keynote',
                        ]}
                        onFileUploaded={async (file: any) => {
                            trackEvent({ name: 'csd_key_uploaded', metadata: { v: 'v2' } }, posthog)
                            await setDoc(
                                doc(fs, `teams/${team?.id}/privateFiles/${privateFilesPrefix}key`),
                                {
                                    invoicingKey: true,
                                    code: 'csdkey',
                                    type: 'key',
                                    name: file.name,
                                    url: file.url,
                                    uid: file.uid,
                                    storageRef: file.storageRef,
                                    path: file.storageRef,
                                },
                                { merge: true },
                            )
                        }}
                    />
                </Col>
            </Row>

            <Row gutter={10}>
                <Col xs={24}>
                    <PasswordInput
                        label="Contraseña"
                        name="password"
                        rules={[{ required: true, message: 'Por favor ingresa la contraseña' }]}
                        description="Recuerda que es la contraseña exclusiva de tu Certificado de Sello Digital"
                    />
                </Col>
            </Row>

            {error && (
                <Row gutter={10}>
                    <Col xs={24}>
                        <ErrorsInFormComponent title="Error al conectar con el SAT" errorMessage={error} />
                    </Col>
                </Row>
            )}
        </>
    )
}

export const EditFiscalInformationForm = () => {
    return (
        <>
            <FiscalInformationForm />
            <FiscalAddressForm />
        </>
    )
}

export const UpdateCSD = ({ error }: { error: string }) => {
    return (
        <>
            <CSDForm error={error} />
        </>
    )
}
