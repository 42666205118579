// onboardingSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum ONBOARDING_STEP {
    USER = 0,
    INVITE = 1,
    PLAN = 2,
    TEAM = 3,
    FISCAL_DATA = 4,
    SAT_CONNECTION = 5,
    FINISHED = 6,
    DEFAULTS = 7,
    ACCEPT_INVITE = 8,
}

export const ONBOARDING_ROUTES = {
    0: '/onboarding/user',
    1: '/onboarding/invite',
    2: '/onboarding/plan',
    3: '/onboarding/team',
    4: '/onboarding/fiscal-data',
    5: '/onboarding/sat-connection',
    6: '/onboarding/finished',
    7: '/onboarding/defaults',
    8: '/onboarding/accept-invite',
}

interface OnboardingState {
    currentStep: ONBOARDING_STEP | null
    loading: boolean | null
    dataLoaded: boolean | null
    needsOnboarding?: boolean | null
    stepLoading: boolean | null
    invites?: any[]
    invitesLoaded: boolean
    inviteLoading: String[]
}

const initialState: OnboardingState = {
    currentStep: null,
    loading: true,
    dataLoaded: false,
    needsOnboarding: false,
    stepLoading: false,
    invites: [],
    invitesLoaded: false,
    inviteLoading: [],
}

const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {
        setCurrentStep: (state, action: PayloadAction<ONBOARDING_STEP | null>) => {
            state.currentStep = action.payload
        },
        setOnboardinLoading: (state, action: PayloadAction<boolean | null>) => {
            state.loading = action.payload
        },
        setDataLoaded: (state, action: PayloadAction<boolean | null>) => {
            state.dataLoaded = action.payload
        },
        setNeedsOnboarding: (state, action: PayloadAction<boolean | null>) => {
            state.needsOnboarding = action.payload
        },
        setStepLoading: (state, action: PayloadAction<boolean | null>) => {
            state.stepLoading = action.payload
        },
        setInvites: (state, action: PayloadAction<any[]>) => {
            state.invites = action.payload
        },
        setInvitesLoaded: (state, action: PayloadAction<boolean>) => {
            state.invitesLoaded = action.payload
        },
        setInviteLoading: (state, action: PayloadAction<string[]>) => {
            state.inviteLoading = action.payload
        },
    },
})

export const {
    setCurrentStep,
    setOnboardinLoading,
    setDataLoaded,
    setNeedsOnboarding,
    setStepLoading,
    setInvites,
    setInvitesLoaded,
    setInviteLoading,
} = onboardingSlice.actions
export default onboardingSlice.reducer
