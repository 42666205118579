import { useEffect, useRef, useState, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { GetServices } from '../../context/databaseContextHelpers'
import { Button, Row, Table, Tooltip, Typography, message, InputNumber, InputRef, Input, Space, Modal } from 'antd'
import { openModal } from '../../context/modalsSlice'
import { FloppyDisk, MagnifyingGlass, PencilSimple, PlusCircle, Question, Trash, X, Plus } from '@phosphor-icons/react'
import { setData } from '../../context/dataSlice'
import { returnCurrencyValue } from '../../functions/helpers'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { MetroSpinner } from 'react-spinners-kit'
import { TaxElement } from '../../../interfaces/invoicesDef'
import EditAction from '../ElementActions/SingleActions/EditAction'
import { generateCode } from '../../../helpers/helperFunctions'
import { InternalItem } from '../../../interfaces/internalItemDef'
import { FilterConfirmProps } from 'antd/lib/table/interface'
import { ColumnType } from 'antd/es/table'
import stripeLogo from '../../../assets/images/stripeSquare.png'
import TaxesTable from '../Tables/TaxesTable'

const EditItemTaxesModal = ({ 
    open, 
    onClose, 
    record,
    onSave 
}: { 
    open: boolean
    onClose: () => void
    record: any
    onSave: (taxes: any[]) => void
}) => {
    const dispatch = useDispatch()
    const { taxes } = useSelector((state: any) => state.data)

    useEffect(() => {
        if (open && record?.taxes) {
            dispatch(setData({ 
                item: 'taxes', 
                data: record.taxes 
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, record])

    return (
        <Modal
            title="Editar impuestos"
            open={open}
            onCancel={() => {
                dispatch(setData({ item: 'taxes', data: [] }))
                onClose()
            }}
            onOk={() => {
                onSave(taxes)
                dispatch(setData({ item: 'taxes', data: [] }))
                onClose()
            }}
            width={600}
            zIndex={1002}
        >
            <Row justify="space-between" align="middle" style={{ marginBottom: '15px' }}>
                <Typography.Text>Impuestos del producto</Typography.Text>
                <Button
                    onClick={() => dispatch(openModal('addTaxModalVisible'))}
                    icon={<Plus size={15} />}
                >
                    Añadir impuesto
                </Button>
            </Row>
            <TaxesTable
                customDelete={(taxToDelete: any) => {
                    dispatch(setData({
                        item: 'taxes',
                        data: taxes.filter((t: any) => 
                            t.type !== taxToDelete.type || 
                            t.rate !== taxToDelete.rate || 
                            t.factor !== taxToDelete.factor ||
                            t.inclusive !== taxToDelete.inclusive
                        )
                    }))
                }}
                incomingTaxes={taxes}
            />
        </Modal>
    )
}

const ItemsSelection = ({
    hideTitle,
    disabled,
    incomingServices,
    changeIncoming,
    showServiceSelection,
}: {
    hideTitle?: boolean
    disabled?: boolean
    incomingServices?: any[]
    changeIncoming?: Function
    showServiceSelection?: boolean
}) => {
    const dispatch = useDispatch()

    const { services, servicesLoaded, selectedServices, itemsTablePagination } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)

    const [loading, setLoading] = useState<string[]>([])
    const [editingTaxes, setEditingTaxes] = useState<any>(null)

    const fromContext = !((incomingServices ?? []).length > 0)
    
    const usingServices = useMemo(() => {
        return ((incomingServices ?? []).length > 0 ? incomingServices : selectedServices ?? []).map(
            (s: any) => {
                const localId = s.localId || generateCode(8)
                return {
                    ...s,
                    key: s.id,
                    localId
                }
            },
        )
    }, [incomingServices, selectedServices])
    const OnItemsChange = ({ newItems }: { newItems: InternalItem[] }) => {
        if (changeIncoming) changeIncoming(newItems)
        else
            dispatch(
                setData({
                    item: 'selectedServices',
                    data: newItems,
                }),
            )
    }

    useEffect(() => {
        if (services.length <= 0 && !servicesLoaded) {
            setLoading([...loading, 'initialData'])
            GetServices(dispatch, team.id, (incomingServices) => {})
        } else {
            setLoading(loading.filter((l) => l !== 'initialData'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let def: InternalItem
    const searchInput = useRef<InputRef>(null)

    type DataIndex = keyof typeof def

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm()
    }

    const handleReset = (clearFilters: () => void) => {
        clearFilters()
    }
    const SearchPropsColumn = (dataIndex: DataIndex): ColumnType<typeof def> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} className="d-flex flex-column" onKeyDown={(e) => e.stopPropagation()}>
                <Tooltip title={`Buscando localmente`}>
                    <Typography.Text className="smallparagraph descriptions" style={{ marginBottom: '5px' }}>
                        Búsqueda local
                    </Typography.Text>
                </Tooltip>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Typography.Text
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys as string[], confirm, dataIndex)
                        }}
                        className="smallparagraph descriptions clickable"
                    >
                        Restablecer
                    </Typography.Text>

                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<MagnifyingGlass />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>

                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        <X className="icon" />
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => {
            return (
                <MagnifyingGlass
                    style={{
                        color: filtered ? '#8666FF' : '#50566B',
                    }}
                    size={16}
                />
            )
        },
        onFilter: (value, record) => {
            return (
                record?.name
                    ?.toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                record?.sku
                    ?.toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                record?.description
                    ?.toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                record?.product_key
                    ?.toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                record?.id
                    ?.toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase())
            )
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
        render: (text, record, index) => <NameColumn record={record} index={index} value={text} />,
    })

    const PriceColumn = ({ record, value, index }: { record: any; value: any; index: number }) => {
        return (
            <Typography.Text
                className="smallparagraphbold"
                editable={
                    !disabled
                        ? {
                              icon: <PencilSimple size={10} className="icon" weight="regular" />,
                              onChange: (value) => {
                                  if (!value) return
                                  const numericValue = Number(value.replace(/[^0-9.-]+/g, ''))
                                  if (!numericValue) return
                                  if (isNaN(numericValue)) return

                                  const { page, pageSize } = itemsTablePagination
                                  const globalIndex = pageSize * (page - 1) + index
                                  const newServices = [...usingServices].map((s: any, i: number) => {
                                        if (i === globalIndex) {
                                            s.total = numericValue
                                            s.localChanges = true
                                        }
                                        return s
                                  })

                                  if (fromContext) {
                                    dispatch(
                                        setData({
                                            item: 'selectedServices',
                                            data: newServices,
                                        }),
                                    )
                                  } else {
                                      OnItemsChange({ newItems: newServices })
                                  }
                              },
                          }
                        : false
                }
            >
                {returnCurrencyValue(value)}
            </Typography.Text>
        )
    }

    const DeleteRowAction = ({ value, record, index }: { value: any; record: any; index: number }) => {
        return (
            <Trash
                size={18}
                weight="regular"
                style={{ marginRight: '5px' }}
                className="icon clickable iconDanger"
                onClick={() => {

                    const { page, pageSize } = itemsTablePagination
                    const globalIndex =  pageSize * (page - 1) + index
                    const newServices = [...usingServices].filter((s: any, i: number) => i !== globalIndex)

                    if (!fromContext) {
                        let indexInTotal = (usingServices ?? []).findIndex((s: any, i: number) => i === globalIndex)

                        if (indexInTotal >= 0) {
                            let newIncoming = [...usingServices]
                            message.success(`Servicio ${newIncoming[indexInTotal].name} eliminado de la lista`)
                            newIncoming.splice(indexInTotal, 1)
                            OnItemsChange({ newItems: newIncoming })
                        }
                        return
                    }
                    dispatch(
                        setData({
                            item: 'selectedServices',
                            data: newServices,
                        }),
                    )
                }}
            />
        )
    }

    const NameColumn = ({ record, index, value }: { record: any; index: number; value: any }) => {
        return (
            <Row>
                {record?.localChanges && (
                    <Tooltip title="Este servicio ha sido editado localmente. ¿Guardar en base de datos?">
                        {loading.includes(`saving${record.id}`) ? (
                            <MetroSpinner size={15} color="#8666FF" />
                        ) : fromContext ? (
                            <FloppyDisk
                                size={15}
                                weight="regular"
                                className="icon clickable"
                                style={{ marginRight: '5px' }}
                                onClick={async () => {
                                    try {
                                        setLoading([...loading, `saving${record.id}`])

                                        var toUpdate = Object.keys(record).reduce((object: any, key) => {
                                            if (record[key] !== undefined) {
                                                object[key] = record[key]
                                            }
                                            return object
                                        }, {})
                                        await updateDoc(doc(getFirestore(), 'services', record.id), {
                                            ...toUpdate,
                                            quantity: 1,
                                        })

                                        const newSelectedServices = usingServices?.map((s: any, i: number) => {
                                            if (i === index) {
                                                return {
                                                    ...record,
                                                    ...toUpdate,
                                                    localChanges: false,
                                                }
                                            }
                                            return s
                                        })

                                        dispatch(
                                            setData({
                                                item: 'selectedServices',
                                                data: newSelectedServices,
                                            }),
                                        )

                                        setLoading(loading.filter((l) => l !== `saving${record.id}`))
                                    } catch (error) {
                                        message.error('Error al guardar en base de datos')
                                    }
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </Tooltip>
                )}
                <div className="d-flex flex-column">
                    <Typography.Text
                        className="smallparagraphbold"
                        editable={
                            !disabled
                                ? {
                                      icon: <PencilSimple size={10} className="icon" weight="regular" />,
                                      onChange: (value) => {
                                          if (!value) return

                                          const { page, pageSize } = itemsTablePagination
                                          const globalIndex = pageSize * (page - 1) + index
                                          const newServices = [...usingServices].map((s: any, i: number) => {
                                                if (i === globalIndex) {
                                                    s.name = value
                                                    s.localChanges = true
                                                }
                                                return s
                                          })
                                          if (fromContext) {
                                              dispatch(
                                                  setData({
                                                        item: 'selectedServices',
                                                        data: newServices
                                                  }),
                                              )
                                          } else {
                                              OnItemsChange({ newItems: newServices })
                                          }
                                      },
                                  }
                                : false
                        }
                    >
                        {record.name}
                    </Typography.Text>
                    <Typography.Text
                        className="smallparagraph descriptions"
                        editable={
                            !disabled
                                ? {
                                      icon: <PencilSimple size={10} className="icon" weight="regular" />,

                                      onChange(value) {
                                            if (!value) return

                                            const { page, pageSize } = itemsTablePagination
                                            const globalIndex = pageSize * (page - 1) + index
                                            const newServices = [...usingServices].map((s: any, i: number) => {
                                                if (i === globalIndex) {
                                                    s.description = value
                                                    s.localChanges = true
                                                }
                                                return s
                                            })
                                            if (fromContext) {
                                                dispatch(
                                                    setData({
                                                        item: 'selectedServices',
                                                        data: newServices
                                                    }),
                                                )
                                            } else {
                                                OnItemsChange({ newItems: newServices })
                                            }
                                      },
                                  }
                                : false
                        }
                    >
                        {' '}
                        <Tooltip title="Código del producto para el SAT">
                            <Question style={{ marginRight: '5px' }} weight="regular" className="icon" />
                        </Tooltip>{' '}
                        {record?.product_key}
                    </Typography.Text>
                    <Typography.Text className="smallparagraph descriptions">
                        {' '}
                        {record?.unit_name ?? 'Sin Unidad de Medida'}
                    </Typography.Text>
                </div>
            </Row>
        )
    }

    const QuantityColumn = ({ record, index, value }: { record: any; index: number; value: any }) => {
        return (
            <InputNumber
                name="quantity"
                disabled={disabled || false}
                defaultValue={value}
                style={{
                    margin: 0,
                    padding: 0,
                }}
                onBlur={(v: any) => {
                    const value = v.target.value

                    if (!value) return

                    const { page, pageSize } = itemsTablePagination
                    const globalIndex = pageSize * (page - 1) + index

                    const newServices = [...usingServices].map((s: any, i: number) => {
                        if (i === globalIndex) {
                            s.quantity = value
                            s.localChanges = true
                        }
                        return s
                    })

                    if (fromContext) {
                        dispatch(
                            setData({
                                item: 'selectedServices',
                                data: newServices
                            }),
                        )
                    } else {
                        OnItemsChange({ newItems: newServices})
                    }
                }}
                min={1}
                max={100000}
                step={1}
            />
        )
    }

    const DiscountColumn = ({ record, index, value }: { record: any; index: number; value: any }) => {
        return (
            <Typography.Text
                className="smallparagraphbold"
                editable={
                    !disabled
                        ? {
                              icon: <PencilSimple size={10} className="icon" weight="regular" />,
                              onChange: (value) => {
                                  if (!value && Number(value) !== 0) return
                                  const numericValue = Number(value.replace(/[^0-9.-]+/g, ''))
                                  if (!numericValue && numericValue !== 0) return
                                  if (isNaN(numericValue)) return
                                  if (numericValue > record.total * record.quantity)
                                      return message.error('El descuento no puede ser mayor al total')

                                  const { page, pageSize } = itemsTablePagination
                                  const globalIndex = pageSize * (page - 1) + index
                                  const newServices = [...usingServices].map((s: any, i: number) => {
                                        if (i === globalIndex) {
                                            s.discount = numericValue
                                            s.localChanges = true
                                        }
                                        return s
                                  })
                                  if (fromContext) {
                                      dispatch(
                                            setData({
                                                item: 'selectedServices',
                                                data: newServices,
                                            }),
                                      )
                                  } else {
                                      OnItemsChange({ newItems: newServices })
                                  }
                              },
                          }
                        : false
                }
            >
                {returnCurrencyValue(value ?? 0)}
            </Typography.Text>
        )
    }

    const handleTaxUpdate = (newTaxes: any[]) => {
        const updatedServices = usingServices.map((service: any) => {
            if (service.localId === editingTaxes.localId) {
                return {
                    ...service,
                    taxes: newTaxes.map(tax => ({
                        type: tax.type,
                        rate: tax.rate,
                        factor: tax.factor,
                        inclusive: tax.inclusive,
                        withholding: tax.withholding,
                        id: `${tax.type}-${tax.rate}-${tax.factor}-${tax.inclusive}-${tax.withholding}`
                    })),
                    localChanges: true
                }
            }
            return service
        })

        if (fromContext) {
            dispatch(setData({
                item: 'selectedServices',
                data: updatedServices
            }))
        } else {
            OnItemsChange({ newItems: updatedServices })
        }
    }

    return (
        <div className="d-flex flex-column" style={{ padding: '10px 0px' }}>
            <EditItemTaxesModal
                open={!!editingTaxes}
                onClose={() => setEditingTaxes(null)}
                record={editingTaxes || {}}
                onSave={handleTaxUpdate}
            />
            <Row align="middle" justify="space-between" style={{ marginBottom: '10px' }}>
                {hideTitle ? (
                    <div></div>
                ) : (
                    <Typography.Text className="bigparagraphbold">Servicios / Productos</Typography.Text>
                )}
                <Row align="middle">
                    {!disabled && (
                        <Button
                            disabled={disabled || false}
                            onClick={() => dispatch(openModal('serviceSelectionVisible'))}
                            icon={<PlusCircle weight="regular" className="icon clickable" />}
                        >
                            Seleccionar
                        </Button>
                    )}
                    {selectedServices?.length > 0 && !disabled && (
                        <Tooltip title={`Eliminar selección (${selectedServices?.length})`}>
                            <Trash
                                size={18}
                                weight="regular"
                                className="icon clickable iconDanger"
                                style={{ marginLeft: '10px' }}
                                onClick={() => {
                                    dispatch(
                                        setData({
                                            item: 'selectedServices',
                                            data: [],
                                        }),
                                    )
                                }}
                            />
                        </Tooltip>
                    )}
                </Row>
            </Row>
            <Table
                pagination={{
                    hideOnSinglePage: true,
                    onChange: (page, pageSize) => {
                        dispatch(
                            setData({
                                item: 'itemsTablePagination',
                                data: {
                                    page: page,
                                    pageSize: pageSize,
                                },
                            }),
                        )
                    },
                    pageSize: itemsTablePagination.pageSize,
                    current: itemsTablePagination.page,
                    defaultCurrent: itemsTablePagination.page,
                }}
                rowKey={(record: any) => `${generateCode(8)}-${record.id}`}
                scroll={{ x: 1000 }}
                dataSource={usingServices}
                locale={{
                    emptyText: (
                        <div
                            className="d-flex flex-column"
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                                padding: '20px',
                            }}
                        >
                            <Typography.Text className="smallparagraph descriptions">
                                No hay servicios / productos seleccionados
                            </Typography.Text>
                            <Typography.Link
                                className="smallparagraph"
                                style={{ marginTop: '5px' }}
                                onClick={() => dispatch(openModal('serviceSelectionVisible'))}
                            >
                                seleccionar servicios
                            </Typography.Link>
                        </div>
                    ),
                }}
                columns={[
                    {
                        title: 'Nombre',
                        dataIndex: 'name',
                        fixed: 'left',
                        width: '14%',
                        key: 'name',
                        ...SearchPropsColumn('name'),
                        render(value, record, index) {
                            return <NameColumn record={record} index={index} value={value} />
                        },
                    },
                    {
                        title: 'Precio',
                        dataIndex: 'total',
                        key: 'total',
                        render(value, record, index) {
                            return <PriceColumn record={record} value={value} index={index} />
                        },
                    },
                    {
                        title: 'Cantidad',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        render(value, record, index) {
                            return <QuantityColumn record={record} index={index} value={value} />
                        },
                    },
                    {
                        //descuentos
                        title: 'Descuentos',
                        dataIndex: 'discount',
                        key: 'discount',
                        render(value, record, index) {
                            return <DiscountColumn record={record} value={value} index={index} />
                        },
                    },
                    {
                        title: 'Total',
                        dataIndex: 'total',
                        key: 'total',
                        render(value, record, index) {
                            return (
                                <Typography.Text className="smallparagraphbold">
                                    {returnCurrencyValue(value * record.quantity - (record.discount ?? 0))}
                                </Typography.Text>
                            )
                        },
                    },
                    {
                        title: 'Impuestos',
                        dataIndex: 'taxes',
                        key: 'taxes',
                        render(value: any, record: any) {
                            return (
                                <div style={{ cursor: !disabled ? 'pointer' : 'default' }}>
                                    <Typography.Text
                                        className="smallparagraph descriptions"
                                        editable={
                                            !disabled
                                                ? {
                                                    icon: <PencilSimple size={10} className="icon" weight="regular" />,
                                                    editing: false,
                                                    onStart: () => {
                                                        dispatch(setData({ 
                                                            item: 'taxes', 
                                                            data: record.taxes || [] 
                                                        }))
                                                        setEditingTaxes({
                                                            ...record
                                                        })
                                                    }
                                                }
                                                : false
                                        }
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {record?.taxes?.map((t: TaxElement, index: number) => (
                                                <span key={`tax-${index}`} style={{ lineHeight: '1.5em' }}>
                                                    {t.type} {t.rate}% {t.inclusive ? '(Incl.)' : ''}{' '}
                                                    {t.withholding ? '(Ret.)' : ''}
                                                </span>
                                            ))}
                                        </div>
                                    </Typography.Text>
                                </div>
                            )
                        }
                    },
                    {
                        title: '',
                        dataIndex: 'actions',
                        fixed: 'right',
                        width: '10%',
                        key: 'actions',
                        render: (value, record, index) => (
                            <Row align="middle" justify="center" gutter={{ xs: 12, lg: 10 }}>
                                {record?.id?.startsWith('price_') && (
                                    <Tooltip title="Servicio importado de Stripe">
                                        <img
                                            src={stripeLogo}
                                            width={'18px'}
                                            height={'18px'}
                                            style={{ borderRadius: '4px', marginRight: '15px' }}
                                            alt="Stripe"
                                        />
                                    </Tooltip>
                                )}

                                {!disabled && <DeleteRowAction value={value} record={record} index={index} />}

                                {!disabled && fromContext && (
                                    <EditAction
                                        action={() => {
                                            dispatch(
                                                setData({
                                                    item: 'service',
                                                    data: {
                                                        ...record,
                                                        index: index,
                                                    },
                                                }),
                                            )
                                            dispatch(
                                                setData({
                                                    item: 'taxes',
                                                    data: record.taxes,
                                                }),
                                            )
                                            dispatch(openModal('createServiceVisible'))
                                        }}
                                        loading={false}
                                    />
                                )}
                            </Row>
                        ),
                    },
                ]}
            />
        </div>
    )
}

export default ItemsSelection
