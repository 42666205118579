import {
    ArrowCounterClockwise,
    CloudArrowDown,
    Copy,
    DotsThreeOutlineVertical,
    FilePdf,
    Flag,
    Prohibit,
    Stamp,
    Trash,
    Warning,
    WhatsappLogo,
} from '@phosphor-icons/react'
import { Alert, Dropdown, MenuProps, Popconfirm, Popover, Row, Space, Tooltip, Typography, message } from 'antd'
import { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingDefinition } from '../../../interfaces/loadingDef'
import { MetroSpinner } from 'react-spinners-kit'
import EmailActions from './SingleActions/EmailActions'
import EditAction from './SingleActions/EditAction'
import CopyAction from './SingleActions/CopyAction'
import { openModal, setElementSelected } from '../../context/modalsSlice'
import { setData } from '../../context/dataSlice'
import { DownloadFile } from '../../functions/helpers'
import { arrayUnion, deleteDoc, doc, getFirestore, updateDoc } from 'firebase/firestore'
import { usePermissions } from '../../customHooks/usePermissions'
import moment from 'moment'
import { clientDataHelper } from '../../../helpers/helperFunctions'

interface ReceiptsActionsProps {
    receipt: any
    enabled?: boolean | null
    hide?: boolean | null
    showFull?: boolean
}

const ReceiptActions: FunctionComponent<ReceiptsActionsProps> = (props) => {
    const fs = getFirestore()

    const { user } = useSelector((state: any) => state.user)

    const { canCreateReceipt } = usePermissions()

    const dispatch = useDispatch()

    const [loading, setLoading] = useState<LoadingDefinition[]>([])
    const [, /*actionOnReceipt*/ setactionOnReceipt] = useState(null)

    const [, /*downloading*/ setdownloading] = useState(false)
    const { receipt } = props

    if (props.hide) return <></>

    const thisReceiptHasAnyLoading = loading.find((l) => l.id === receipt?.id) !== undefined

    const LoadingIcon = () => {
        return <MetroSpinner size={16} color="#686769" />
    }

    const DownloadAction = ({ full }: { full: boolean }) => {
        // const keys = [{ key: '0', type: 'pdf' }, { key: '1', type: 'xml' }, { key: '2', type: 'zip' }]
        const downloadItems: MenuProps['items'] = [
            {
                label: (
                    <Row align="middle">
                        <FilePdf className="clickableIcon icon" size={18} weight="regular" />
                        <Typography.Text style={{ margin: 0, padding: 0, fontSize: '13px', marginLeft: '5px' }}>
                            Descargar PDF
                        </Typography.Text>
                    </Row>
                ),
                key: '0',
            },
        ]
        return (
            <div
                style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Dropdown
                    disabled={thisReceiptHasAnyLoading}
                    menu={{
                        items: downloadItems,
                        onClick: async () => {
                            DownloadFile({
                                type: 'pdf',
                                url: receipt.pdf,
                                name: 'Recibo-de-venta',
                                setdownloading: (r: any) => setdownloading(r),
                            })
                            await new Promise((resolve) => setTimeout(resolve, 5000))
                            setLoading(loading.filter((l) => l.id !== receipt?.id && l.action !== `download`))
                        },
                    }}
                    trigger={['click']}
                >
                    <Row>
                        {loading.find((l) => l.id === receipt?.id && l.action === `download`) ? (
                            <LoadingIcon />
                        ) : (
                            <CloudArrowDown className="clickableIcon icon" size={18} weight="regular" />
                        )}
                        {full && <Typography.Text style={{ marginLeft: '5px' }}>Descargar</Typography.Text>}
                    </Row>
                </Dropdown>
            </div>
        )
    }

    const CancelAction = ({ full }: { full: boolean }) => {
        if (receipt?.status !== 'canceled' && receipt?.cancellation_status !== 'pending' && canCreateReceipt) {
            return (
                <Popconfirm
                    disabled={thisReceiptHasAnyLoading}
                    title="Cancelar recibo"
                    description="Este recibo no se podrá facturar"
                    okText="Cancelar"
                    cancelText="Volver"
                    okButtonProps={{ danger: true }}
                    cancelButtonProps={{ type: 'default' }}
                    onConfirm={async () => {
                        setactionOnReceipt(receipt.id)
                        message.loading('Cancelando recibo...')
                        try {
                            await updateDoc(doc(fs, 'receipts', receipt.id), {
                                status: 'completed',
                                invoiceErrorMessage: 'Recibo cancelado por usuario',
                                logs: arrayUnion({
                                    date: moment().format('YYYY-MM-DD HH:mm:ss'),
                                    changed: 'status',
                                    message: `Recibo cancelado por ${user?.email}`,
                                }),
                                disallowInvoice: true,
                            })

                            message.success('Recibo cancelado')
                            setactionOnReceipt(null)
                        } catch (error) {
                            message.error('Error al cancelar Recibo')
                            setactionOnReceipt(null)
                        }
                    }}
                >
                    <Tooltip title="Cancelar recibo">
                        <Row align="middle">
                            <Prohibit size={18} weight="regular" className="clickableIcon icon" />
                            {full && <Typography.Text style={{ marginLeft: '5px' }}>Cancelar</Typography.Text>}
                        </Row>
                    </Tooltip>
                </Popconfirm>
            )
        } else return <></>
    }

    const CopyIdAction = ({ full }: { full: boolean }) => {
        if (receipt?.status !== 'canceled' && receipt?.cancellation_status !== 'pending') {
            return (
                <Tooltip title="Copiar ID">
                    <Row
                        align="middle"
                        onClick={() => {
                            navigator.clipboard.writeText(receipt?.id)
                            message.success('ID copiado')
                        }}
                    >
                        <Copy size={18} weight="regular" className="clickableIcon icon" />
                        {full && <Typography.Text style={{ marginLeft: '5px' }}>Copiar ID</Typography.Text>}
                    </Row>
                </Tooltip>
            )
        } else return <></>
    }

    const StampAction = ({ full }: { full: boolean }) => {
        return (
            <div
                style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Tooltip title="Intentar facturar">
                    <Row
                        align="middle"
                        onClick={async () => {
                            dispatch(openModal('createInvoiceFromReceiptModalVisible'))
                            dispatch(
                                setData({
                                    item: 'receipt',
                                    data: receipt,
                                }),
                            )
                        }}
                    >
                        {loading.find((l) => l.id === receipt?.id && l.action === 'stamp') ? (
                            <LoadingIcon />
                        ) : (
                            <Stamp
                                size={18}
                                weight="regular"
                                className="clickableIcon icon"
                                style={{ cursor: 'pointer' }}
                            />
                        )}
                        {full && <Typography.Text style={{ marginLeft: '5px' }}>Timbrar</Typography.Text>}
                    </Row>
                </Tooltip>
            </div>
        )
    }

    const DeleteReceipt = ({ full }: { full: boolean }) => {
        if (!canCreateReceipt) return <></>

        return (
            <div
                style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Tooltip title="Borrar recibo">
                    <Popconfirm
                        disabled={thisReceiptHasAnyLoading}
                        title="Borrar recibo"
                        description="Borraremos el recibo de venta"
                        okText="Borrar"
                        cancelText="Cancelar"
                        onConfirm={async () => {
                            try {
                                setdownloading(receipt.id)
                                try {
                                    const d = doc(fs, 'receipts', receipt.id)
                                    await deleteDoc(d)

                                    message.success('Recibo eliminado')
                                } catch (error: any) {
                                    message.error(error.message)
                                }
                            } catch (error) {}
                        }}
                    >
                        <Row align="middle">
                            {loading.find((l) => l.id === receipt?.id && l.action === 'stamp') ? (
                                <LoadingIcon />
                            ) : (
                                <Trash
                                    size={18}
                                    weight="regular"
                                    className="clickableIcon icon"
                                    style={{ cursor: 'pointer' }}
                                />
                            )}
                            {full && <Typography.Text style={{ marginLeft: '5px' }}>Borrar recibo</Typography.Text>}
                        </Row>
                    </Popconfirm>
                </Tooltip>
            </div>
        )
    }

    const editAction = () => {
        if (!canCreateReceipt) return message.warning('No tienes permisos para editar recibos')

        dispatch(openModal('receiptModalVisible'))
        dispatch(
            setData({
                item: 'client',
                data: receipt?.client,
            }),
        )
        dispatch(
            setData({
                item: 'selectedServices',
                data: receipt.items,
            }),
        )
        // GetServices(dispatch, receipt?.items.map((i: any) => i.id))
        dispatch(
            setElementSelected({
                element: 'receipt',
                data: {
                    ...receipt,
                },
            }),
        )
    }

    let elements: MenuProps['items'] = [
        {
            label: (
                <EmailActions
                    full={true}
                    action={() => {}}
                    loading={loading.find((l) => l.id === receipt?.id && l.action === `email`) !== undefined}
                />
            ),
            onClick: async () => {
                if (!canCreateReceipt) return message.warning('No tienes permisos para enviar recibos')
                const receiptClient = await clientDataHelper(receipt.clientId)
                dispatch(openModal('sendByEmailModalVisible'))
                dispatch(
                    setElementSelected({
                        element: 'toEmail',
                        data: { ...receipt, elementType: 'receipts' },
                    }),
                )
                dispatch(
                    setData({
                        item: 'client',
                        data: receiptClient,
                    }),
                )
            },
            key: 'email',
        },
        {
            label: (
                <EditAction
                    action={() => {}}
                    loading={loading.find((l) => l.id === receipt?.id && l.action === `email`) !== undefined}
                    full={true}
                />
            ),
            onClick: () => editAction(),
            key: 'edit',
        },
        {
            label: <CopyIdAction full={true} />,
            onClick: () => {
                navigator.clipboard.writeText(receipt?.id)
                message.success('ID copiado')
            },
            key: 'copyid',
        },
        {
            label: <DownloadAction full={true} />,
            onClick: async () => {
                DownloadFile({
                    type: 'pdf',
                    url: receipt.pdf,
                    name: 'Recibo-de-venta',
                    setdownloading: (r: any) => setdownloading(r),
                })
                await new Promise((resolve) => setTimeout(resolve, 5000))
                setLoading(loading.filter((l) => l.id !== receipt?.id && l.action !== `download`))
            },
            key: 'download',
        },
    ]

    if (receipt.status !== 'completed' && canCreateReceipt) {
        elements.push(
            {
                label: <StampAction full={true} />,
                onClick: async () => {
                    dispatch(openModal('createInvoiceFromReceiptModalVisible'))
                    dispatch(
                        setData({
                            item: 'receipt',
                            data: receipt,
                        }),
                    )
                },
                key: 'stamp',
            },
            {
                label: <CancelAction full={true} />,
                onClick: async () => {
                    setactionOnReceipt(receipt.id)
                    message.loading('Cancelando recibo...')
                    try {
                        await updateDoc(doc(fs, 'receipts', receipt.id), {
                            status: 'completed',
                            invoiceErrorMessage: 'Recibo cancelado por usuario',
                            logs: arrayUnion({
                                date: moment().format('YYYY-MM-DD HH:mm:ss'),
                                changed: 'status',
                                message: `Recibo cancelado por ${user?.email}`,
                            }),
                            disallowInvoice: true,
                        })
                        message.success('Recibo cancelado')
                        setactionOnReceipt(null)
                    } catch (error) {
                        message.error('Error al cancelar Recibo')
                        setactionOnReceipt(null)
                    }
                },
                key: 'cancel',
            },
        )
    }
    if (receipt.status === 'cancelled' && canCreateReceipt) {
        elements.push({
            label: <DeleteReceipt full={true} />,
            onClick: async () => {
                try {
                    setdownloading(receipt.id)
                    try {
                        const d = doc(fs, 'receipts', receipt.id)
                        await deleteDoc(d)
                        message.success('Recibo eliminado')
                    } catch (error: any) {
                        message.error(error.message)
                    }
                } catch (error) {}
            },
            key: 'delete',
        })
    }

    const showInvoiceError = receipt?.invoiceError && receipt?.validUntil < moment().valueOf()

    const ReloadButton = ({ full }: { full: boolean }) => {
        return (
            <div
                style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Tooltip title="Regresar a espera">
                    <Popconfirm
                        disabled={thisReceiptHasAnyLoading}
                        title="Regresar recibo"
                        description="Actualizaremos el recibo a en espera"
                        okText="Regresar"
                        cancelText="Cancelar"
                        onConfirm={async () => {
                            try {
                                setLoading([...loading, { id: receipt.id, action: 'reload' }])
                                await updateDoc(doc(fs, 'receipts', receipt.id), {
                                    status: 'pending',
                                    logs: arrayUnion({
                                        date: moment().format('YYYY-MM-DD HH:mm:ss'),
                                        changed: 'status',
                                        message: `Recibo regresado a "En espera" por ${user?.email}`,
                                    }),
                                })
                                message.success("Recibo regresado a 'En espera'")
                                setLoading(loading.filter((l) => l.id !== receipt?.id && l.action !== `reload`))
                            } catch (error) {}
                        }}
                    >
                        <Row align="middle">
                            {loading.find((l) => l.id === receipt?.id && l.action === 'reload') ? (
                                <LoadingIcon />
                            ) : (
                                <ArrowCounterClockwise
                                    size={18}
                                    weight="regular"
                                    className="clickableIcon icon"
                                    style={{ cursor: 'pointer' }}
                                />
                            )}
                            {full && (
                                <Typography.Text style={{ marginLeft: '5px' }}>Regresar a "En espera"</Typography.Text>
                            )}
                        </Row>
                    </Popconfirm>
                </Tooltip>
            </div>
        )
    }

    if (receipt?.status === 'completed' && !receipt?.invoice && moment().valueOf() <= receipt?.validUntil) {
        elements.push({
            label: <ReloadButton full={true} />,
            key: 'returnToPending',
        })
    }

    if (receipt?.status === 'completed') {
        elements = elements.filter((e) => !['stamp', 'cancel', 'edit', 'email'].includes(e?.key as string))
    }

    const ActionsContent = () => {
        return (
            <div className={props.showFull ? 'd-flex flex-column' : 'd-flex flex-row'}>
                <Space
                    direction={props.showFull ? 'vertical' : 'horizontal'}
                    align="start"
                    style={{ marginBottom: props.showFull ? '10px' : '0px' }}
                >
                    {receipt?.status === 'pending' && (
                        <CopyAction
                            toCopy={`https://beta.gigstack.pro/autofactura?id=${receipt.id}`}
                            loading={thisReceiptHasAnyLoading}
                        />
                    )}
                    {/* <DownloadAction full={props.showFull ?? false} /> */}

                    {/* <StampAction full={props.showFull ?? false} /> */}
                    {receipt?.invoiceErrorMessage && (
                        <Tooltip title={receipt?.invoiceErrorMessage}>
                            <Warning weight="regular" className="icon" />
                        </Tooltip>
                    )}
                    {receipt?.hilosResponse?.conversation && (
                        <Tooltip title="Enviado desde hilos.">
                            <WhatsappLogo
                                className="icon"
                                size={18}
                                weight="regular"
                                onClick={() => {
                                    //COPY TO CLIPBOARD
                                    navigator.clipboard.writeText(receipt.hilosResponse.conversation)
                                    message.success('ID de conversación copiada')
                                }}
                            />
                        </Tooltip>
                    )}
                </Space>

                {props.showFull && props?.receipt?.status !== 'completed' ? (
                    <Space direction={props.showFull ? 'vertical' : 'horizontal'}>
                        {
                            <EmailActions
                                full={props.showFull ?? false}
                                action={async () => {
                                    if (!canCreateReceipt)
                                        return message.warning('No tienes permisos para enviar recibos')
                                    const receiptClient = await clientDataHelper(receipt.clientId)
                                    dispatch(openModal('sendByEmailModalVisible'))
                                    dispatch(
                                        setElementSelected({
                                            element: 'toEmail',
                                            data: { ...receipt, elementType: 'receipts' },
                                        }),
                                    )
                                    dispatch(
                                        setData({
                                            item: 'client',
                                            data: receiptClient,
                                        }),
                                    )
                                }}
                                loading={
                                    loading.find((l) => l.id === receipt?.id && l.action === `email`) !== undefined
                                }
                            />
                        }
                        <CancelAction full={props.showFull ?? false} />
                        <EditAction
                            full={props.showFull ?? false}
                            action={() => {}}
                            loading={loading.find((l) => l.id === receipt?.id && l.action === `email`) !== undefined}
                        />
                        {receipt?.hilosResponse?.conversation && (
                            <WhatsappLogo className="icon" size={18} weight="regular" />
                        )}
                    </Space>
                ) : (
                    <Dropdown
                        trigger={['click']}
                        menu={{
                            items: elements,
                            onClick: (v) => {
                                if (v.key === 'email') {
                                    if (!canCreateReceipt)
                                        return message.warning('No tienes permisos para enviar recibos')
                                    const receiptClient = clientDataHelper(receipt.clientId)
                                    dispatch(openModal('sendByEmailModalVisible'))
                                    dispatch(
                                        setElementSelected({
                                            element: 'toEmail',
                                            data: { ...receipt, elementType: 'receipts' },
                                        }),
                                    )
                                    dispatch(
                                        setData({
                                            item: 'client',
                                            data: receiptClient,
                                        }),
                                    )
                                }
                            },
                        }}
                    >
                        <DotsThreeOutlineVertical
                            className="clickable icon"
                            style={{ marginLeft: '10px', cursor: 'pointer' }}
                            size={18}
                            weight="regular"
                        />
                    </Dropdown>
                )}
            </div>
        )
    }

    const GlobalInvoiceError = () => {
        if (!showInvoiceError) return <></>
        return (
            <Popover
                content={
                    <div className="d-flex flex-column">
                        <Typography.Text className="smallheader">Error en factura global</Typography.Text>
                        <Alert
                            style={{ padding: '5px 10px' }}
                            type="error"
                            description={
                                <Typography.Text className="smallparagraph descriptions">
                                    {receipt?.invoiceError}
                                </Typography.Text>
                            }
                        />

                        {/* <Button style={{ marginTop: '5px' }} size="small">Timbrar</Button> */}
                        <Typography.Text className="smallparagraph" style={{ marginTop: '15px' }}>
                            Prueba{' '}
                            <span style={{ margin: '0px 5px' }} className="primary clickable" onClick={editAction}>
                                editando
                            </span>{' '}
                            el recibo y{' '}
                            <span style={{ margin: '0px 5px' }} className="primary clickable">
                                timbrando
                            </span>{' '}
                            de forma manual
                        </Typography.Text>
                    </div>
                }
            >
                <Flag
                    size={16}
                    weight="regular"
                    className="clickableIcon  warning"
                    style={{ marginRight: '8px', marginBottom: '2px' }}
                />
            </Popover>
        )
    }

    return props.showFull ? (
        <Space direction="vertical" wrap={true}>
            <GlobalInvoiceError />
            <ActionsContent />
        </Space>
    ) : (
        <Row align="middle" justify="end">
            <GlobalInvoiceError />
            <ActionsContent />
        </Row>
    )
}

export default ReceiptActions
