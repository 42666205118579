import { Button, Col, Form, Row, Typography, Input, Alert, message, Table, Tag, Tooltip } from 'antd'
import { useState, useEffect } from 'react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFirestore, doc, getDoc } from 'firebase/firestore'
import { useSelector } from 'react-redux'
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

interface CustomEmailResponse {
    success: boolean
    domain: string
    subdomain: string
    valid: boolean
    dns_setup: any
    message: string
}

const CustomEmailForm = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const { team } = useSelector((state: any) => state.team)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    const [loading, setLoading] = useState(false)
    const [dnsRecords, setDnsRecords] = useState<any>(null)
    const [validationStatus, setValidationStatus] = useState<'success' | 'error' | 'validating' | ''>('')

    // TODO -> CHANGE TO NEW PERMISSIONS
    const isAllowedPlan =
        billingAccount?.plan?.id && ['professional', 'professional-plus', 'custom'].includes(billingAccount.plan.id)

    useEffect(() => {
        if (!isAllowedPlan) {
            return
        }

        const fetchCustomDomain = async () => {
            try {
                const db = getFirestore()
                const customDomainRef = doc(db, `teams/${team.id}`)
                const customDomainDoc = await getDoc(customDomainRef)

                if (customDomainDoc.exists() && customDomainDoc.data().custom_domain) {
                    const data = customDomainDoc.data() as any
                    form.setFieldsValue({
                        domain: data.custom_domain.domain,
                        subdomain: data.custom_domain.subdomain,
                    })

                    if (data.custom_domain.dns_setup) {
                        setDnsRecords(data.custom_domain.dns_setup)
                        setValidationStatus(data.custom_domain.valid ? 'success' : 'validating')
                    }
                }
            } catch (error) {
                console.error('Error fetching custom domain:', error)
            }
        }

        if (team?.id) {
            fetchCustomDomain()
        }
    }, [team.id, form, isAllowedPlan])

    const renderDNSTable = (records: any) => {
        const columns = [
            {
                title: 'Tipo',
                dataIndex: 'type',
                key: 'type',
                width: '15%',
                render: (text: string) => (
                    <Tag color="blue" style={{ padding: '4px 12px', fontSize: '14px' }}>
                        {text.toUpperCase()}
                    </Tag>
                ),
            },
            {
                title: 'Host',
                dataIndex: 'host',
                key: 'host',
                width: '30%',
                render: (text: string) => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Typography.Text copyable={{ text }} style={{ color: '#1890ff' }}>
                            {text}
                        </Typography.Text>
                    </div>
                ),
            },
            {
                title: 'Valor',
                dataIndex: 'data',
                key: 'data',
                width: '40%',
                render: (text: string) => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Typography.Text copyable={{ text }} style={{ fontFamily: 'monospace' }}>
                            {text}
                        </Typography.Text>
                    </div>
                ),
            },
            {
                title: 'Estado',
                dataIndex: 'valid',
                key: 'valid',
                width: '15%',
                render: (valid: boolean) => (
                    <Tooltip title={valid ? 'Registro DNS verificado' : 'Esperando verificación'}>
                        <Tag
                            icon={valid ? <CheckCircleOutlined /> : <ClockCircleOutlined />}
                            color={valid ? 'success' : 'warning'}
                            style={{ padding: '4px 12px', fontSize: '14px' }}
                        >
                            {valid ? 'Verificado' : 'Pendiente'}
                        </Tag>
                    </Tooltip>
                ),
            },
        ]

        const data = Object.entries(records).map(([key, value]: [string, any]) => ({
            key,
            type: value.type,
            host: value.host,
            data: value.data,
            valid: value.valid,
        }))

        return (
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                style={{ marginTop: '16px' }}
                bordered
                className="dns-records-table"
            />
        )
    }

    const onFinish = async (values: any) => {
        setLoading(true)

        const customEmail = httpsCallable(getFunctions(), 'setClientCustomDomainSendgrid')
        const result = await customEmail({
            type: 'set',
            subdomain: values.subdomain,
            domain: values.domain,
            team_id: team.id,
        })

        const response = result.data as CustomEmailResponse

        if (response.success) {
            setDnsRecords(response.dns_setup)
            setValidationStatus(response.valid ? 'success' : 'validating')
            message[response.valid ? 'success' : 'warning'](response.message)
            setLoading(false)
        } else {
            message.error('Error al configurar el dominio')
            setLoading(false)
        }
    }

    const handleValidate = async () => {
        setLoading(true)
        const customEmail = httpsCallable(getFunctions(), 'setClientCustomDomainSendgrid')
        const result = await customEmail({
            type: 'validate',
            team_id: team.id,
        })

        const response = result.data as CustomEmailResponse

        if (response.success) {
            setDnsRecords(response.dns_setup)
            setValidationStatus(response.valid ? 'success' : 'validating')
            message[response.valid ? 'success' : 'warning'](response.message)
        } else {
            message.error('Error al validar el dominio')
        }
        setLoading(false)
    }

    const handleRemoveDomain = async () => {
        setLoading(true)
        try {
            const customEmail = httpsCallable(getFunctions(), 'setClientCustomDomainSendgrid')
            const result = await customEmail({
                type: 'remove',
                team_id: team.id,
            })

            const response = result.data as CustomEmailResponse
            if (response.success) {
                setDnsRecords(null)
                setValidationStatus('')
                form.resetFields()
                message.success('Dominio removido correctamente')
            } else {
                message.error('Error al remover el dominio')
            }
        } catch (error) {
            message.error('Error al remover el dominio')
        }
        setLoading(false)
    }

    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={[16, 16]}>
                {!isAllowedPlan ? (
                    <Col xs={24}>
                        <Alert
                            type="warning"
                            message="Plan no disponible"
                            description={
                                <div>
                                    <Typography.Paragraph>
                                        La configuración de dominio personalizado solo está disponible para planes
                                        Professional, Professional Plus y Custom.
                                    </Typography.Paragraph>
                                    <Button
                                        className="btn-primary"
                                        onClick={() => navigate('/memberships')}
                                        style={{ marginTop: '8px' }}
                                    >
                                        Actualizar Plan
                                    </Button>
                                </div>
                            }
                        />
                    </Col>
                ) : (
                    <>
                        <Col xs={24}>
                            <Form.Item>
                                <Input.Group compact>
                                    <Form.Item
                                        name="subdomain"
                                        noStyle
                                        rules={[
                                            { required: true, message: 'Por favor ingrese el subdominio' },
                                            {
                                                pattern: /^[a-z0-9-]+$/,
                                                message: 'Solo letras minúsculas, números y guiones',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="mail" style={{ width: '40%' }} disabled={!!dnsRecords} />
                                    </Form.Item>
                                    <Input
                                        style={{
                                            width: '5%',
                                            borderLeft: 0,
                                            borderRight: 0,
                                            pointerEvents: 'none',
                                            textAlign: 'center',
                                        }}
                                        placeholder="@"
                                        disabled
                                    />
                                    <Form.Item
                                        name="domain"
                                        noStyle
                                        rules={[
                                            { required: true, message: 'Por favor ingrese el dominio' },
                                            {
                                                pattern: /^[a-z0-9][a-z0-9-]+\.[a-z]{2,}$/,
                                                message: 'Ingrese un dominio válido',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="suempresa.com"
                                            style={{ width: '55%' }}
                                            disabled={!!dnsRecords}
                                        />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        </Col>

                        {dnsRecords && isAllowedPlan && (
                            <Col xs={24}>
                                <Alert
                                    type={validationStatus === 'success' ? 'success' : 'info'}
                                    message={
                                        <Typography.Title level={5} style={{ margin: 0 }}>
                                            {validationStatus === 'success'
                                                ? 'Dominio validado correctamente'
                                                : 'Configuración DNS Requerida'}
                                        </Typography.Title>
                                    }
                                    description={
                                        <div>
                                            <Typography.Paragraph type="secondary">
                                                Agregue los siguientes registros DNS a su dominio:
                                            </Typography.Paragraph>
                                            {renderDNSTable(dnsRecords)}
                                            <Typography.Paragraph type="secondary" style={{ marginTop: '16px' }}>
                                                <i>
                                                    Nota: Los cambios en los registros DNS pueden tardar hasta 48 horas
                                                    en propagarse completamente.
                                                </i>
                                            </Typography.Paragraph>
                                        </div>
                                    }
                                />
                            </Col>
                        )}

                        <Col xs={24}>
                            <div
                                style={{
                                    marginTop: dnsRecords ? '24px' : '0',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: '12px',
                                    borderTop: dnsRecords ? '1px solid #f0f0f0' : 'none',
                                    paddingTop: dnsRecords ? '24px' : '0',
                                }}
                            >
                                {dnsRecords && isAllowedPlan && (
                                    <>
                                        <Button
                                            className="btn-destructive"
                                            onClick={handleRemoveDomain}
                                            loading={loading}
                                        >
                                            Remover Dominio
                                        </Button>
                                        {validationStatus !== 'success' && (
                                            <Button className="btn-primary" onClick={handleValidate} loading={loading}>
                                                Verificar DNS
                                            </Button>
                                        )}
                                    </>
                                )}

                                {!dnsRecords && isAllowedPlan && (
                                    <Button className="btn-primary" htmlType="submit" loading={loading}>
                                        Configurar Dominio
                                    </Button>
                                )}
                            </div>
                        </Col>
                    </>
                )}
            </Row>
        </Form>
    )
}

export default CustomEmailForm
