/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react'
import { Button, Input, InputRef, Space, Table, Tooltip, Typography } from 'antd'
import {
    query,
    collection,
    orderBy,
    getFirestore,
    where,
    limit,
    startAfter,
    getCountFromServer,
    getDocs,
} from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestoreCollectionData } from 'reactfire'
import { InvoiceDef, OptimizedInvoiceDef, optimizedInvoiceDefSet } from '../../../interfaces/invoicesDef'
import { InvoicesColumnsObject } from './ColumnsSettlers'
import { MagnifyingGlass, X } from '@phosphor-icons/react'
import { FilterConfirmProps } from 'antd/lib/table/interface'
import { ColumnType } from 'antd/es/table'
import { InvoicesColumns } from './Columns/InvoicesColumns'
import { useLocation, useNavigate } from 'react-router-dom'
import { SearchParamInURL } from '../../functions/helpers'
import { setData } from '../../context/dataSlice'

const InvoicesTable = ({
    max: incomingMax = 10,
    startPeriod,
    endPeriod,
}: {
    max: number
    search?: any
    startPeriod?: number | null
    endPeriod?: number | null
}) => {
    type Def = OptimizedInvoiceDef | InvoiceDef
    type DataIndex = keyof Def

    const source = 'invoices'
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const defSet = (data: any) => {
        return optimizedInvoiceDefSet(data)
    }

    // IMPORTS
    const fs = getFirestore()

    // SELECTORS
    const { testmode } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)

    // REFS
    const searchInput = useRef<InputRef>(null)

    // CONTEXTS
    const ps = SearchParamInURL('ps') ?? 10
    const p = Number(SearchParamInURL('page') ?? 1)

    // STATES
    const [newLoading, setNewLoading] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [pageSize, setPageSize] = useState(ps ?? team?.defaults?.invoiceInitialLoad ?? 10)
    const [currentPage, setCurrentPage] = useState(p ?? 1)
    const [items, setItems] = useState<Def[]>([])

    const [activatePages, setActivatePages] = useState(true)

    const defaultInitialLoad = team?.defaults?.invoiceInitialLoad ?? ps

    // VARIABLES
    let resetTrigger = false
    let max =
        p !== 1
            ? Number(ps) * Number(p)
            : team?.defaults?.invoiceInitialLoad
              ? team?.defaults?.invoiceInitialLoad
              : incomingMax

    // CONSTANTS
    const initialQuery =
        startPeriod && endPeriod
            ? query(
                  collection(fs, source),
                  where('team', '==', team?.id ?? ''),
                  where('invoiceType', 'in', ['I', 'E']),
                  where('timestamp', '>=', startPeriod),
                  where('timestamp', '<=', endPeriod),
                  where('livemode', '==', !testmode),
                  orderBy('timestamp', 'desc'),
                  limit(max),
              )
            : query(
                  collection(fs, source),
                  where('team', '==', team?.id ?? ''),
                  where('invoiceType', 'in', ['I', 'E']),
                  orderBy('timestamp', 'desc'),
                  where('livemode', '==', !testmode),
                  limit(max),
              )
    const totalCountQuery =
        startPeriod && endPeriod
            ? query(
                  collection(fs, source),
                  where('team', '==', team?.id ?? ''),
                  where('invoiceType', 'in', ['I', 'E']),
                  where('timestamp', '>=', startPeriod),
                  where('livemode', '==', !testmode),
                  where('timestamp', '<=', endPeriod),
                  orderBy('timestamp', 'desc'),
              )
            : query(
                  collection(fs, source),
                  where('team', '==', team?.id ?? ''),
                  where('invoiceType', 'in', ['I', 'E']),
                  where('livemode', '==', !testmode),
                  orderBy('timestamp', 'desc'),
              )

    const { status: initialLoadStatus, data } = useFirestoreCollectionData(initialQuery, {
        idField: 'fid',
    })

    //EFFECTS
    useEffect(() => {
        getInitialCount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, testmode, team?.id])

    // HANDLERS
    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        setActivatePages(resetTrigger || !selectedKeys[0] ? true : false)
        confirm()
        resetTrigger = false
    }

    const handleReset = (clearFilters: () => void) => {
        resetTrigger = true
        clearFilters()
    }

    //FUNCTIONS
    const getInitialCount = async () => {
        setTotalCount((await getCountFromServer(totalCountQuery)).data().count)
    }

    const getNewData = async ({ lastItem: newItem, ps, p }: { lastItem: number; ps: number; p: number }) => {
        if (ps * p < items.length) return
        setNewLoading(true)
        const newDataQuery =
            startPeriod && endPeriod
                ? query(
                      collection(fs, source),
                      where('team', '==', team?.id ?? ''),
                      where('timestamp', '>=', startPeriod),
                      where('timestamp', '<=', endPeriod),
                      where('livemode', '==', !testmode),
                      orderBy('timestamp', 'desc'),
                      limit(ps * p - items.length + 1),
                      startAfter(newItem),
                  )
                : query(
                      collection(fs, source),
                      where('team', '==', team?.id ?? ''),
                      orderBy('timestamp', 'desc'),
                      where('livemode', '==', !testmode),
                      limit(ps * p - items.length + 1),
                      startAfter(newItem),
                  )
        let newItems = await getDocs(newDataQuery)

        setItems([...items, ...(newItems.docs.map((c) => defSet(c?.data())) ?? [])])
        setNewLoading(false)
    }

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Def> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Tooltip
                    title={`Solo buscará en los elementos que ya se hayan cargado en la lista (${
                        [...(data ?? [] ?? []), ...items].length
                    } de ${totalCount}).`}
                >
                    <Typography.Text className="smallparagraph descriptions" style={{ marginBottom: '5px' }}>
                        Búsqueda local
                    </Typography.Text>
                </Tooltip>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Typography.Text
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys as string[], confirm, dataIndex)
                        }}
                        className="smallparagraph descriptions clickable"
                    >
                        Restablecer
                    </Typography.Text>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<MagnifyingGlass />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        <X className="icon" />
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => {
            return (
                <MagnifyingGlass
                    style={{
                        color: filtered ? '#8666FF' : '#50566B',
                    }}
                    size={16}
                />
            )
        },
        onFilter: (value, record) => {
            let filterValue
            if (dataIndex === 'series') {
                filterValue =
                    (record[dataIndex] ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.series ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.folio_number ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase())

                return filterValue
            } else {
                filterValue =
                    (record[dataIndex] ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.id ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.uuid ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.series ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.folio_number ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.customer?.legal_name ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.customer?.tax_id ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.client?.name ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.client?.email ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.client?.phone ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.client?.company ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.client?.legalName ?? record.client?.legal_name ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.client?.id ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.client?.rfc ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.client?.tax_id ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.metadata?.internalId ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.metadata?.orderId ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.client?.email ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) ||
                    (record.client?.phone ?? '')
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase())
            }

            return filterValue
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
        render: (text, record) =>
            dataIndex === 'clientName'
                ? InvoicesColumns({
                      record,
                      columnName: 'clientInfo',
                      navigate: () => {
                        dispatch(setData({item: 'anchorLocation', data:`${location.pathname}${location.search}`}))
                        navigate(`/invoiceDetails?id=${record.id}`)
                      },
                  })
                : InvoicesColumns({
                      record,
                      columnName: 'series',
                      navigate: () => {
                        dispatch(setData({item: 'anchorLocation', data:`${location.pathname}${location.search}`}))
                        navigate(`/invoiceDetails?id=${record.id}`)
                      },
                  }),
    })

    const getCurrentPageData = () => {
        const allItems = [...(data ?? []).map(d => defSet(d)), ...items]
        const startIndex = (Number(currentPage) - 1) * Number(pageSize)
        const endIndex = Math.min(startIndex + Number(pageSize), totalCount)
        return allItems.slice(startIndex, endIndex)
    }

    return (
        <div>
            <Table
                rowKey={(rec) => rec?.id ?? 'as'}
                scroll={{ x: 1600 }}
                loading={initialLoadStatus === 'loading' || newLoading}
                pagination={
                    activatePages
                        ? {
                              total: totalCount,
                              defaultPageSize: Number(pageSize),
                              defaultCurrent: Number(currentPage),
                              pageSize: Number(pageSize),
                              current: Number(currentPage),
                              onChange(p, ps) {
                                  setItems([])
                                  setPageSize(ps)
                                  setCurrentPage(p)
                                  const allItems = [...(data ?? [])]
                                  
                                  // Calculate if this is the last page
                                  const isLastPage = p === Math.ceil(totalCount / ps)
                                  const itemsNeeded = isLastPage ? (totalCount % ps) || ps : ps
                                  
                                  getNewData({
                                      lastItem: allItems[allItems.length - 1]?.timestamp,
                                      ps: itemsNeeded,
                                      p,
                                  })
                                  const path = `/invoices?page=${p}&ps=${ps}&ak=I`
                                  navigate(path)
                              },
                          }
                        : {
                              defaultPageSize: Number(pageSize),
                              pageSize: Number(pageSize),
                          }
                }
                columns={InvoicesColumnsObject({
                    customKeysToShow: null,
                    navigate: (p: string) => navigate(p),
                }).map((c) => {
                    let column = c
                    if (c.dataIndex === 'clientInfo') {
                        column = {
                            ...column,
                            ...getColumnSearchProps('clientName'),
                        }
                    }

                    if (c.dataIndex === 'series') {
                        column = {
                            ...column,
                            ...getColumnSearchProps('series'),
                        }
                    }
                    if (c.dataIndex === 'status') {
                        column = {
                            ...column,
                            filterMode: 'radio',
                            filterSearch: true,
                            filters: [
                                {
                                    text: 'Válido',
                                    value: 'valid',
                                },
                                {
                                    text: 'Cancelado',
                                    value: 'canceled',
                                },
                                {
                                    text: 'Cancelaciones',
                                    value: 'cancelations',
                                    children: [
                                        {
                                            text: 'Pendiente de cancelación',
                                            value: 'PC',
                                        },
                                        {
                                            text: 'Cancelación rechazada',
                                            value: 'CR',
                                        },
                                    ],
                                },
                            ],
                            onFilter: (value: string, record: any) => {
                                if (value === 'PC') {
                                    return record.cancellation_status === 'pending'
                                } else if (value === 'CR') {
                                    return record.cancellation_status === 'rejected'
                                } else return record.status.includes(value)
                            },
                        }
                    }

                    if (c.dataIndex === 'invoiceType') {
                        column = {
                            ...column,
                            filterMode: 'tree',
                            filterSearch: true,
                            filters: [
                                {
                                    text: 'Ingreso',
                                    value: 'I',
                                },
                                {
                                    text: 'Egreso',
                                    value: 'E',
                                },
                                {
                                    text: 'Pago',
                                    value: 'P',
                                },
                            ],
                            onFilter: (value: string, record: any) => record.invoiceType.includes(value),
                        }
                    }
                    return column
                })}
                dataSource={getCurrentPageData()}
            />
        </div>
    )
}

export default InvoicesTable
